import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
//import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import { withRouter } from 'react-router';

const styles = theme => ({
  root: {
    margin: '90px 0 0 0',
  },
  card: {
    margin: '0px 10px 20px 10px',
    textAlign: 'center',
  },
  topname: {
    padding: '6px',
  },
});

class UserHeader extends React.Component {

  render() {
    const { classes, userInfo } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Card className={classes.card}>
          <Typography variant="h6" color="inherit" className={classes.topname} >
          {(userInfo) ?
            userInfo.data.OfficeName__c
            :
            ""
          }
          </Typography>
        </Card>
      </div>
    );
  }
}

UserHeader.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(withRouter(UserHeader))
);
