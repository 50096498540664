import React from 'react';
import { connect } from 'react-redux';
import UserPolicy from '../../components/templates/UserPolicy';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  logoutAction: authOperations.doLogoutOperation,
  clearAction: authOperations.doClear,
  doOpenDialog: commonOperations.doOpenDialog,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.clearAction();
  }

  render() {
    return (<UserPolicy
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
