// operations
// 1つ以上の action を組み合わせたもの。
// 個別の action は単純なままに、必要に応じて複雑な operations を作成できる。
// redux-thunkなどのミドルウェアはここで使う。
import { push } from 'connected-react-router';
import { changeTheme, doLogin, doLogout, getUserInfo } from './actions';
import axios from 'axios';
import { commonOperations } from '../common';

// API_URL取得
import conf from './../../config/config.js';

import Amplify, { Auth } from 'aws-amplify'; // Amplify
import AWS from 'aws-sdk'; // AWS-SDK

Amplify.configure(conf.amplify_conf);
AWS.config.region = conf.region;


// storeリセット
const doClear = info => {
  return dispatch => {
    dispatch(doLogout());
  };
};
    

// ログイン
const doLoginOperation = (fm) => {
  return async (dispatch, getState) => {
    const { form } = getState();
    let values;
    if (fm === "redirect") {
      values = form.Redirect.values;

console.log(values.username, values.password);
    }else{
      values = form.Login.values;
    }

    try{
      console.log("ログイン情報取得");
      dispatch(commonOperations.startRequest());
      const retLogin = await Auth.signIn(values.username, values.password);
      console.log("retLogin:",retLogin);
      console.log("userId:",retLogin.attributes["custom:userId"]);
      const loginInfo = {
          isLoggedIn: true,
          idToken: retLogin.signInUserSession.idToken.jwtToken,
          cognitoUser: retLogin.attributes.sub,
          userId: retLogin.attributes["custom:userId"],
      };
      dispatch(commonOperations.endRequest());
      dispatch(doLogin(loginInfo));
      if(retLogin.attributes["custom:userId"] === undefined || retLogin.attributes["custom:userId"] ===""){
        return "NO_USER_ID";
      }
      else{
        return "OK";
      }
    } catch(err){
      console.log("signIn err:",err);
      dispatch(commonOperations.endRequest());
      if(err.code === "UserNotConfirmedException"){
        return "NO_USER_ID";
      }
      else if(err.code === "UserNotFoundException" || err.code === "NotAuthorizedException"){
        return "NO_LOGIN";
      }
      else{
        return "SYS_ERROR";
      }
    }
  };
};

// パスワードリセット(認証コード送付)
const sendAuthenticationCodeOperation = info => {
  console.log("パスワードリセット(認証コード送付)");
  return async(dispatch, getState) => {
    const { form } = getState();
    try{
      dispatch(commonOperations.startRequest());
      console.log("メールアドレス:",form.PasswordReset.values.email);
      const retSendAuthenticationCode = await Auth.forgotPassword(form.PasswordReset.values.email);
      console.log("retSendAuthenticationCode:",retSendAuthenticationCode);
      dispatch(commonOperations.endRequest());
      return "OK";
    } catch(err){
      console.log("forgotPassword err:",err);
      dispatch(commonOperations.endRequest());
      if(err.code === "UserNotFoundException"){
        return "NO_USER";
      }
      else if(err.code === "InvalidParameterException"){
        return "NO_VERIFY";
      }
      else if(err.code === "LimitExceededException"){
        return "LIMIT_EXCEED";
      }
      else{
        return "SYS_ERROR";
      }
    }
  };
};

// パスワードリセット(パスワード更新)
const doPasswordResetOperation = info => {
  console.log("パスワードリセット(パスワード更新)");
  return async(dispatch, getState) => {
    const { form } = getState();
    try{
      if (!form.PasswordReset || !form.PasswordReset.values || !form.PasswordReset.values.email) {
        return "EMAIL_NOTHING";
      }
      dispatch(commonOperations.startRequest());
      console.log("PasswordReset form:",form.PasswordReset.values);
      console.log("PasswordResetVerify form:",form.PasswordResetVerify.values);
      const retDoPasswordReset = await Auth.forgotPasswordSubmit(form.PasswordReset.values.email, form.PasswordResetVerify.values.authenticationCode, form.PasswordResetVerify.values.newPassword);
      console.log("retDoPasswordReset:",retDoPasswordReset);
      dispatch(commonOperations.endRequest());
      return "OK";
    } catch(err){
      console.log("forgotPasswordSubmit err:",err);
      dispatch(commonOperations.endRequest());
      if(err.code === "CodeMismatchException"){
        return "CODE_ERROR";
      }
      else if(err.code === "ExpiredCodeException"){
        return "CODE_EXPIRED";
      }
      else{
        return "SYS_ERROR";
      }
    }
  };
};

// ログアウト（storeリセット）
const doLogoutOperation = info => {
  // logout api 呼び出し
  // ログイン画面
  return dispatch => {
    dispatch(doLogout());
    // Amplify
    console.log("###signOut");
    Auth.signOut()
    .then(data => console.log("data:",data))
    .catch(err => console.log("err:",err));
    dispatch(push('/login'));
  };
};

// ログアウト（storeリセット）
const doLogoutRedirectOperation = () => {
  // logout api 呼び出し
  return dispatch => {
    dispatch(doLogout());
    // Amplify
    console.log("###signOut");
    Auth.signOut()
    .then(data => console.log("data:",data))
    .catch(err => console.log("err:",err));
  };
};

// 利用者登録
const doUserRegistOperation = info => {
  console.log("利用者登録");
  return async(dispatch, getState) => {
    const { form } = getState();
    const values = form.UserRegist.values;
    const paramCognito = {
            username: values.mAddress,
            password: values.password,
            attributes:{
                email: values.mAddress
            }
    };

    const paramAPI = {
      Mail__c: values.mAddress,
      OfficeName__c: trim(values.companynameKanji),
      OfficeNameKana__c: convert_katakana(trim(values.companyname), 1),
      ApplicantFamilyName__c: trim(values.usernameFamily),
      ApplicantFirstName__c: trim(values.usernameFirst),
      ApplicantFamilyNameKana__c: convert_katakana(trim(values.usernameFamilyKana), 1),
      ApplicantFirstNameKana__c: convert_katakana(trim(values.usernameFirstKana), 1),
      OfficePostalCode__c: values.zipCode,
      OfficeAddress__c: trim(values.companyaddress),
      Cellphone__c: (values.mobile ? values.mobile : " "),
      Tel__c: (values.telephone ? values.telephone : " "),
    };

    console.log("先にAPI呼び出し:",paramAPI);
    const retDoUserRegistApi = await axios.post(conf.API_URL + "CustomerInformation/registUser", paramAPI);
    console.log("retDoUserRegistApi:",retDoUserRegistApi);
    if (retDoUserRegistApi.data.body.errorCode === "00000") {
      console.log("cognito登録");
      try{
        dispatch(commonOperations.startRequest());
        const retDoUserRegistCognito = await Auth.signUp(paramCognito);
        console.log("retDoUserRegistCognito:",retDoUserRegistCognito);
        dispatch(commonOperations.endRequest());
        return "OK";
      } catch(err){
        console.log("signUp err:",err);
        dispatch(commonOperations.endRequest());
        if(err.code === "UsernameExistsException"){
          // メールを再送する
          try{
            dispatch(commonOperations.startRequest());
            const retResendSignUp = await Auth.resendSignUp(values.mAddress);
            console.log("resendSignUp:",retResendSignUp);
            dispatch(commonOperations.endRequest());
            return "OK";
          }catch(err){
            console.log("resendSignUp err:",err);
            dispatch(commonOperations.endRequest());
            if(err.code === "InvalidParameterException"){
              return "USER_EXIST";
            }
            else if(err.code === "LimitExceededException"){
              return "LIMIT_EXCEED";
            }
            else if(err.code === "CodeDeliveryFailureException"){
              return "MAIL_ERROR";
            }
            else{
              return "SYS_ERROR";
            }
          }
        }
        else if(err.code === "CodeDeliveryFailureException"){
          return "MAIL_ERROR";
        }
        else{
          return "SYS_ERROR";
        }
      }
    }else{
      return "REGIST_ERROR";
    }
  };
};

// メールアドレス変更
const mailUpdateOperation = info => {
  console.log("メールアドレス変更");
  return async(dispatch, getState) => {
    const { form, auth } = getState();
    console.log("メールアドレス:",form.MailUpdate.values.email);
    let params = { 
      Mail__c: form.MailUpdate.values.email
    }; 
    const retAPI = await axios.post(conf.API_URL + "MailAddress/changeMailAddress", params, { 
      headers: { 
        Authorization: auth.loginInfo.idToken, 
      } 
    }); 
    console.log("retAPI:",retAPI);
    if (retAPI.data.body.errorCode == "00000") { 
      return "OK";
    } 
    else if (retAPI.data.body.errorCode == "21700") {
      return "USER_EXIST";
    }
    else if (retAPI.data.body.errorCode == "21701") {
      return "UPDATE_ERROR";
    }
    else if (retAPI.data.body.errorCode == "21702") {
      return "MAIL_ERROR";
    }
    else{
      return "SYS_ERROR";
    }
  };
};

// メールアドレス変更(認証)
const mailUpdateVerifyOperation = () => {
  console.log("メールアドレス変更(認証)");
  return async(dispatch, getState) => {
    const { form, auth } = getState();
    console.log("認証コード:",form.MailUpdateVerify.values.authenticationCode);
    let params = { 
      Mail__c: form.MailUpdate.values.email,
      AuthCode: form.MailUpdateVerify.values.authenticationCode
    }; 
    const retAPI = await axios.post(conf.API_URL + "MailAddress/verifyMailAddress", params, { 
      headers: { 
        Authorization: auth.loginInfo.idToken, 
      } 
    }); 
    console.log("retAPI:",retAPI);
    if (retAPI.data.body.errorCode == "00000") { 
      return "OK";
    } 
    else if (retAPI.data.body.errorCode == "21800") {
      return "USER_EXIST";
    }
    else if (retAPI.data.body.errorCode == "21801") {
      return "CODE_ERROR";
    }
    else if (retAPI.data.body.errorCode == "21802") {
      return "CODE_EXPIRED";
    }
    else if (retAPI.data.body.errorCode == "21803") {
      return "UPDATE_ERROR";
    }
    else{
      return "SYS_ERROR";
    }
  };
};


// ユーザー情報取得
const getUserInfoOperation = () => {
  
  return async(dispatch, getState) => { 
    const { auth } = getState(); 
    console.log("ユーザー情報取得");

    const retUser = await axios.post(conf.API_URL + "CustomerInformation/getUser", {}, { 
      headers: { 
        Authorization: auth.loginInfo.idToken, 
      } 
    }); 
    console.log(retUser);
    if (retUser.data.body.errorCode !== "00000") { 
      return retUser.data.body.errorCode; 
    } 

    // カナ文字を半角から全角に変換
    const userInfo = retUser.data.body; 
    userInfo.data.OfficeNameKana__c = userInfo.data.OfficeNameKana__c ? convert_katakana(userInfo.data.OfficeNameKana__c, 0) : "";
    userInfo.data.ApplicantFamilyNameKana__c = userInfo.data.ApplicantFamilyNameKana__c ? convert_katakana(userInfo.data.ApplicantFamilyNameKana__c, 0) : "";
    userInfo.data.ApplicantFirstNameKana__c = userInfo.data.ApplicantFirstNameKana__c ? convert_katakana(userInfo.data.ApplicantFirstNameKana__c, 0) : "";
    dispatch(getUserInfo(userInfo));
    return "OK";
  }; 
};

// ユーザー情報更新
const doUserUpdateOperation = () => {
  
    return async(dispatch, getState) => { 
        const { form, auth } = getState();
        const values = form.UserUpdate.values;

        let params = { 
            OfficeName__c: trim(values.companynameKanji),
            OfficeNameKana__c: convert_katakana(trim(values.companyname), 1),
            ApplicantFamilyName__c: trim(values.usernameFamily),
            ApplicantFirstName__c: trim(values.usernameFirst),
            ApplicantFamilyNameKana__c: convert_katakana(trim(values.usernameFamilyKana), 1),
            ApplicantFirstNameKana__c: convert_katakana(trim(values.usernameFirstKana), 1),
            OfficePostalCode__c: values.zipCode,
            OfficeAddress__c: trim(values.companyaddress),
            LastModifiedDate: auth.userInfo.data.LastModifiedDate
        }; 

        // 任意項目を設定
        if (values.mobile.length > 0) {
          params.Cellphone__c = values.mobile;  // 携帯電話番号
        } else {
          params.Cellphone__c = " ";
        }
        if (values.telephone.length > 0) {
          params.Tel__c = values.telephone;     // 固定電話番号
        } else {
          params.Tel__c = " ";
        }

        console.log(params);
        
        const ret = await axios.post(conf.API_URL + "CustomerInformation/updateUser", params, {
          headers: { Authorization: auth.loginInfo.idToken }
        }); 
        
        if (values.changePassword) {
        // パスワード変更ありの場合
          if (ret.data.body.errorCode === "00000") {
            // パスワード変更処理
            try{
              dispatch(commonOperations.startRequest());
              console.log("#####currentAuthenticatedUser");
              const user = await Auth.currentAuthenticatedUser();
              console.log("currentAuthenticatedUser:",user);
              console.log("#####changePassword");
              const retChangePassword = await Auth.changePassword(user, form.UserUpdate.values.oldPassword, form.UserUpdate.values.password);
              console.log("retChangePassword:",retChangePassword);
              dispatch(commonOperations.endRequest());
              return "00000";
            } catch(err){
              console.log("retChangePassword err:",err);
              dispatch(commonOperations.endRequest());
              return "80005";
            }
          }
          else{
            // APIのエラーをそのまま返却
            return ret.data.body.errorCode;
          }
        }else{
          return ret.data.body.errorCode;
        }
    }; 
    
};

// トークン更新
const doRefreshTokenOperation = () => {
  return async(dispatch, getState) => { 
    const { auth } = getState();
    const loginInfo = auth.loginInfo;
    if(loginInfo){
      try{
        console.log("トークン更新");
        const currentSession = await Auth.currentSession();
        const idToken = currentSession.getIdToken().getJwtToken();
        //IDトークン更新
        loginInfo.idToken = idToken;
        dispatch(doLogin(loginInfo));
      } catch(err){
        console.log(err);
      }
    }
  };
};

export default {
  changeTheme,
  doLoginOperation,
  sendAuthenticationCodeOperation,
  doPasswordResetOperation,
  doLogoutOperation,
  doUserRegistOperation,
  mailUpdateOperation,
  getUserInfoOperation,
  doUserUpdateOperation,
  mailUpdateVerifyOperation,
  doRefreshTokenOperation,
  doClear,
  doLogoutRedirectOperation
};



//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
const trim = (val) => {
  return val.replace(/^[\s\xA0]+|[\s\xA0]+$/g, '');
};

function convert_katakana(str, option) {
  //option=0:半角から全角, 1:全角から半角
  var pairs, pairs_length, i, before, after;
  option = option === 0 ? 0 : 1;
  pairs = [
    ["ｶﾞ", "ガ"],
    ["ｷﾞ", "ギ"],
    ["ｸﾞ", "グ"],
    ["ｹﾞ", "ゲ"],
    ["ｺﾞ", "ゴ"],
    ["ｻﾞ", "ザ"],
    ["ｼﾞ", "ジ"],
    ["ｽﾞ", "ズ"],
    ["ｾﾞ", "ゼ"],
    ["ｿﾞ", "ゾ"],
    ["ﾀﾞ", "ダ"],
    ["ﾁﾞ", "ヂ"],
    ["ﾂﾞ", "ヅ"],
    ["ﾃﾞ", "デ"],
    ["ﾄﾞ", "ド"],
    ["ﾊﾞ", "バ"],
    ["ﾊﾟ", "パ"],
    ["ﾋﾞ", "ビ"],
    ["ﾋﾟ", "ピ"],
    ["ﾌﾞ", "ブ"],
    ["ﾌﾟ", "プ"],
    ["ﾍﾞ", "ベ"],
    ["ﾍﾟ", "ペ"],
    ["ﾎﾞ", "ボ"],
    ["ﾎﾟ", "ポ"],
    ["ｳﾞ", "ヴ"],
    [" ", "　"],
    ["ｰ", "ー"],
    ["ｧ", "ァ"],
    ["ｱ", "ア"],
    ["ｨ", "ィ"],
    ["ｲ", "イ"],
    ["ｩ", "ゥ"],
    ["ｳ", "ウ"],
    ["ｪ", "ェ"],
    ["ｴ", "エ"],
    ["ｫ", "ォ"],
    ["ｵ", "オ"],
    ["ｶ", "カ"],
    ["ｷ", "キ"],
    ["ｸ", "ク"],
    ["ｹ", "ケ"],
    ["ｺ", "コ"],
    ["ｻ", "サ"],
    ["ｼ", "シ"],
    ["ｽ", "ス"],
    ["ｾ", "セ"],
    ["ｿ", "ソ"],
    ["ﾀ", "タ"],
    ["ﾁ", "チ"],
    ["ｯ", "ッ"],
    ["ﾂ", "ツ"],
    ["ﾃ", "テ"],
    ["ﾄ", "ト"],
    ["ﾅ", "ナ"],
    ["ﾆ", "ニ"],
    ["ﾇ", "ヌ"],
    ["ﾈ", "ネ"],
    ["ﾉ", "ノ"],
    ["ﾊ", "ハ"],
    ["ﾋ", "ヒ"],
    ["ﾌ", "フ"],
    ["ﾍ", "ヘ"],
    ["ﾎ", "ホ"],
    ["ﾏ", "マ"],
    ["ﾐ", "ミ"],
    ["ﾑ", "ム"],
    ["ﾒ", "メ"],
    ["ﾓ", "モ"],
    ["ｬ", "ャ"],
    ["ﾔ", "ヤ"],
    ["ｭ", "ュ"],
    ["ﾕ", "ユ"],
    ["ｮ", "ョ"],
    ["ﾖ", "ヨ"],
    ["ﾗ", "ラ"],
    ["ﾘ", "リ"],
    ["ﾙ", "ル"],
    ["ﾚ", "レ"],
    ["ﾛ", "ロ"],
    ["ﾜ", "ヮ"],
    ["ﾜ", "ワ"],
    ["ｲ", "ヰ"],
    ["ｴ", "ヱ"],
    ["ｦ", "ヲ"],
    ["ﾝ", "ン"],
    ["ｶ", "ヵ"],
    ["ｹ", "ヶ"]
  ];
  pairs_length = pairs.length;
  for(i = 0; i < pairs_length; i++) {
    before = new RegExp(pairs[i][option], "g");
    after = pairs[i][1 - option];
    str = str.replace(before, after);
  }
  return str;
}
