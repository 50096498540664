import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, getFormValues } from 'redux-form';
import * as validateRule from '../../validateRule';
import Conf from '../../../config/config.js';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import axios from 'axios';
import m from "moment";

const styles = theme => ({
  root: {
    textAlign: 'center',
    flexDirection: 'column',
    marginTop: '100px',
    padding: '16px',
  },
  button: {
    marginTop: '20px',
    width: '60%',
    maxWidth: '300px',
  },
  card: {
    margin: '0px 0px 16px 0px',
    paddingTop: '10px',
    textAlign: 'center',
    backgroundColor: '#e8f5e9',
    height: "80px",
  },
  cardh: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  title: {
      color: "#d50000",
      fontSize: "1.3em"
    },
  textField: {
    width: '80%',
    maxWidth: '800px',
  },
  messageArea: {
    border: "solid 1px #d50000",
    margin: '5px',
    padding: '5px 5px 0 5px',
    borderRadius: "0.1em",
    textAlign: 'left',
    maxHeight: '200px',
    overflow: 'auto',
  },
  messageRow: {
    padding: '0 0 5px 0',
    display: 'flex',
  },
  messageDate: {
    marginRight: '10px',
  },
  noticeArea: {
    border: "solid 1px #000000",
    margin: '20px auto',
    padding: '5px',
    borderRadius: "0.1em",
    maxWidth: '620px',
    textAlign:'left',
  },
  noticeLevel1: {
    color: "#d50000",
  },
  noticeLevel2: {
    color: "#d50000",
    fontWeight: "bold",
    marginTop: "6px",
  },
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    inputProps={{
      min: 1
    }}
    {...input}
    {...custom}
  />
);

class Login extends React.Component {

  state = {
    message: null,
  };

  async componentDidMount() {
    this.getMessage();
  }

  /**
   * メッセージの取得。
   */
  getMessage = async () => {
    try {
      const ret = await axios.post(Conf.API_URL + "Message/get");
      const body = ret.data.body;

      // メッセージ取得エラーの場合は例外を投げてcatchへ遷移
      if (body.errorCode !== '00000') {
        throw new Error('API実行時エラー');
      }

      // メッセージが空の場合、メッセージ表示エリアを表示しないためにmessageをnullに設定
      const messages = body.data;
      if (messages.length === 0) {
        this.setState({ message: null });
        return;
      }

      // メッセージをstateに設定
      this.setState({ message: messages });
    } catch (err) {
      // エラー時にはメッセージ取得失敗エラーを表示
      console.error(err)
      this.setState({ message: [{ message: 'メッセージの取得に失敗しました。' }] })
    }
  }

  doLogin = async() => {
    console.log("#######");
    const { loginAction } = this.props;
    if (true) { //バリデーションがOK
      try {
        const result = await loginAction();
        console.log("結果：" + result);
        if (result === 'OK') {
          // メニュー画面へ遷移
          this.props.history.push('/workMenu');
        }
        else if (result === 'NO_USER_ID') {
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'login.dialog.error.nouserid',
          };
          doOpenDialog(dialog);
        }
        else if (result === 'NO_LOGIN') {
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'login.dialog.error.input',
          };
          doOpenDialog(dialog);
        }
        else {
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: 'system.message.error.title',
            message: 'login.dialog.error.system',
          };
          doOpenDialog(dialog);
        }
      }
      catch (err) {
        console.log(`エラー: ${err}`);
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'login.dialog.error.system',
        };
        doOpenDialog(dialog);
      }
    }
  };

  doUserRegist = () => {
    console.log("#userPolicy画面に遷移#");
    this.props.history.push('/userPolicy');
  };

  doPasswordReset = () => {
    console.log("#passwordReset画面に遷移#");
    this.props.history.push('/passwordReset');
  };

  createMessageElements = (messages, classes) => {
    const elements = [];

    for (const messageItem of messages) {
      const date = messageItem.date;
      const message = messageItem.message;

      if (messageItem.date) {
        // 日付が設定されている場合
        const dateStr = m(date).format("YYYY/MM/DD");
        elements.push(
          <div className={classes.messageRow}>
            <Typography className={classes.messageDate}>{dateStr}</Typography>
            <Typography>{message}</Typography>
          </div>
        );
      } else {
        // 日付が設定されていない場合
        elements.push(
          <div className={classes.messageRow}>
            <Typography>{message}</Typography>
          </div>
        );
      }
    }

    return elements;
  }

  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root}>
          <Card className={classes.card}>
            <CardHeader className={classes.cardh} classes={{title: classes.title}} title=<FormattedMessage id={'login.card.title'}/> />
          </Card>
          <Grid container>
            <Grid item xs={12}>
              <Field
                id="username"
                name="username"
                label=<FormattedMessage id={'login.in.mailaddress'}/>
                className={classes.textField}
                required={true}
                margin="normal"
                variant="outlined"
                component={renderTextField}
                autoComplete="username"
                type="text"
                validate={[
                  validateRule.required,
                  validateRule.minLength(6),
                  validateRule.maxLength(80),
                  validateRule.email,
                  validateRule.emailDomain
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="password"
                name="password"
                label=<FormattedMessage id={'login.in.password'}/>
                type="password"
                className={classes.textField}
                required={true}
                margin="normal"
                variant="outlined"
                component={renderTextField}
                autoComplete="current-password"
                validate={[
                  validateRule.required,
                  validateRule.minLength(8),
                  validateRule.maxLength(32),
                  validateRule.password,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleSubmit(this.doLogin)}
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
//                type="submit"
              >
                <span><FormattedMessage id={'login.btn.login'} /></span>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.doPasswordReset}
                className={classes.button}>
                <u><FormattedMessage id={'login.btn.reissue'} /></u>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.doUserRegist}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                <span><FormattedMessage id={'login.btn.regist'} /></span>
              </Button>
            </Grid>
            <Grid item xs={12} align="center">
              <div className={classes.noticeArea}>
                <Typography display="inline">
                  お知らせ
                </Typography>
                <Typography display="inline" className={classes.noticeLevel1}>
                  ・取付けまでの期間は、防護管の取付範囲および見積金額をご承諾後３週間程度を要しますので早期のお申込みをお願いいたします（官公庁等に申請が必要な場合は更に日数を要します）。
                </Typography>
                <Typography display="inline" className={classes.noticeLevel2}>
                  注）防護管・防護シート・防護ネット・ジャバラ管は、工事を行う際の目印です。絶対に作業者が触れたり、工作物が接触したりしないように、作業される責任者の方へお伝えください。
                </Typography>

                {this.state.message != null && 
                  <div className={classes.messageArea}>
                    {this.createMessageElements(this.state.message, classes)}
                  </div>
                }
              </div>
            </Grid>

         </Grid>
        </form>
      </div>
    );
  }
}

const FORM_NAME = "Login";

Login.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({
  initialValues: Conf.DefaultId ? {
    username:Conf.DefaultId,
    password:Conf.DefaultPassword
  }
  :
  {
    username: "",
    password: ""
  }
});

Login = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect(state => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Login));
export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(Login)
);