import React from 'react';
import { connect } from 'react-redux';
import MailUpdateVerify from '../../components/templates/MailUpdateVerify';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
});

const mapDispatchToProps = {
  mailUpdateVerifyAction: authOperations.mailUpdateVerifyOperation,
  doOpenDialog: commonOperations.doOpenDialog,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<MailUpdateVerify
      {...this.props}
    />);
  }
}
const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
