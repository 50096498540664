import React from 'react';
import { connect } from 'react-redux';
import WorkConsideration from '../../components/templates/WorkConsideration';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';
import { workOperations } from '../../reducks/work';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  applyDetail: state.work.applyDetail,
  finalImage: state.work.finalImage,
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doOpenDialog: commonOperations.doOpenDialog,
  getApplyDetailOperation: workOperations.getApplyDetailOperation,
  doApplyConsentOperation: workOperations.doApplyConsentOperation,
  getFinalImageOperation: workOperations.getFinalImageOperation,
  clearFinalImage: workOperations.clearFinalImage,
};

  
class App extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setfinalImage();
  }

  // 申込詳細取得＆最終画像取得
  setfinalImage = async() => {
    this.props.clearFinalImage(); // 最終画像クリア
    const result = await this.props.getApplyDetailOperation();  // 申込詳細取得
    if (result === '00000') { 
      this.props.applyDetail.data.ImageDatas.map( ( item, index ) => {
        let ret = "";
        try {
          ret = this.props.getFinalImageOperation(item.ImageId, item.ObjectType, item.Description__c);
        }catch(e) {
          console.log("error:pages");
        }finally{
          console.log("画像データ取得：", item);
        }
        return ret;
      });
    }
  }

  render() {
    return (<WorkConsideration
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default ContainerApp;
