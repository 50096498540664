import React from 'react';
import { connect } from 'react-redux';
import UserRegistConfirm from '../../components/templates/UserRegistConfirm';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
});

const mapDispatchToProps = {
  doUserRegistAction: authOperations.doUserRegistOperation,
  doOpenDialog: commonOperations.doOpenDialog,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<UserRegistConfirm
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
