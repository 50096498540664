import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Conf from '../../../config/config.js';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 10px 16px 10px',
  },
  card: {
    margin: '10px 20px 10px 20px',
  },

  typographyHead: {
    margin: '20px 0 0 20px',
    fontSize: '1.0em'
  },
  typography: {
    margin: '10px 0 0 16px',
    fontSize: '0.8em'
  },
});

const ExPanelSummary = withStyles(theme => ({
//  head: {
//    backgroundColor: "#e0e0e0",
//    color: "#1b5e20",
//    fontSize: "1.0em",
//  },
  root: {
    padding: '5px 5px 5px 10px !important',
    height: '18px',
    backgroundColor: "#e8f5e9",
    minHeight: "50px",
      '&$expanded': {
        minHeight: "50px",
      },
  },
  expanded: {
    padding: "12px 0 12px 0px"
  },
  expandIcon: {
    padding: "0",
  }
}))(ExpansionPanelSummary);

const ExPanel = withStyles(theme => ({
  expanded: {
    margin: "0"
  }
}))(ExpansionPanel);

const ExPanelDetail = withStyles(theme => ({
  root: {
    padding: "10px",
    display:"block"
  }
}))(ExpansionPanelDetails);


class Help extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography className={classes.typographyHead}>防護管取付に係る流れについて</Typography>
        <Card className={classes.card}>
          <ExPanel>
            <ExPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography>①お申込み</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>■取付申込メニュー画面から[新規申込はこちらをクリック]を選択し，取付申込画面にて必要事項を入力してください。取付希望範囲の写真撮影方法については，<a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_PHOTOGRAPHY_URL}>こちら</a>を参考にしてください。[送信]をクリックすると申込が完了します。</Typography>
              <Typography>■申込が完了した件名については，取付申込メニュー画面の申込一覧から確認ができます。</Typography>
              <Typography>■②にてWeb会議を行ないます。事前にWeb会議用アプリ(Zoom)のインストールをお願いします。</Typography>
              <Typography>　【Zoomインストールマニュアル】　<a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_PC_INSTALL_URL}>PC版</a>　／　<a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_SP_INSTALL_URL}>スマホ版</a></Typography>
            </ExPanelDetail>
          </ExPanel>
          <ExPanel >
            <ExPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography>②取付範囲の確認</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>■①でお申込みいただいた内容について，防護管受付センターの担当者より電話連絡いたします。</Typography>
              <Typography>■その際，防護管の取付範囲等の相互確認を円滑に行なうため，Web会議(Zoom)を行ないます。担当者の案内に従い，Web会議への参加をお願いいたします。</Typography>
              <Typography>　【Zoom利用マニュアル】　<a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_PC_MEETING_URL}>PC版</a>　／　<a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_SP_MEETING_URL}>スマホ版</a></Typography>
              <Typography></Typography>
              <Typography></Typography>
            </ExPanelDetail>
          </ExPanel>
          <ExPanel >
            <ExPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>③取付内容の確認</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>■②で調整した結果の確認について，メールがお客さま宛に送信されます。</Typography>
              <Typography>■メール本文に記載されたURLから申込検討画面へアクセスし，取付内容についてご確認をお願いします。内容に問題がなければ，画面下部の[承諾]をクリックしてください。この操作を以て，取付作業実施が確定します。</Typography>
              <Typography></Typography>
            </ExPanelDetail>
          </ExPanel>
          <ExPanel >
            <ExPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>④取付作業の実施</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>■防護管取付予定日が決まり次第，メールがお客さま宛に送信されます。</Typography>
              <Typography>■防護管取付作業が完了しましたら，メールがお客さま宛に送信されます。</Typography>
            </ExPanelDetail>
          </ExPanel>
          <ExPanel >
            <ExPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>⑤取外し作業の実施</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>■防護管取付期間満了日の１ヶ月前になりましたら，メールがお客さま宛に送信されます。</Typography>
              <Typography>■メール本文に記載されたURLから申込検討画面へアクセスし，取外し予定日についてご確認をお願いします。取外し予定日の変更がある場合にも，こちらから承ります。取外し予定日について間違いがないことを確認し，画面下部の[確認]をクリックしてください。この操作を以て取外し作業実施が確定します。</Typography>
              <Typography>■防護管取外し作業が完了しましたら，メールがお客さま宛に送信されます。</Typography>
            </ExPanelDetail>
          </ExPanel>
        </Card>
        <div align="center">
          <a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_PDF_URL}>防護管取付申込操作説明書を表示する</a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Help);