import React from 'react';
import { connect } from 'react-redux';
import Redirect from '../../components/templates/Redirect';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';
import { workOperations } from '../../reducks/work';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  formData: state.form,
});
const mapDispatchToProps = {
  loginAction: authOperations.doLoginOperation,
  getUserInfo: authOperations.getUserInfoOperation,
  doOpenDialog: commonOperations.doOpenDialog,
  selectApply: workOperations.selectApply, 
  logoutAction: authOperations.doLogoutRedirectOperation,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.doGetQuery();
  }
  
  doGetQuery = () => {
    let query = this.props.history.location.search.substring(1);

    let inParam = {};
    query.split("&").forEach((data) => {
      inParam[data.split("=")[0]] = data.split("=")[1];
    });
    let param = {
      "Id": inParam["id"], "OrderNumber__c": "", "Status__c": ""
    }; 
console.log(param);
    //値のチェック（null,NGなど）
    if (!param.Id) {
      const { doOpenDialog } = this.props; 
      const dialog = { 
        type: 'error', // '閉じる'のみ表示 
        title: 'system.message.error.title', 
        message: 'system.message.error.context', 
      }; 
      doOpenDialog(dialog); 
    }
    this.props.selectApply(param);
    this.props.history.replace("/redirect");
  }

  componentWillMount() {
    // store初期化
    this.props.logoutAction();
  }
  
  render() {
    return (<Redirect
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
