import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForwardIosSharp';
import { reduxForm, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import CardContent from '@material-ui/core/CardContent';
import UserHeader from '../../organisms/UserHeader';
import m from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Warning from '@material-ui/icons/Warning';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 16px 4px',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  card: {
    margin: '16px 16px 0px 16px',
    textAlign: 'center',
  },
  cardTop: {
    margin: '0 16px 16px 16px',
  },
  buttonS: {
    width: "60%",
    height: "40px",
    margin: "0 10px 0 20px"
  },
  gridList: {
    root: {
      padding: "-10px"
    },
    width: "60%",
    height: "250px",
  },
  typography:{
    margin: '0 25px 0 25px'
  },
  typography2:{
    margin: '30px 10px 0 10px'
  },
  typography3:{
    margin: '10px 10px -10px 16px'
  },
  typographyImg:{
    margin: '10px 10px 0 16px'
  },
  typographyDescription:{
    lineHeight: "1.3em"
  },
  termArea: {
    marginBottom: '10px'
  },
  typographyTerm:{
    margin: '0 0 0 12px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  textFieldL: {
    margin: '10px 10px 5px 10px',
  },
  textFieldS: {
    margin: '10px 10px 5px 10px',
    width: '55%'
  },
  textFieldDate: {
    margin: '10px 10px 5px 10px',
    width: '35%',
    minWidth: '240px',
  },
  button: {
//    marginTop: '30px',
    width: '100%',
  },
  buttonB: {
//    marginTop: '30px',
    width: '100%'
  },
  formControl: {
    margin: '10px 20px 10px 20px',
  },
  upload: {
    margin: '10px 20px 0px 10px',
    alignItems: 'center',
  },
  formLabel: {
    color: "#212121",
    margin: "0 0 5px -5px"
  },
  commentHeight: {
    margin: '10px 20px 5px 20px',
  },
  dateMark: {
    marginTop: "22px",
    fontSize: "1.2em"
  },
  cardimage: {
    width: '100%',
  },
  media: {
    width: '100%',
  },
  cardContent: {
    textAlign: 'left',
    padding: "2px 8px 0px 8px",
    "&:last-child": {
      paddingBottom: "10px"
    }
  },
  circle: {
    textAlign: 'center'
  },
  fordaysArea: {
    margin: "10px 0 5px -8px",
    display: "flex",
  },
  fordaysArrow: {
    marginTop: "15px",
  },
  fordaysTextArea: {
    border: "solid 1px rgb(192,192,192)",
    borderRadius: "4px",
    display: "table",
  },
  fordaysText: {
    padding: "0 8px 0 8px",
    display: "table-cell",
    verticalAlign: "middle",
  },
});

const RadioB = withStyles(theme => ({
  root: {
    padding: "0px 5px 0px 10px",
    '&$checked': {
      color: '#4caf50'
    }
  },
  checked: {
    },
}))(Radio);

const Check = withStyles(theme => ({
  root: {
    padding: "0px 5px 0px 10px",
    '&$checked': {
      color: '#4caf50'
    }
  },
  checked: {
    },
}))(Checkbox);


class WorkConsider extends React.Component {

  // お見積金額確認
  onClickEstimateConfirm = () => {
    this.props.history.push('/workEstimateConfirm');
  };

  // ご請求金額確認
  onClickBillConfirm = () => {
    this.props.history.push('/workBillConfirm');
  };


  // 承諾ボタンクリック
  onClickConsent = () => {
    const { doOpenDialog } = this.props;
    const dialog = {
      type: 'okcancel', // 「確認」と「キャンセル」を表示
      title: '',
      message: 'workConsideration.dialog.inquiry',
      action: this.doAction
    };
    doOpenDialog(dialog);
  }


  // 承諾処理実行
  doAction = async() => {

    const { doApplyConsentOperation, doOpenDialog } = this.props;
    try {
      const result = await doApplyConsentOperation();
      console.log(result);
      if (result === '00000') {
        // 正常
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workConsideration.dialog.finish',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else if (result === '20800') {
        // 取付申込承諾失敗
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'workConsideration.dialog.error.failure2',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else {
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.title',
      };
      doOpenDialog(dialog);
    }
  };

  // 問合せ/写真追加
  onClickInquiry = () => {
    this.props.history.push('/workInquiry');
  };

  // 取外し確認
  onClickRemoval = () => {
    this.props.history.push('/workRemoval');
  };

  // 戻る
  onClickBack = () => {
    this.props.history.push('/workMenu');
  };

  // 最新化処理実行
  onClickRequest = async() => {
    console.log("最新化ボタンクリック");
    const { getApplyDetailOperation, doOpenDialog, applyDetail, getFinalImageOperation, clearFinalImage } = this.props;
    try {
      const result = await getApplyDetailOperation();
      console.log(result);
      if (result === '00000') {
        clearFinalImage(); // 最終画像クリア
        // 正常の場合は画像読込
        applyDetail.data.ImageDatas.map( ( item, index ) => {
          let ret = "";
          try {
            ret = getFinalImageOperation(item.ImageId, item.ObjectType, item.Description__c);
          }catch(e) {
            console.log("error:pages");
          }finally{
            console.log("画像データ取得：", item);
          }
          return ret;
        });
      }
      else if (result === '20700') {
        // 申込詳細取得失敗
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workConsideration.dialog.error.failure',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else {
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }

    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }

  };


  render() {
    const { classes, intl, applyDetail, finalImage } = this.props;

    const selKind1 = intl.formatMessage({ id: 'workConsideration.select.architecture' });
    const selKind2 = intl.formatMessage({ id: 'workConsideration.select.carry' });
    const selKind3 = intl.formatMessage({ id: 'workConsideration.select.civilEngineering' });
    const selKind4 = intl.formatMessage({ id: 'workConsideration.select.road' });
    const selKind5 = intl.formatMessage({ id: 'workConsideration.select.wall' });
    const selKind6 = intl.formatMessage({ id: 'workConsideration.select.paint' });
    const selKind7 = intl.formatMessage({ id: 'workConsideration.select.workOther' });

    const presenceYes = intl.formatMessage({id: 'workConsideration.select.yes'});
    const presenceNo = intl.formatMessage({id: 'workConsideration.select.no'});

    let applyData = [];
    let guidMessageID = '';
    let isEstimateBtnDisable = true;
    let isBillBtnDisable = true;
    let isConsentBtnDisable = true;
    let isRemovalBtnDisable = true;

    if (applyDetail) {
      if (applyDetail.errorCode === '00000') {
        if (applyDetail.data) {
          applyData = applyDetail.data;
        }
      }
      else if (applyDetail.errorCode === '20700' || applyDetail.errorCode === '20701'　) {
        // 詳細取得エラーだった場合(申込メニュー画面へ遷移)
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workConsideration.dialog.error.failure',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      } else {
        // その他エラー(ログイン画面へ遷移)
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }

    if (applyDetail) {
      // メッセージ切り替え
      if (applyData.Status__c === "撤去可否登録～撤去工事検査" && applyData.RemoveProperty__c === "否") {
        // 取外し確認待
        guidMessageID = 'workConsideration.message.removalConfirm';
      } else if ( applyData.PaidFlag__c ) {
          if (applyData.Status__c === "承諾入力～取付工事検査" && applyData.AttachApprovalInput__c === "未") {
            // 有償 見積金額確認待
            guidMessageID = 'workConsideration.message.estimateConfirm';
          } else if (applyData.Status__c === "精算" && applyData.AttachWork.PaymentAcceptance__c === "未") {
            // 有償 請求金額確認待
            guidMessageID = 'workConsideration.message.billConfirm';
          }
      } else {
        if (applyData.Status__c === "承諾入力～取付工事検査" && applyData.AttachApprovalInput__c === "未") {
          // 無償 承諾待
          guidMessageID = 'workConsideration.message.consent';
        }
      }

      // ボタン活性非活性切り替え
      if (applyData.Status__c === "撤去可否登録～撤去工事検査" && applyData.RemoveProperty__c === "否") {
        isRemovalBtnDisable = false;
      }
      if (!applyData.Cost.UnnecessaryEstimate__c
          && (applyData.Status__c === "承諾入力～取付工事検査"
            || applyData.Status__c === "精算"
            || applyData.Status__c === "撤去可否登録～撤去工事検査"
            || applyData.Status__c === "完了")) {
        isEstimateBtnDisable = false;
      }
      if ((!applyData.Cost.BillingUnnesessary__c || applyData.Cost.CancelChargeRequest__c)
          && (applyData.Status__c === "精算"
            || applyData.Status__c === "撤去可否登録～撤去工事検査"
            || applyData.Status__c === "完了")) {
        isBillBtnDisable = false;
      }
      if (applyData.Status__c === "承諾入力～取付工事検査" && applyData.AttachApprovalInput__c === "未") {
        isConsentBtnDisable = false;
      }
    }

    // 取付希望期間の日数を算出
    let forDays = null;
    if (applyData.StartRequireAttachTerm__c && applyData.EndRequireAttachTerm__c) {
      const start = m(applyData.StartRequireAttachTerm__c);
      const end = m(applyData.EndRequireAttachTerm__c);
      forDays = end.diff(start, 'days') + 1;
    }

    return (
      <div className={classes.root}>
      <form noValidate autoComplete="off" className={classes.root}>
      <UserHeader />

        <Typography variant="h5" className={classes.typography2}>件名：{applyData.OrderName__c}</Typography>

        <Typography variant="subtitle1" className={classes.typographyImg}><FormattedMessage id={'workConsideration.title.latestImage'}/></Typography>
        <div style={{margin:"0 0 20px 10px"}}>
        <Grid container>

{/* 最終画像 */}
        <Grid item xs={8}>
        {finalImage && finalImage.length > 0 ?
          <Card className={classes.cardimage}>
            {finalImage.map((item, index) => (
            <div key={index} >
              {(item.ImageBody && item.ImageBody === "ERROR") &&
                <div className={classes.circle}>
                <hr size="0.5" margin="0px"/>
                <img src="" className={classes.media} alt="画像の読込みに失敗しました"/>
                </div>
              }
              {(item.ImageBody && item.ImageBody !== "ERROR") &&
                <img src={item.ImageBody} className={classes.media} alt=""/>
              }
              {!(item.ImageBody) &&
                <div className={classes.circle}>
                  {(index>0) &&
                    <hr />
                  }
                  <CircularProgress/>
                </div>
              }
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typographyDescription} variant="body2" color="textSecondary" component="p">
                  {item.Description__c ? item.Description__c : "（説明の登録がありません）"}
                </Typography>
              </CardContent>
            </div>
            ))}
          </Card>
          :
          <Card className={classes.cardimage}>
          <CardContent>
            <FormattedMessage id={'workConsideration.message.nodata'} />
          </CardContent>
          </Card>
        }
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonS}
            size="small"
            onClick={this.onClickRequest}
            disabled={ (((this.props.finalImage) ? this.props.finalImage.length : 0) !== ((applyData.ImageDatas) ? applyData.ImageDatas.length : 0)) ? true : false }
          >
            <span><FormattedMessage id={'workConsideration.btn.updates'}/></span>
          </Button>
        </Grid>
        </Grid>
        </div>

{/* 現場所在地(県) */}
        <TextField
          id="WorkSitePrefecture__c"
          name="WorkSitePrefecture__c"
          label=<FormattedMessage id={'workConsideration.select.prefecture'}/>
          className={classes.textFieldS}
          value={applyData.WorkSitePrefecture__c ? applyData.WorkSitePrefecture__c : ""}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />

{/* 現場所在地(住所) */}
        <TextField
          id="WorkSiteAddress__c"
          name="WorkSiteAddress__c"
          label=<FormattedMessage id={'workConsideration.in.workaddress'}/>
          className={classes.textFieldL}
          value={applyData.WorkSiteAddress__c ? applyData.WorkSiteAddress__c : ""}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />

{/* 現場所在地(電柱番号) */}
        <TextField
          id="WorkSitePoleNo__c"
          name="WorkSitePoleNo__c"
          label=<FormattedMessage id={'workConsideration.in.poleNumber'}/>
          className={classes.textFieldS}
          value={applyData.WorkSitePoleNo__c ? applyData.WorkSitePoleNo__c : ""}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />

{/* 取付希望期間 */}
        <div className={classes.termArea}>
          <Typography variant="subtitle1" className={classes.typography3}><FormattedMessage id={'workConsideration.title.term'}/></Typography>
        </div>

        <Typography variant="subtitle2" display="inline" className={classes.typographyTerm}>
          <FormattedMessage id={'term.explanation'}/>
        </Typography>

        <Grid container>
          <TextField
            id="filled-periodfrom"
            className={classes.textFieldDate}
            value={applyData.StartRequireAttachTerm__c ? m(applyData.StartRequireAttachTerm__c).format("YYYY/MM/DD") : ""}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography className={classes.dateMark}>～</Typography>
          <TextField
            id="filled-periodto"
            className={classes.textFieldDate}
            value={applyData.EndRequireAttachTerm__c ? m(applyData.EndRequireAttachTerm__c).format("YYYY/MM/DD") : ""}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          { forDays != null &&
            <div className={classes.fordaysArea}>
              <ArrowForward className={classes.fordaysArrow}/>

              <div className={classes.fordaysTextArea}>
                <Typography className={classes.fordaysText}>
                  {forDays}<FormattedMessage id={'term.fordays'}/>
                </Typography>
              </div>
            </div>
          }
        </Grid>

{/* 工事・作業の分類 */}
        <div style={{marginTop:"10px"}}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.formLabel}><FormattedMessage id={'workConsideration.title.worktype'}/></FormLabel>
            <div style={{paddingLeft:"20px"}}>
              <RadioGroup name="Purpose__c" id="Purpose__c" value={applyData.Purpose__c ? applyData.Purpose__c : ""} row>
                <FormControlLabel value={selKind1} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.architecture'}/> />
                <FormControlLabel value={selKind2} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.carry'}/> />
                <FormControlLabel value={selKind3} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.civilEngineering'}/> />
                <FormControlLabel value={selKind4} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.road'}/> />
                <FormControlLabel value={selKind5} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.wall'}/> />
                <FormControlLabel value={selKind6} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.paint'}/> />
                <FormControlLabel value={selKind7} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.workOther'}/> />
              </RadioGroup>
            </div>
          </FormControl>
        </div>

        {(applyData.Purpose__c === selKind7) &&
          <TextField
            id="PurposeOther__c"
            name="PurposeOther__c"
            label=<FormattedMessage id={'workConsideration.select.workOther.memo'}/>
            className={classes.textFieldS}
            value={applyData.PurposeOther__c}
            margin="normal"
            InputProps = {
              {
                readOnly: true
              }
            }
            variant="outlined"
          />
        }

{/* 工事・作業の内容 */}
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.formLabel}>工事・作業の内容</FormLabel>
          <div style={{paddingLeft:"20px"}}>

          <table>
            <tbody>
              <tr>
                <td style={{width:'120px', whiteSpace: 'nowrap'}}>
                  <Typography ><FormattedMessage id={'workConsideration.title.crane'}/></Typography>
                </td>
                <td>
                  <RadioGroup value={applyData.Crane__c ? applyData.Crane__c : ""} aria-label="crane" name="crane" id="crane" row>
                    <FormControlLabel value={presenceYes} control={<RadioB/>} label=<FormattedMessage id={'workConsideration.select.yes'}/> />
                    <FormControlLabel value={presenceNo} control={<RadioB/>} label=<FormattedMessage id={'workConsideration.select.no'}/> />
                  </RadioGroup>
                </td>
              </tr>
              <tr>
                <td style={{width:'120px', whiteSpace: 'nowrap'}}>
                  <Typography ><FormattedMessage id={'workConsideration.title.scaffold'}/></Typography>
                </td>
                <td>
                  <RadioGroup value={applyData.Scafford__c ? applyData.Scafford__c : ""} aria-label="scaffold" name="scaffold" id="scaffold" row>
                    <FormControlLabel value={presenceYes} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.yes'}/> />
                    <FormControlLabel value={presenceNo} control={<RadioB />} label=<FormattedMessage id={'workConsideration.select.no'}/> />
                  </RadioGroup>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </FormControl>

{/* 防護管取付範囲 */}
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.formLabel}><FormattedMessage id={'workConsideration.title.range'}/></FormLabel>
          <div style={{paddingLeft:'20px'}}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Check checked={applyData.DistributionLine__c ? applyData.DistributionLine__c : false} />
              }
              label=<FormattedMessage id={'workConsideration.check.distributionLine'}/>
            />
            <FormControlLabel
              control={
                <Check checked={applyData.IncomingLine__c ? applyData.IncomingLine__c : false} />
              }
              label=<FormattedMessage id={'workConsideration.check.serviceLine'}/>
            />
            <FormControlLabel
              control={
                <Check checked={applyData.Device__c ? applyData.Device__c : false} />
              }
              label=<FormattedMessage id={'workConsideration.check.machine'}/>
            />
            <FormControlLabel
              control={
                <Check checked={applyData.Other__c ? applyData.Other__c : false} />
              }
              label=<FormattedMessage id={'workConsideration.check.rangeOther'}/>
            />
          </FormGroup>
          </div>
        </FormControl>

{/* ご連絡事項 */}
        <TextField
          id="Message__c"
          name="Message__c"
          label=<FormattedMessage id={'workConsideration.in.memo'}/>
          className={classes.textFieldL}
          value={applyData.Message__c ? applyData.Message__c : ""}
          margin="normal"
          variant="outlined"
          InputProps = {
            {
              readOnly: true
            }
          }
          multiline={true}
          rows={3}
        />

{/* 取付予定日 */}
        <TextField
          id="AttachWorkSchedule__c"
          name="AttachWorkSchedule__c"
          label=<FormattedMessage id={'workConsideration.schedule.construct'}/>
          className={classes.textFieldS}
          value={ applyData.AttachWorkSchedule__c ? m(applyData.AttachWorkSchedule__c).format("YYYY/MM/DD") : ""}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />

{/* 取外し予定日 */}
        <TextField
          id="RemvScheduleDate__c"
          name="RemvScheduleDate__c"
          label=<FormattedMessage id={'workConsideration.schedule.removal'}/>
          className={classes.textFieldS}
          value={ applyData.RemoveScheduleDate__c ? m(applyData.RemoveScheduleDate__c).format("YYYY/MM/DD") : ""}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div style={{margin: "20px 0 15px 0"}}>
        {guidMessageID &&
          <Typography align="center"><FormattedMessage id={guidMessageID}/></Typography>
        }
        </div>

        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Grid container spacing={8}>
              {applyData.PaidFlag__c &&
                <React.Fragment>
                  <Grid item xs={12}>
                    {/* お見積金額確認ボタン */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonB}
                      size="large"
                      onClick={this.onClickEstimateConfirm}
                      disabled={isEstimateBtnDisable}
                    >
                    <span><FormattedMessage id={'workConsideration.btn.estimateConfirm'}/></span>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {/* ご請求金額確認ボタン */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonB}
                      size="large"
                      onClick={this.onClickBillConfirm}
                      disabled={ isBillBtnDisable }
                    >
                    <span><FormattedMessage id={'workConsideration.btn.billConfirm'}/></span>
                    </Button>
                  </Grid>
                </React.Fragment>
              }
              {!applyData.PaidFlag__c &&
                <Grid item xs={12}>
                  {/* 承諾ボタン */}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonB}
                    size="large"
                    onClick={this.onClickConsent}
                    disabled={isConsentBtnDisable}
                  >
                  <span><FormattedMessage id={'workConsideration.btn.consent'}/></span>
                  </Button>
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                {/* 問合せ・写真追加ボタン */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="large"
                  onClick={this.onClickInquiry}
                >
                  <span><FormattedMessage id={'workConsideration.btn.inqualy.add'}/></span>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* 取外し確認ボタン */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="large"
                  onClick={this.onClickRemoval}
                  disabled={isRemovalBtnDisable}
                >
                  <span><FormattedMessage id={'workConsideration.btn.confirm'}/></span>
                </Button>
              </Grid>
              <Grid item xs={12}>
                {/* 戻るボタン */}
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonB}
                  size="large"
                  onClick={this.onClickBack}
                >
                  <span><FormattedMessage id={'common.btn.back'}/></span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </form>
      </div>
    );
  }
}

const FORM_NAME = "WorkConsiderration";

WorkConsider.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

WorkConsider = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
})(connect(state => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(WorkConsider));


export default withStyles(styles)(
  connect( )(injectIntl(WorkConsider))
);
