import React from 'react';
import { connect } from 'react-redux';
import WorkMenu from '../../components/templates/WorkMenu';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';
import { workOperations } from '../../reducks/work';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  applyList: state.work.applyList,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doOpenDialog: commonOperations.doOpenDialog,
  doGetApplyList: workOperations.doGetApplyList,
  getUserInfo: authOperations.getUserInfoOperation,
  clearApplyDetail: workOperations.clearApplyDetail
};


class App extends React.Component {

  async componentDidMount() {
    window.scrollTo(0, 0);
    const ret = await this.props.getUserInfo();
    this.doJudge(ret);
  }

  doJudge = ret => {
    if (ret === "OK" && this.props.userInfo.data["OfficeName__c"]) { 
        this.props.doGetApplyList();
        this.props.clearApplyDetail();
    } else {
      const { doOpenDialog } = this.props; 
      const dialog = { 
        type: 'ok', // '閉じる'のみ表示 
        title: 'system.message.error.title', 
        message: 'system.message.error.context', 
        action: this.doMoveLogin
      }; 
      doOpenDialog(dialog); 
      
    }
  }

  doMoveLogin = () => {
    this.props.history.push("/login");
  }
  
  render() {
    return (<WorkMenu
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
