import React from 'react';
import { connect } from 'react-redux';
import WorkInquiry from '../../components/templates/WorkInquiry';
import { commonOperations } from '../../reducks/common';
import { workOperations } from '../../reducks/work';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
  applyDetail: state.work.applyDetail,
  inquiryList: state.work.inquiryList,
});

const mapDispatchToProps = {
  doOpenDialog: commonOperations.doOpenDialog,
  doWorkInquiryAction: workOperations.doWorkInquiryOperation,
  getWorkInquiryAction: workOperations.getWorkInquiryOperation
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getWorkInquiryAction();
  }

  render() {
    return (<WorkInquiry
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
