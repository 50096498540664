import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import * as validateRule from '../../validateRule';
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '90px',
    padding: '16px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    width: '60%',
    textAlign: 'center',
    maxWidth: '300px',
  },
    textField: {
    width: '80%',
    maxWidth: '800px',
  },
  hiddenField: {
    top: "-300px",
    position: "fixed",
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

class PasswordResetVerify extends React.Component {
  state = {
    authenticationCode: '',
    authcode: '',
    password: '',
    password2: ''
  };

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  doPasswordReset = async() => {
    console.log("#passwordReset実施#");
    const { passwordResetAction } = this.props;
      
    try {
      console.log('API呼出');
      const result = await passwordResetAction({});
      console.log(result);
      if (result === 'OK') {
        // 正常処理
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // ログイン画面に戻るため
          title: '',
          message: 'passwordResetVerify.dialog.finish',
          action: this.doMoveLogin
        };
        doOpenDialog(dialog);
      }
      else if (result === "EMAIL_NOTHING") {
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // ログイン画面に戻る
          title: '',
          message: 'passwordResetVerify.dialog.error.misoperation',
          action: this.doMoveLogin
        };
        doOpenDialog(dialog);
      }
      else if (result === 'CODE_ERROR') {
        // 認証コード不正
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'passwordResetVerify.dialog.error.input',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'CODE_EXPIRED') {
        // 認証コード有効期限切れ
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'passwordResetVerify.dialog.error.expired',
          action: this.doMoveLogin
        };
        doOpenDialog(dialog);
      }
      else {
        // システムエラーだった場合
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // システムエラーだった場合
      const { doOpenDialog } = this.props;
      const dialog = { 
        type: 'error', // '閉じる'のみ表示 
        title: 'system.message.error.title', 
        message: 'system.message.error.context',
      }; 
      doOpenDialog(dialog);
    }

  };
  
  // ログイン画面に遷移
  doMoveLogin = () => {
    this.props.history.push("/login");
  }
  
  render() {
    const { classes, handleSubmit, newPassword } = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root}>
         <Grid item xs={12}>
          <Field
            id="filled-authenticationCode"
            name="authenticationCode"
            label=<FormattedMessage id={'passwordResetVerify.authenticationCode'} />
            className={classes.textField}
            onChange={this.onChange}
            margin="normal"
            variant="outlined"
            required={true}
            component={renderTextField}
            validate={[
              validateRule.required,
              validateRule.minLength(6),
              validateRule.maxLength(6),
              validateRule.number,
            ]}
          />
          </Grid>
          
          {/* autocomplete対策で、ダミーのID/PW項目を設置 */}
          <Field
            id="dummyIn"
            name="dummyIn"
            margin="normal"
            variant="outlined"
            className={classes.hiddenField}
            component={renderTextField}
            autoComplete="username"
          />
          <Field
            id="dummyPassword"
            name="dummyPassword"
            type="password"
            margin="normal"
            variant="outlined"
            className={classes.hiddenField}
            component={renderTextField}
            autoComplete="current-password"
          />

         <Grid item xs={12}>          
          <Field
            id="filled-password"
            name="newPassword"
            label=<FormattedMessage id={'passwordResetVerify.password'} />
            className={classes.textField}
            type="password"
            onChange={this.onChange}
            margin="normal"
            variant="outlined"
            required={true}
            component={renderTextField}
            autoComplete="new-password"
            validate={[
                validateRule.required,
                validateRule.minLength(8),
                validateRule.maxLength(32),
                validateRule.password
            ]}
          />
          </Grid>
          
         <Grid item xs={12}>             
          <Field
            id="filled-password2"
            name="password2"
            label=<FormattedMessage id={'passwordResetVerify.password.confirm'} />
            className={classes.textField}
            type="password"
            onChange={this.onChange}
            margin="normal"
            variant="outlined"
            required={true}
            component={renderTextField}
            autoComplete="new-password"
            validate={[
              validateRule.required,
              validateRule.passwordConfirm(newPassword ? newPassword : '')
            ]}
          />
          </Grid>
          
          <Grid item xs={12}>
          <Button
            onClick={handleSubmit(this.doPasswordReset)}
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
          >
            <span><FormattedMessage id={'passwordResetVerify.btn.doPasswordReset'} /></span>
          </Button>
      </Grid>
      </form>
      </div>
    );
  }
}

PasswordResetVerify.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const selector = formValueSelector('PasswordResetVerify');
const mapStateToProps = state => ({ 
  initialValues: {
        authenticationCode:null,
        newPassword:null,
        password2:null,
  },
});

PasswordResetVerify = reduxForm({
  form: 'PasswordResetVerify',
  destroyOnUnmount: false,
  enableReinitialize: true, 
})(connect(state => {
  return {
    newPassword: selector(state, 'newPassword'),
  };
})(PasswordResetVerify));

export default withStyles(styles)(connect(mapStateToProps)(PasswordResetVerify));

