const CHANGE_THEME = 'CHANGE_THEME';
const ADD_TODO = 'ADD_TODO';
const DO_LOGIN = 'DO_LOGIN';
const DO_LOGOUT = 'DO_LOGOUT';
const DO_WATER_PRICE = 'DO_WATER_PRICE';
const GET_USER_INFO = 'GET_USER_INFO';
const DO_WATER_PRICE_INFO = 'DO_WATER_PRICE_INFO';
const DO_PASSWORD_RESET = 'DO_PASSWORD_RESET';
const SEND_AUTHENTICATION_CODE = 'SEND_AUTHENTICATION_CODE';
const DO_USER_REGIST = 'DO_USER_REGIST';
const VERIFY_USER = 'VERIFY_USER';
const GET_ADDRESS_INFO = 'GET_ADDRESS_INFO';
const DO_USER_UPDATE = 'DO_USER_UPDATE';
const DO_PASSWORD_CHANGE = 'DO_PASSWORD_CHANGE';

export default {
  CHANGE_THEME,
  ADD_TODO,
  DO_LOGIN,
  DO_LOGOUT,
  DO_WATER_PRICE,
  GET_USER_INFO,
  DO_WATER_PRICE_INFO,
  DO_PASSWORD_RESET,
  SEND_AUTHENTICATION_CODE,
  DO_USER_REGIST,
  VERIFY_USER,
  GET_ADDRESS_INFO,
  DO_USER_UPDATE,
  DO_PASSWORD_CHANGE
};
