import { combineReducers } from 'redux';
import CONSTANTS from './constants';
import types from './types';
/* State shape
state: {
  todos: [],
  visibilityFilter: SHOW_ALL
}
*/

// this method can be confusing because it serves two purposes:
// 1 - it create a new todo
// 2 - it toggles the completed state of an existing todo
const todo = (state, action) => {
  switch (action.type) {
    case types.ADD_TODO:
      return action.todo;
    case types.TOGGLE_TODO:
      if (state.id !== action.id) {
        return state;
      }
      return {
        ...state,
        completed: !state.completed,
      };
    default:
      return state;
  }
};

const todos = (state = ['hoge', 'foo', 'bar'], action) => {
  switch (action.type) {
    case types.ADD_TODO:
      return [...state, todo(undefined, action)];
    case types.TOGGLE_TODO:
      return state.map(t => todo(t, action));
    default:
      return state;
  }
};

const visibilityFilter = (state = CONSTANTS.SHOW_ALL, action) => {
  switch (action.type) {
    case types.SET_VISIBILITY_FILTER:
      return action.filter;
    default:
      return state;
  }
};

const theme = (state = 'default', action) => {
  switch (action.type) {
    case types.CHANGE_THEME:
      return action.theme || state;
    default:
      return state;
  }
};


const loginInfo = (state = null, action) => {
  switch (action.type) {
    case types.DO_LOGIN:
      return action.loginInfo;
    default:
      return state;
  }
};

const isLoggedIn = (state = false, action) => {
  switch (action.type) {
    case types.DO_LOGIN:
      return action.loginInfo.isLoggedIn;
    case types.DO_LOGOUT:
      return false;
    default:
      return state;
  }
};

const userInfo = (state = null, action) => {
  switch (action.type) {
    case types.GET_USER_INFO:
      return action.userInfo;
    default:
      return state;
  }
};

const waterPriceInfo = (state = null, action) => {
  switch (action.type) {
    case types.DO_WATER_PRICE_INFO:
      return action.waterPriceInfo;
    default:
      return state;
  }
};

const sendAuthenticationCodeInfo = (state = null, action) => {
  switch (action.type) {
    case types.SEND_AUTHENTICATION_CODE:
      return action.sendAuthenticationCodeInfo;
    default:
      return state;
  }
};

const passwordResetInfo = (state = null, action) => {
  switch (action.type) {
    case types.DO_PASSWORD_RESET:
      return action.passwordResetInfo;
    default:
      return state;
  }
};

const userRegistInfo = (state = null, action) => {
  switch (action.type) {
    case types.DO_USER_REGIST:
      return action.userRegistInfo;
    default:
      return state;
  }
};

const verifyUserInfo = (state = null, action) => {
  switch (action.type) {
    case types.VERIFY_USER:
      return action.verifyUserInfo;
    default:
      return state;
  }
};


// ユーザー情報更新
const userUpdateInfo = (state = null, action) => {
  switch (action.type) {
    case types.DO_USER_UPDATE:
      return action.doUserUpdate;
    default:
      return state;
  }
};



const reducer = combineReducers({
  todos,
  visibilityFilter,
  theme,
  isLoggedIn,
  loginInfo,
  userInfo,
  waterPriceInfo,
  sendAuthenticationCodeInfo,
  passwordResetInfo,
  userRegistInfo,
  verifyUserInfo,
  userUpdateInfo
});

export default reducer;
