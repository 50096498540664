import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import Conf from '../../../config/config.js';


const styles = theme => ({
  root: {
    textAlign: 'center',
    flexDirection: 'column',
    padding: '35px 10px 16px 10px',
  },
  root2: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  card: {
    textAlign: 'center',
    margin: '10px',
  },
  cardTop: {
    margin: '0 16px 16px 16px',
  },
  center: {
    textAlign: 'center',
  },
  button: {
    textAlign: 'center',
    marginTop: '20px',
    width: '60%',
    maxWidth: '300px',
  },
  cell: {
    textAlign: 'center'
  },
  typographyHead: {
    margin: '15px 0 0 10px',
    fontSize: '1.0em'
  },
  typography: {
    margin: '10px 0 0 5px',
    fontSize: '0.8em'
  },
  typoLink: {
    margin: '10px 0 0 18px',
    fontSize: '0.8em'
  },
  typographySec: {
    textAlign: 'left',
    color: "#d50000",
    border: "solid 2px #d50000",
    margin: '20px 10px 0 10px',
    padding: '0 0 0 5px',
    borderRadius: "0.2em",
    fontSize: "1.0em",
  },
  typographyText: {
    textAlign: 'left',
    margin: '5px 10px 0 16px',
    fontSize: '0.8em'
  },
  paper: {
    padding: "5px",
    align: 'right',
    textAlign: 'left',
    width: "50%",
  },
  conf: {
    paddingRight: "100px",
    },
  conf2: {
    paddingRight: "18px",
  },
  conf3: {
    paddingRight: "127px",
  },
});

const ExPanelSummary = withStyles(theme => ({
  root: {
    padding: '5px 5px 5px 10px !important',
    height: '18px',
    backgroundColor: "#e8f5e9",
    minHeight: "45px",
      '&$expanded': {
        minHeight: "45px",
      },
  },
  expanded: {
    padding: "12px 0 12px 0px"
  },
  expandIcon: {
    padding: "0",
  }
}))(ExpansionPanelSummary);

const ExPanel = withStyles(theme => ({
  root: {
    fontSize: "1.0em"
  },
  expanded: {
    margin: "0"
  },
}))(ExpansionPanel);

const ExPanelDetail = withStyles(theme => ({
  root: {
    padding: "0 10px 10px 10px",
    display: "block",
  }
}))(ExpansionPanelDetails);

class ImportantPage extends React.Component {
  state = { checkFlgs : [false, false, false, false, false] };
  setValue = (val) => {
    let { checkFlgs } = this.state;
    checkFlgs[val] = true;
    this.setState({checkFlgs: checkFlgs});
  }

  onClickNext = () => {
    this.props.history.push('/workRequest');
  };

  onClickBack = () => {
    this.props.history.push('/workMenu');
  };

render() {
    const { classes } = this.props;
    const { checkFlgs } = this.state;
    let nextable = false;
    for (const checkFlg of checkFlgs) {
      nextable = checkFlg;
      if (!checkFlg) break;
    }
    return (
    <div className={classes.root}>
      <form noValidate autoComplete="off" className={classes.root}>
        <Typography variant="subtitle2" className={classes.typographySec}><span><FormattedMessage id={'importantPage.message.confirm'}/></span></Typography>
        <Typography className={classes.typographyText}><font color="#d50000">次の事項は，防護管等取付に係わる事項です。特に【必須】となっている項目については重要事項のため必ずご確認ください。</font></Typography>
        <Grid container >
          <Grid item xs={12} md={12}>
            <Card className={classes.root2}>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />} onClick={()=>this.setValue(0)}>
                  <Typography><font color="#d50000">【必須】</font>必要な費用負担（作業・調査・申請・キャンセル）</Typography>
                </ExPanelSummary>
                <ExPanelDetail >
                  <Typography className={classes.typography}>
                    　防護管取付サービス契約にもとづき申受ける防護管取付サービス料金は、取付分と取外分を合わせたもので、その内訳は「サービス基本料金」「加算料金」および申請費用などの「実費」の合計となります。<br />
                    　防護管取付サービス料金の詳細は、申込後に見積書にてお知らせします。<br />
                    　なお、取付けに必要な資材代は本サービス料金に含まれます。<br />
                  <br />
                    　また、申込時に、取付希望現場の事前調査を要望された場合等は、上記とは別に現場調査・設計費用を申受けます。<br />
                  <br />
                    　キャンセルについては、申込受付後、既に実施した調整や諸手配等の実働がある場合に、キャンセル料として申受ける場合があります。<br />
                  <br />
                    　既に防護管等を取付けている箇所で、電線等の設備管理者が緊急にやむを得ず当該設備を取外す場合には、本サービスで取付けた防護管等についてもあわせて取外します。<br />
                    　なお、この場合、既に貰い受けている防護管取付サービス料金の返還は行いません。<br />
                  <br />
                    　防護管取付サービス料金は、三井住友カード株式会社に債権譲渡します。取付後に、三井住友カード株式会社より請求させていただきます。<br />
                    　なお、防護管取付サービス料金は、取付分と取外分を合わせた一体のものであり、取外分に相当する料金も含めて、取付後に三井住友カード株式会社より請求させていただきます。<br />
                  </Typography>
                </ExPanelDetail>
              </ExPanel>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />} onClick={()=>this.setValue(1)}>
                  <Typography><font color="#d50000">【必須】</font>災害時・悪天候時のやむを得ない作業予定日の延期</Typography>
                </ExPanelSummary>
                <ExPanelDetail >
                  <Typography className={classes.typography}>
                    　強風・雷雨等の天候事情などにより、やむを得ず作業予定を延期させていただくことがあります。
                  </Typography>
                </ExPanelDetail>
              </ExPanel>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />} onClick={()=>this.setValue(2)}>
                  <Typography><font color="#d50000">【必須】</font>取付後の防護管等の管理責任</Typography>
                </ExPanelSummary>
                <ExPanelDetail>
                  <Typography className={classes.typography}>
                    　防護管の取付状況（取付けの範囲・状態）は、弊社での取付作業完了後に責任者さまにより確認をお願いします。また、日々の作業着手前および作業中においても責任者さまにより取付状況等の確認をお願いします。<br />
                    　防護管等（防護シート・防護ネット・ジャバラ管を含む）は、工事を行う際に電力設備に触れることがないようあくまでも目印として取付けるものであり、責任者さまにより防護管等を取付けた部分に絶対に作業者が触れたり、工作物が接触したりしないよう必要な措置を講じるようお願いします。責任者さまにより常に作業者の安全を確保していただきますようお願いします。
                  </Typography>
                </ExPanelDetail>
              </ExPanel>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />} onClick={()=>this.setValue(3)}>
                  <Typography><font color="#d50000">【必須】</font>契約に係る事前のお知らせ事項</Typography>
                </ExPanelSummary>
                <ExPanelDetail>
                  <Typography className={classes.typography}>
                    １．防護管取付サービス契約の成立<br />
                    <span style={{display:"block", marginLeft: "20px"}}>
                      この入力用システムの「お見積金額確認」画面において、「見積金額明細」および「約款」を確認し決定権限者の承認を得た後、「承諾」ボタンを押していただくことで契約成立となります。
                    </span>
                    ２．防護管の取付時期<br />
                    <span style={{display:"block", marginLeft: "20px"}}>
                      事業者さまの希望に沿うように作業日等を調整いたしますが、やむを得ず取付希望日までに作業が実施できない場合がありますので、予めご了承ください。<br />
                      <span style={{display:"block", marginLeft: "20px"}}>
                        （取付希望日までに作業が実施できない場合の例）<br />
                        ・契約成立から、３週間程度の期間が確保されていない場合<br />
                        ・作業場所により、作業に必要な申請等を実施する必要がある場合<br />
                        ・自然災害・悪天候等により、計画した作業日に作業ができない場合　など<br />
                      </span>
                    </span>
                    ３．キャンセル料<br />
                    <span style={{display:"block", marginLeft: "20px"}}>
                      事業者さまからの申込みを受付後、防護管取付に向けた調整を開始するため、以後のキャンセルにおいては、キャンセル料を申受ける場合があります。
                    </span>
                    ４．取付作業時の申込者さまの立会<br />
                    <span style={{display:"block", marginLeft: "20px"}}>
                      取付作業日が決まりましたら、メールにてお知らせします。現地にて立会をしていただきますと、取付範囲の細かな確認等が実施していただけます。<br />
                      なお、取付範囲が変更になる場合は、見積金額明細でお知らせしています防護管取付サービス料金を変更することがあります。
                    </span>
                  </Typography>
                </ExPanelDetail>
              </ExPanel>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />} onClick={()=>this.setValue(4)}>
                  <Typography><font color="#d50000">【必須】</font>作業に伴い申請書を提出する場合の準備期間</Typography>
                </ExPanelSummary>
                <ExPanelDetail>
                  <Typography className={classes.typography}>
                    　防護管等の取付作業を実施するにあたり、道路管理者への申請等が必要な場合は通常の作業準備期間（３週間程度）に加え、申請書作成～申請許可に要する期間として少なくとも１か月程度を必要としますので、ご理解をお願いします。<br />
                    　なお、事業者さまで確保される作業用エリア内に、作業車等を配置して作業を行うことも可能な場合がありますので、申込時にご相談ください。
                  </Typography>
                </ExPanelDetail>
              </ExPanel>
              <ExPanel >
                <ExPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>災害防止に向けた電気安全PR</Typography>
                </ExPanelSummary>
                <ExPanelDetail>
                  <Typography className={classes.typography}>　災害防止に向けた電気安全PRについては、以下を参照願います。
                  </Typography>
                  <Typography className={classes.typoLink}><a target="_blank" rel="noopener noreferrer" href={Conf.MANUAL_SAFETY_PR_URL}>災害防止に向けた電気安全PR</a></Typography>
                </ExPanelDetail>
              </ExPanel>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              size="large"
              disabled={!nextable}
              onClick={this.onClickNext}
            >
              <span><FormattedMessage id={'common.btn.next'}/></span>
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              size="large"
              onClick={this.onClickBack}
            >
              <span><FormattedMessage id={'common.btn.back'}/></span>
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div style={{margin:"10px 0 20px 0"}}>
              <Typography className={classes.conf} align="right" >　<span><FormattedMessage id={'importantPage.contact'}/></span></Typography>
              <Typography className={classes.conf2} align="right" ><span>{Conf.company}</span></Typography>
              <Typography className={classes.conf3} align='right'><span>{Conf.tell}</span></Typography>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
    );
  }
}

ImportantPage.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
export default withStyles(styles)(ImportantPage);
