import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import * as validateRule from '../../validateRule';
import UserHeader from '../../organisms/UserHeader';
import { workSelectors } from '../../../reducks/work';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 16px 4px',
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    textAlign: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  card: {
    margin: '16px 16px 40px 16px',
    textAlign: 'center',
  },
  cardTop: {
    margin: '0 16px 16px 16px',
  },
  typography:{
    margin: '0 20px 30px 20px'
  },
  typography2:{
    margin: '0 20px 0 20px'
  },
  typography3:{
    margin: '38px 0 0 4px',
    fontSize: "1.5em"
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  button2: {
    marginTop: '20px',
    width: '80%',
    textAlign: 'center',
    maxWidth: '300px',
  },
  upload: {
    margin: '10px 20px 0px 20px',
    alignItems: 'center',
  },
  textFieldL: {
    margin: '10px 20px 5px 20px',
  },
  textFieldS: {
    margin: '30px 20px 5px 20px',
    width: '40%'
  },
  dateMark: {
    marginTop: "22px",
    fontSize: "1.2em"
  }
});

const renderTextField = ({
  input,
  label,
  defaultValue,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    defaultValue={defaultValue}
    {...input}
    {...custom}
  />
);

class WorkRemoval extends React.Component {

  // 取外し申込ボタンクリック
  onClickRemove = () => {
    const { doOpenDialog } = this.props;

    const dialog = {
      type: 'okcancel', // 「確認」と「キャンセル」を表示
      title: '',
      message: 'workRemoval.dialog.confirm',
      action: this.doAction,
    };
    doOpenDialog(dialog);

  }


  // 取外し申込処理実行
  doAction = async() => {

    const { doApplyRemovalAction, doOpenDialog } = this.props;
    try {
      const result = await doApplyRemovalAction();
      if (result === '00000') {
        // 正常
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workRemoval.dialog.finish',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else if (result === '21100') {
        // 取外し申込失敗
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workRemoval.dialog.error.failure',
          action: this.doMoveMenu
        };
        doOpenDialog(dialog);
      }
      else {
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }
  }

  // 申込内容確認画面に遷移
  onClickBack = () => {
    this.props.history.push('/workConsideration');
  };

  // メニュー画面に遷移
  doMoveMenu = () => {
    this.props.history.push("/workMenu");
  }

  render() {
    const { classes, applyDetail, handleSubmit } = this.props;

    return (
      <div className={classes.root}>
      <form noValidate autoComplete="off" className={classes.root}>
     <UserHeader />
        <Typography variant="h5" className={classes.typography}>{ applyDetail.data ? "件名：" + applyDetail.data.OrderName__c : "" }</Typography>

        <Typography variant="subtitle1" className={classes.typography2}>①取外し予定日が、この日程で問題ない場合は、「確認」をクリックください</Typography>
        <Typography variant="subtitle1" className={classes.typography2}>②変更を希望される場合は、取外し予定日を変更して、「確認」をクリックください</Typography>
        <Typography variant="subtitle1" className={classes.typography2}>③変更理由などご連絡事項があれば、ご連絡事項に入力ください</Typography>


{/* 取外し予定日 */}
        <Grid container>
          <Field
            id="RemvScheduleDate__c"
            name="RemvScheduleDate__c"
            accessibilitylabel="RemvScheduleDate__c"
            label=<FormattedMessage id={'workRemoval.in.removaldate'}/>
            component={renderTextField}
            style={{width: "180px"}}
            margin="normal"
            variant = "outlined"
            required={true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            validate={[
                validateRule.required,
                validateRule.pastDate
            ]}
          />
          <Typography className={classes.typography3}><FormattedMessage id={'workRemoval.message.after'}/></Typography>
        </Grid>
{/* ご連絡事項 */}
        <Field
          id="Message2__c"
          name="Message2__c"
          component={renderTextField}
          label=<FormattedMessage id={'workRemoval.in.memo'}/>
          margin="normal"
          variant="outlined"
          type="text"
          multiline={true}
          rows={3}
          validate={[
            validateRule.maxLength(250),
          ]}
        />

       <div className={classes.root2}>
       <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button2}
          size="large"
          onClick={handleSubmit(this.onClickRemove)}
          disabled={ applyDetail && applyDetail.data.StatusOperator__c === "取付済" && applyDetail.data.RemoveProperty__c === "否" ? false: true }
        >
          <span><FormattedMessage id={'workRemoval.btn.confirm'}/></span>
        </Button>
        </Grid>
        <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button2}
          size="large"
          onClick={this.onClickBack}
        >
          <span><FormattedMessage id={'common.btn.back'}/></span>
        </Button>
        </Grid>
        </div>
      </form>
      </div>
    );
  }
}

const FORM_NAME = "WorkRemoval";

const mapStateToProps = state => ({
  initialValues: {
    RemvScheduleDate__c : workSelectors.getInitRemvScheduleDate(state.work.applyDetail),
    Message2__c: null,
  }
});

WorkRemoval = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect(state => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(WorkRemoval));

WorkRemoval.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(WorkRemoval)
);
