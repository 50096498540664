import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from "redux-form";
import * as validateRule from '../../validateRule';
import * as common from '../../common';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    marginTop: '70px'
  },
    root2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px'
  },
    button2: {
    marginTop: '20px',
    width: '80%',
    textAlign: 'center',
    maxWidth: '400px',
  },
  serchbutton: {
    marginTop: '20px',
    marginLeft: '10px'
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '20px 20px -10px 20px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    variant: "subtitle2",
  },
  typographyCap: {
    color: "#757575",
    variant: "subtitle2",
  },
  textFieldGrid: {
    width: '100%'
  },
  hiddenField: {
    top: "-300px",
    position: "fixed",
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    inputProps={{
      min: 1
    }}
    {...input}
    {...custom}
  />
);

class UserRegist extends React.Component {
state = {
    errFlg:false
  };

  getAddress = async() => {
    const { zipCode } = this.state;
    console.log("#住所検索呼出#");
    const { getAddressAction } = this.props;

    if(zipCode === undefined || zipCode === ""){
      return;
    }
    try {
      const result = await getAddressAction(zipCode);
      if(result.data.body.data.status === 200){
        //正常
        if(result.data.body.data.results){
          var info = result.data.body.data.results[0];
          console.log(info.address1,info.address2,info.address3);
          this.props.change('companyaddress', info.address1 + info.address2 + info.address3);
        }else{
          //データ無し
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'userRegist.dialog.error.nondata',
          };
          doOpenDialog(dialog);
        }
      }else if (result.data.body.data.status === 400) {
        // 入力値不正エラーだった場合
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'message.error.90000',
        };
        doOpenDialog(dialog);
      }else{
        //異常
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'userRegist.dialog.error.system',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // システムエラーだった場合
      const { doOpenDialog } = this.props;
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }
  };

  backPolicy = async() => {
    console.log("#userPolicy画面へ戻る#");
    this.props.history.push('/userPolicy');
  };

  confirmUserRegist = () => {
    const { values } = this.props;
    if (!values.mobile && !values.telephone) {
      this.setState({errFlg: true});
      return;
    }else{
      this.setState({errFlg: false});
    }
    console.log("#userRegistConfirm表示#");
    this.props.history.push('/userRegistConfirm');
  };

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  changeTel = (e) => {
    if (e.target.value.length > 0) {
      this.setState({errFlg: false});
    }
  }

  judgeErr = (e) => {
    this.cnvFull2Harf(e);
    const { values } = this.props;
    if (values) {
      if (!values.mobile && !values.telephone) {
        this.setState({errFlg: true});
      }else{
        this.setState({errFlg: false});
      }
    }else{
        this.setState({errFlg: true});
    }
  }

  cnvFull2Harf = (e) => {
    const { name, value } = e.target;
    let harf = common.full2Harf(value);
    this.props.change(name, harf);
    e.preventDefault();
  }

  cnvHarf2Full = (e) => {
    const { name, value } = e.target;
    let full = common.harf2Full(value);
    this.props.change(name, full);
    e.preventDefault();
  }

  render() {
    const { classes, values, handleSubmit } = this.props;

    return (
      <div>
          <form noValidate autoComplete="off" className={classes.root}>
            <Field
              id="mAddress"
              name="mAddress"
              label=<FormattedMessage id={'userRegist.mailaddress'} />
              className={classes.textField}
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
              validate={[
                validateRule.required,
                validateRule.minLength(6),
                validateRule.maxLength(80),
                validateRule.email,
                validateRule.emailDomain,
              ]}
              onBlur={this.cnvFull2Harf}
            />
            <Field
              id="companynameKanji"
              name="companynameKanji"
              label=<FormattedMessage id={'userRegist.companynameKanji'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              required={true}
//              inputProps={{ inputMode: "text" }}
              type="text"
              component={renderTextField}
              validate={[
                validateRule.required,
                validateRule.maxLength(38),
                validateRule.fullWidth,
              ]}
              onBlur={this.cnvHarf2Full}
            />
            <Field
              id="companyname"
              name="companyname"
              label=<FormattedMessage id={'userRegist.companyname'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              required={true}
//              inputProps={{ inputMode: "text" }}
              type="text"
              component={renderTextField}
              validate={[
                validateRule.required,
                validateRule.maxLength(38),
                validateRule.fullKanaWidth,
              ]}
              onBlur={this.cnvHarf2Full}
            />
            <Grid container spacing={24}>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFamily"
                  name="usernameFamily"
                  label=<FormattedMessage id={'userRegist.usernameFamily'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
//                  inputProps={{ inputMode: "text" }}
                  type="text"
                  component={renderTextField}
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFirst"
                  name="usernameFirst"
                  label=<FormattedMessage id={'userRegist.usernameFirst'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
//                  inputProps={{ inputMode: "text" }}
                  type="text"
                  component={renderTextField}
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFamilyKana"
                  name="usernameFamilyKana"
                  label=<FormattedMessage id={'userRegist.usernameFamilyKana'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
//                  inputProps={{ inputMode: "text" }}
                  type="text"
                  component={renderTextField}
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullKanaWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFirstKana"
                  name="usernameFirstKana"
                  label=<FormattedMessage id={'userRegist.usernameFirstKana'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
//                  inputProps={{ inputMode: "text" }}
                  type="text"
                  component={renderTextField}
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullKanaWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
            </Grid>
            {/* 住所 */}
            <Grid container spacing={24} className={classes.postalRow}>
              <Grid item xs={5}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  label=<FormattedMessage id={'userRegist.zipCode'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: "numeric" }}
//                  type="number"
                  validate={[
                    validateRule.required,
                    validateRule.minLength(7),
                    validateRule.maxLength(7),
                    validateRule.number,
                  ]}
                  onChange={e => this.setState({ zipCode: e.target.value })}
//                  onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 107 || e.keyCode === 109 ) && e.preventDefault() }
                  onBlur={this.cnvFull2Harf}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  id="search"
                  name="search"
                  variant="contained"
                  color="primary"
                  className={classes.serchbutton}
                  size="large"
                  onClick={this.getAddress}
                >
                <span><FormattedMessage id={'userRegist.btn.search'} /></span>
                </Button>
              </Grid>
            </Grid>
            <Field
              id="companyaddress"
              name="companyaddress"
              label=<FormattedMessage id={'userRegist.companyaddress'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              required={true}
//              inputProps={{ inputMode: "text" }}
              type="text"
              validate={[
                validateRule.required,
                validateRule.maxLength(65),
                validateRule.fullWidth,
              ]}
              onBlur={this.cnvHarf2Full}
            />
            {/* 電話番号 */}
{this.state.errFlg &&
// エラーメッセージ
        <div style={{margin:"5px 0 -10px 12px"}}>
          <Typography variant="subtitle2"
            style={{display:"inline-block", fontSize:"0.75em", color:"red"}}>
            <FormattedMessage id={'inputCheckMessage.required2'}/><br />
          </Typography>
        </div>
}
            <Field
              id="mobile"
              name="mobile"
              label=<FormattedMessage id={'userRegist.mobile'} />
              className={classes.textField}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              onChange={this.changeTel}
              onBlur={this.judgeErr}
//              inputProps={{ inputMode: "tel" }}
//              type="tel"
              validate={[
                validateRule.telephone,
                validateRule.maxLength(13)
              ]}
            />

            <Field
              id="telephone"
              name="telephone"
              label=<FormattedMessage id={'userRegist.telephone'} />
              className={classes.textField}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              onChange={this.changeTel}
              onBlur={this.judgeErr}
//              inputProps={{ inputMode: "tel" }}
//              type="tel"
              validate={[
                validateRule.telephone,
                validateRule.maxLength(13)
              ]}
            />
            <Typography className={classes.typographyCap}><FormattedMessage id={'userRegist.telephoneinfo'} /></Typography>
            {/* autocomplete対策で、ダミーのID/PW項目を設置 */}
            <Field
              id="dummyIn"
              name="dummyIn"
              margin="normal"
              variant="outlined"
              className={classes.hiddenField}
              component={renderTextField}
              autoComplete="username"
            />
            <Field
              id="dummyPassword"
              name="dummyPassword"
              type="password"
              margin="normal"
              variant="outlined"
              className={classes.hiddenField}
              component={renderTextField}
              autoComplete="current-password"
            />
            {/* パスワード */}
            <Field
            id="password"
              label=<FormattedMessage id={'userRegist.password'} />
              className={classes.textField}
              name="password"
              type="password"
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
              autoComplete="new-password"
              validate={[
                validateRule.required,
                validateRule.minLength(8),
                validateRule.maxLength(32),
                validateRule.password
              ]}
            />
            <Typography className={classes.typographyCap}><FormattedMessage id={'userRegist.password.info'} /></Typography>
            <Field
              id="password2"
              label=<FormattedMessage id={'userRegist.password2'} />
              className={classes.textField}
              name="password2"
              type="password"
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
              autoComplete="new-password"
              validate={[
                validateRule.required,
                validateRule.minLength(8),
                validateRule.maxLength(32),
                validateRule.passwordConfirm(values ? values.password : '')
              ]}
            />
            <Typography display="inline" className={classes.typographySec}><FormattedMessage id={'userRegist.info'} /></Typography>
            </form>
            <form className={classes.root2}>
             <Grid item xs={12}>
            <Button
             id="btnOk"
              onClick={handleSubmit(this.confirmUserRegist)}
              variant="contained"
              color="primary"
              className={classes.button2}
              size="large"
              type="submit"
            >
              <span><FormattedMessage id={'userRegist.btn.confirm'} /></span>
            </Button>
            </Grid>
           <Grid item xs={12}>
            <Button
             id="btnCancel"
              onClick={this.backPolicy}
              variant="outlined"
              color="primary"
              className={classes.button2}
              size="large"
            >
              <span><FormattedMessage id={'userRegist.btn.backPolicy'} /></span>
            </Button>
          </Grid>
          </form>
        </div>
    );
  }
}

const FORM_NAME = "UserRegist";

const selector = formValueSelector(FORM_NAME);

UserRegist = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect(state => {
  return {
    zipCode: selector(state, 'zipCode'),
    values: getFormValues(FORM_NAME)(state),
  };
})(UserRegist));

UserRegist.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  change: PropTypes.func,
};

export default withStyles(styles)(UserRegist);
