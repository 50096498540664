// this rules is in this example below
// https://redux-form.com/7.3.0/examples/fieldlevelvalidation/
import { intl } from '../core/globalIntl';
import m from "moment";
var dt = m();
var today = dt.format("YYYY-MM-DD");

// 必須チェック
//export const required = value => (value ? undefined : intl.formatMessage({ id: 'inputCheckMessage.required' }));
export const required = value => (value ? (value.trim() ? undefined : intl.formatMessage({ id: 'inputCheckMessage.required' })) : intl.formatMessage({ id: 'inputCheckMessage.required' }));
export const required2 = value => (value ? undefined : intl.formatMessage({ id: 'inputCheckMessage.required2' }));
export const required3 = value => (value ? undefined : intl.formatMessage({ id: 'inputCheckMessage.required3' }));

// 最大文字数チェック
export const maxLength = max => value =>
  value && value.length > max ? intl.formatMessage({ id: 'inputCheckMessage.max' }, { max: max }) : undefined;

// 最少文字数チェック
export const minLength = min => value =>
  value && value.length < min ? intl.formatMessage({ id: 'inputCheckMessage.min' }, { min: min }) : undefined;

// 数字チェック
export const number = value =>
  value && !value.match(/^[0-9]+$/) ? intl.formatMessage({ id: 'inputCheckMessage.number' }) : undefined; // eslint-disable-line no-restricted-globals

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

// メールアドレス形式チェック
export const email = value =>
  value && !/^[A-Z0-9.!#$%&'*/=?^_+-`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  intl.formatMessage({ id: 'inputCheckMessage.mailaddress' }) :
  undefined;

// メールアドレスドメインチェック()
export const emailDomain = value => 
  value && (/^[A-Z0-9.!#$%&'*/=?^_+-`{|}~]+@(?=.*-\.).*$/i.test(value) || /^[A-Z0-9._%+-]+@(?=.*\.-).*$/i.test(value)) ?
  intl.formatMessage({ id: 'inputCheckMessage.mailaddress' }) :
  undefined;
  
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ?
  'Only alphanumeric characters' :
  undefined;

// 全角文字チェック
export const fullWidth = value =>
  !value ? undefined : (value && value.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/) ?
//  !value ? undefined : (value && value.match(/^[^\\x01-\x7E\xA1-\xDF]+$/) ?
    undefined :
    intl.formatMessage({ id: 'inputCheckMessage.fullchar' }));

// 全角文字チェック(スペース不可)
export const fullWidthWithoutSpace = value =>
  !value ? undefined : (value && value.match(/^[^\x01-\x7E\uFF61-\uFF9F　]+$/) ?
//  !value ? undefined : (value && value.match(/^[^\\x01-\x7E\xA1-\xDF　 ]+$/) ?
    undefined :
    intl.formatMessage({ id: 'inputCheckMessage.fullchar' }));
    
// パスワード形式チェック
export const password = value =>
  value && (!value.match(/[A-Z]/) || !value.match(/[a-z]/) || !value.match(/\d/))?
  intl.formatMessage({ id: 'inputCheckMessage.password' }) :
  undefined;

/*
export const password = value =>
  value && (!value.match(/[A-Z]/) || !value.match(/[a-z]/) || !value.match(/\d/) || !value.match(/[\^\$\*\.\[\]{}()?\-"!@#%&\/\\,><':;|_~`]/)) ?
  intl.formatMessage({ id: 'inputCheckMessage.password' }) :
  undefined;
*/

// 確認用パスワードチェック
export const passwordConfirm = password => value =>
  value && value !== password ? intl.formatMessage({ id: 'inputCheckMessage.passwordConfirm' }) : undefined;

// 全角カタカナチェック(全角スペースと全角ハイフンもOK)
export const fullKanaWidth = value =>
  !value ? undefined : (value && value.match(/^[ァ-ヶー　]+$/) ?
    undefined :
    intl.formatMessage({ id: 'inputCheckMessage.fullKanachar' }));

// 全角カタカナチェック(全角スペースNGで全角ハイフンOK)
export const fullKanaWidthWithoutSpace = value =>
  !value ? undefined : (value && value.match(/^[ァ-ヶー]+$/) ?
    undefined :
    intl.formatMessage({ id: 'inputCheckMessage.fullKanachar' }));
    

// 電話番号チェック(先頭は必ず０、ハイフンは少なくとも１つは入る)
export const telephone = value =>
  !value ? undefined : (value && value.match(/^0[0-9]+-[0-9-]*[0-9]+$/) ?
    undefined:
    intl.formatMessage({ id: 'inputCheckMessage.telephone' }));
    
// 過去日付チェック(システム日付当日はOK)
export const pastDate = value =>
  !value ? undefined : (value && today > value) ? intl.formatMessage({ id: 'inputCheckMessage.pastdays' }) : undefined ;

// 日付妥当性チェック
export const correctDate = fromVal => value =>
  !fromVal || fromVal === "" ? undefined : fromVal <= value ? undefined : intl.formatMessage({ id: 'inputCheckMessage.wrongday' });

