import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid'; 
import MenuItem from '@material-ui/core/MenuItem'; 
import ArrowForward from '@material-ui/icons/ArrowForwardIosSharp';
import m from "moment";
import Upload from '../../organisms/UploadCustom';
import { Field, reduxForm, getFormValues } from 'redux-form'; 
import { FormattedMessage } from 'react-intl'; 
import { connect } from 'react-redux'; 
import { injectIntl } from 'react-intl'; 
import * as validateRule from '../../validateRule';
import UserHeader from '../../organisms/UserHeader';
import { intl } from '../../../core/globalIntl';
import Conf from '../../../config/config.js';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 16px 4px',
  },
  fields: {
    margin: '0px 10px 15px 10px'
  },
  fields2: {
    margin: '0'
  },
  fields3: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 16px 4px',
    margin: '0'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  card: {
    margin: '16px 16px 30px 16px',
    textAlign: 'center',
  },
  cardHeader: {
    header: {
    padding: '0 !imp',
    },
  },
  typographyTop:{
    margin: '0 0 0 10px',
    fontSize: "1.0em"
  },
  typographyExp:{
    margin: '10px 0 10px 20px',
    fontSize: "0.9em"
  },
  typographyExp2:{
    margin: '0 0 0 20px',
    fontSize: '0.8em'
  },
  typography:{
    margin: '0 0 0 40px'
  },
  typography2:{
    margin: '40px 10px 0 10px'
  },
  typography3:{
    margin: '10px 10px -10px 16px'
  },
  typography4:{
    margin: '10px 10px 0 16px'
  },
  typographySample: {
    border: "solid 1px #d50000",
    color: "#d50000",
    margin: '6px 0 0 10px',
    padding: '5px 0',
    borderRadius: "0.1em",
    fontSize: "0.9em",
    width: "190px",
    textAlign: "center",
  },
  textFieldL: {
    margin: '10px 10px 5px 10px',
    height: "50"
  },
  selPrefecture: {
    margin: '10px 10px 5px 10px',
    width: '120px'
  },
  textFieldS: {
    margin: '10px 10px 5px 10px',
    width: '55%'
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '35%',
    minWidth: '240px',
  },
  textFieldOther: {
    margin: '0px 10px 15px 35px',
    width: '200px'
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  formControl: {
    margin: '10px 20px 10px 20px',
  },
    formControl2: {
    margin: '10px 20px 0 20px',
  },
    formControl3: {
    margin: '-21px 20px 0 0',
    paddingLeft:"25px",
  },
  upload: {
    margin: '10px 20px 0px 10px',
    alignItems: 'center',
  },
  formLabel: {
    color: "#212121",
    margin: "0 0 5px -5px"
  },
  commentHeight: {
    margin: '10px 20px 5px 20px',
  },
  dateMark: {
    marginTop: "22px",
    fontSize: "1.2em"
  },
  termArea: {
    display: 'flex',
  },
  explanation: {
    margin: "0 0 10px 0"
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '10px 10px 0 10px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  typographySec2: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '0 10px 10px 10px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  fordaysArea: {
    margin: "0 0 15px -8px",
    display: "flex",
  },
  fordaysArrow: {
    marginTop: "15px",
  },
  fordaysTextArea: {
    border: "solid 1px rgb(192,192,192)",
    borderRadius: "4px",
    display: "table",
  },
  fordaysText: {
    padding: "0 8px 0 8px",
    display: "table-cell",
    verticalAlign: "middle",
  },
});

const RadioB = withStyles(theme => ({
  root: {
    padding: "0px 5px 0px 10px",
    '&$checked': {
      color: '#4caf50'
    }
  },
  checked: {
    },
}))(Radio);


/*
var distributionLine = false;
var incomingLine = false;
var device = false;
var communicationLine = false;
var other = false;
*/
var termErrFlg = false;

const validate = (values) => {
  const errors = {};
  if (values.StartRequireAttachTerm__c ) {
    if (values.EndRequireAttachTerm__c) {
      if (values.StartRequireAttachTerm__c > values.EndRequireAttachTerm__c) {
        errors.EndRequireAttachTerm__c = {_error: intl.formatMessage({ id: 'inputCheckMessage.wrongday' })};
      }
    }
  }

  if ((!values.DistributionLine__c ) &&
        (!values.IncomingLine__c ) &&
        (!values.Device__c ) &&
        (!values.CommunicationLine__c ) &&
        (!values.Other__c )
  ) {
    termErrFlg = true;
    errors.DistributionLine__c = {_error: "error"};
  } else {
    termErrFlg = false;
  }
  return errors;
};


const renderSelect = ({ 
  input, 
  label, 
  meta: { touched, error, valid }, 
  ...custom 
}) => ( 
  <TextField 
    select 
    label={label} 
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  /> 
); 

const renderTextField = ({ 
  input, 
  label, 
  meta: { touched, error, valid }, 
  ...custom 
}) => ( 
  <TextField 
    label={label}
    helperText={touched && error} 
    error={touched && !valid} 
    {...input} 
    {...custom} 
  /> 
); 

const renderCheckbox = ({ input, label,  meta: { touched, error, valid }, 
  ...custom  }) => {
/*
console.log("inputの値");
console.log(input["name"] + ":" + input["value"]);

  switch (input["name"]) {
      case "DistributionLine__c":
          if (input["value"]) {
            distributionLine = true;
          }else{
            distributionLine = false;
          }
      case "IncomingLine__c":
          if (input["value"]) {
            incomingLine = true;
          }else{
            incomingLine = false;
          }
      case "Device__c":
          if (input["value"]) {
            device = true;
          }else{
            device = false;
          }
      case "CommunicationLine__c":
          if (input["value"]) {
            communicationLine = true;
          }else{
            communicationLine = false;
          }
      case "Other__c":
          if (input["value"]) {
            other = true;
          }else{
            other = false;
          }
  }

  if (!distributionLine && !incomingLine && !device && !communicationLine && !other) {
    termErrFlg = true;
  }else{
    termErrFlg = false;
  }
console.log(distributionLine  + ":" + incomingLine + ":" + device + ":" + communicationLine + ":" + other);
console.log(termErrFlg);
*/
  return (
    <FormControlLabel 
      control={ 
        <Checkbox 
          onChange={input.onChange}
          color="primary"
          style={{padding: "0px 6px 0 12px"}}
          {...custom} 
        /> 
      } 
      label = { label } 
    />
  ); 
}; 


const renderRadioGroup = ({
 input,
 label,
 meta: { touched, error },
 ...rest
}) => (
   <div> {label}
     <RadioGroup
       {...input}
       {...rest}
     />
     {touched && error &&
       <Typography variant="caption" color="error">
         {error}
       </Typography>
     }
   </div>
 );

renderTextField.propTypes = { 
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types 
  label: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types 
  meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types 
}; 


class WorkRequest extends React.Component {
  
  state = {
    ImageDatas: [],
    forDays: null,
  };

  
  // 取付登録確認
  doWorkRequest = () => { 
  
    const { doOpenDialog } = this.props; 
    const dialog = { 
      type: 'okcancel', // 「確認」と「キャンセル」を表示 
      title: '', 
      message: 'workRequest.dialog.inquiry', 
      action: this.doAction
    };
    doOpenDialog(dialog);
  }

  
  // 取付登録処理
  doAction = async() => {
  
    console.log("#取付申込#");
    const { doWorkRequestAction, doOpenDialog } = this.props; 
    try { 
      const result = await doWorkRequestAction(this.state.ImageDatas);
      console.log(result); 
      if (result === "00000") { 
        // 正常
        const dialog = { 
          type: 'ok', // '閉じる'のみ表示 
          title: '', 
          message: 'workRequest.dialog.finish',
          action: this.onClickBack
        }; 
        doOpenDialog(dialog); 
      } else if (result === "20500" || result === "20501") { 
        // 20500：お客様情報取得失敗(cognitoからの取得)
        // 20501：防護管申込 失敗
        const dialog = { 
          type: 'ok', // '閉じる'のみ表示 
          title: '', 
          message: 'workRequest.dialog.error.failure',
          action: this.onClickBack
        }; 
        doOpenDialog(dialog);
      } else if (result === "ERROR_UPLOAD") {
        // ファイルアップロード失敗(メッセージを表示してメニュー画面表示)
        const dialog = { 
          type: 'ok', // '閉じる'のみ表示 
          title: '', 
          message: 'workRequest.dialog.error.failure.upload',
          action: this.onClickBack
        }; 
        doOpenDialog(dialog);
      }
      else { 
        // その他エラー 
        const dialog = { 
          type: 'error', // '閉じる'のみ表示 
          title: 'system.message.error.title', 
          message: 'system.message.error.context',
        }; 
        doOpenDialog(dialog); 
      } 
    } 
    catch (err) { 
      console.log(`エラー: ${err}`); 
      // その他エラー 
      const dialog = { 
          type: 'error', // '閉じる'のみ表示 
          title: 'system.message.error.title', 
          message: 'system.message.error.context',
      }; 
      doOpenDialog(dialog); 
    } 
 
  }; 
  
  
  // 戻る(メニュー画面に遷移)
  onClickBack = () => {
    this.props.history.push('/workMenu');
  };
  
  // 写真アップロード
  setFiles = (e) => {
    this.setState({ImageDatas: e});
  }

  judgeErr = () => {
    const { values } = this.props;
    if (values) {
      if (values.StartRequireAttachTerm__c && values.EndRequireAttachTerm__c && values.StartRequireAttachTerm__c > values.EndRequireAttachTerm__c) {
        this.setState({errFlg: true});
      }else{
        this.setState({errFlg: false});
      }
    }else{
        this.setState({errFlg: true});
    }
  }

  onChangeTerm = (e) => {
    // イベントから要素を取得
    const target = e.target;

    // 値が未設定の場合は処理終了
    const eventValue = target.value;
    if (!eventValue) {
      this.setState({ forDays: null });
      return;
    }

    const { values } = this.props;

    let startStr;
    let endStr;

    // 変更した項目の値と、対になる項目の入力済の値をそれぞれ取得
    const name = target.name;
    if (name === 'StartRequireAttachTerm__c') {
      startStr = eventValue;
      endStr = values.EndRequireAttachTerm__c
    } else if (name === 'EndRequireAttachTerm__c') {
      startStr = values.StartRequireAttachTerm__c;
      endStr = eventValue;
    } else {
      return;
    }

    // 開始日か終了日が未設定の場合は処理終了
    if (!startStr || !endStr) {
      this.setState({ forDays: null });
      return;
    }

    const start = m(startStr);
    const end = m(endStr);

    const forDays = end.diff(start, 'days') + 1;
    this.setState({ forDays: forDays });
  }

  render() {
    const { classes, intl, handleSubmit, userInfo, values } = this.props;
  
    // 県名***
    const selPre1 = intl.formatMessage({ id: 'workRequest.select.Tottori' }); 
    const selPre2 = intl.formatMessage({ id: 'workRequest.select.Shimane' }); 
    const selPre3 = intl.formatMessage({ id: 'workRequest.select.Okayama' }); 
    const selPre4 = intl.formatMessage({ id: 'workRequest.select.Hiroshima' }); 
    const selPre5 = intl.formatMessage({ id: 'workRequest.select.Yamaguchi' }); 
    const selPre6 = intl.formatMessage({ id: 'workRequest.select.Hyougo' }); 
    const selPre7 = intl.formatMessage({ id: 'workRequest.select.Kagawa' }); 
    const selPre8 = intl.formatMessage({ id: 'workRequest.select.Ehime' }); 
    // 工事・作業の分類***
    const selKind1 = intl.formatMessage({ id: 'workRequest.select.architecture' }); 
    const selKind2 = intl.formatMessage({ id: 'workRequest.select.carry' }); 
    const selKind3 = intl.formatMessage({ id: 'workRequest.select.civilEngineering' }); 
    const selKind4 = intl.formatMessage({ id: 'workRequest.select.road' }); 
    const selKind5 = intl.formatMessage({ id: 'workRequest.select.wall' }); 
    const selKind6 = intl.formatMessage({ id: 'workRequest.select.paint' }); 
    const selKind7 = intl.formatMessage({ id: 'workRequest.select.workOther' }); 
    // 有/無***
    const yes = intl.formatMessage({ id: 'workRequest.select.yes' });
    const no = intl.formatMessage({ id: 'workRequest.select.no' });


    return (
      <div className={classes.root}>
      <form noValidate autoComplete="off" className={classes.root}>
      <UserHeader />

        <Typography className={classes.typographyTop}>取付申込の概要(詳細はヘルプを参照ください)</Typography>
        <Typography className={classes.typographyExp}>(注意)写真登録が必要となります。予め現場で取付け希望範囲の写真(複数枚)を撮影してください。</Typography>

        <div className={classes.explanation}>
        <Typography className={classes.typography}>①この画面の必要事項を入力</Typography>
        <Typography className={classes.typography}>②予め撮影した写真を登録</Typography>
        <Typography className={classes.typography}>・取付け希望箇所(電線，機器等)と範囲がわかるもの</Typography>
        <Typography className={classes.typography}>・取付け状態(防護管・ネット等)がイメージできるよう加筆したもの等</Typography>
        <Typography className={classes.typography}>③取付申込みに関し、連絡事項がある場合はご連絡事項に入力</Typography>
        <Typography className={classes.typography}>④入力内容を確認したうえで「登録ボタン」をクリック</Typography>
        </div>
        <Typography className={classes.typographyExp2}>申込内容を確認後、オペレータから電話連絡しますので、しばらくお待ちください。なお、申込みが輻輳した場合は、翌営業日以降の連絡となる場合があります。</Typography>
        <Typography className={classes.typographyExp2}>また、オペレータから電話連絡した際に、防護管取付け範囲を双方で確認するため「ZOOM(クラウドミーティング)」アプリを利用する場合があります。予めアプリをインストールされることをお勧めします。</Typography>

        <br />
{/* 現場所在地 */}        
        <Field 
          id="WorkSitePrefecture__c" 
          name="WorkSitePrefecture__c" 
          component={renderSelect} 
          className={classes.fields}
          variant="outlined"  
          label={<FormattedMessage id="workRequest.select.prefecture" />} 
          style={{width:"200px"}}
          required={true}
          validate={[ 
              validateRule.required, 
          ]} 
          >
          <MenuItem id="selPre1" value={selPre1}><FormattedMessage id="workRequest.select.Tottori" /></MenuItem> 
          <MenuItem id="selPre2" value={selPre2}><FormattedMessage id="workRequest.select.Shimane" /></MenuItem> 
          <MenuItem id="selPre3" value={selPre3}><FormattedMessage id="workRequest.select.Okayama" /></MenuItem> 
          <MenuItem id="selPre4" value={selPre4}><FormattedMessage id="workRequest.select.Hiroshima" /></MenuItem> 
          <MenuItem id="selPre5" value={selPre5}><FormattedMessage id="workRequest.select.Yamaguchi" /></MenuItem> 
          <MenuItem id="selPre6" value={selPre6}><FormattedMessage id="workRequest.select.Hyougo" /></MenuItem> 
          <MenuItem id="selPre7" value={selPre7}><FormattedMessage id="workRequest.select.Kagawa" /></MenuItem> 
          <MenuItem id="selPre8" value={selPre8}><FormattedMessage id="workRequest.select.Ehime" /></MenuItem> 
        </Field> 

        <Field 
          id="WorkSiteAddress__c" 
          name="WorkSiteAddress__c" 
          component={renderTextField} 
          className={classes.fields}
          label=<FormattedMessage id={'workRequest.in.workaddress'}/> 
          margin="normal" 
          variant="outlined" 
          type="text"
          required={true}
          validate={[ 
              validateRule.required,
              validateRule.maxLength(120),
              validateRule.fullWidth
          ]} 
        /> 

        <Field 
          id="WorkSitePoleNo__c" 
          name="WorkSitePoleNo__c" 
          component={renderTextField} 
          className={classes.fields}
          label=<FormattedMessage id={'workRequest.in.poleNumber'}/> 
          margin="normal" 
          variant="outlined" 
          type="text"
          validate={[
              validateRule.maxLength(50)
          ]}
        />

{/* 取付け希望期間 */}
        <div className={classes.termArea}>
          <Typography variant="subtitle1" className={classes.typography4}><FormattedMessage id={'workRequest.title.term'}/> </Typography>
        </div>
        <Typography variant="subtitle2" display="inline" className={classes.typographySec2}>
          <FormattedMessage id={'term.explanation'}/>
        </Typography>
        <Grid container>
          <Field 
            id="StartRequireAttachTerm__c" 
            name="StartRequireAttachTerm__c" 
            component={renderTextField} 
            label=<React.Fragment />
            className={classes.textFieldDate}
            margin="normal" 
            variant = "outlined" 
            type="date"
            required={true}
//            onChange={this.judgeErr}
            InputLabelProps={{ 
              shrink: true, 
            }} 
            validate={[ 
              validateRule.required,
              validateRule.maxLength(10), 
              validateRule.minLength(10),
              validateRule.pastDate,
            ]} 
            onChange={e => { this.onChangeTerm(e) }}
          /> 
          <Typography className={classes.dateMark}>～</Typography>
          <Field 
            id="EndRequireAttachTerm__c" 
            name="EndRequireAttachTerm__c" 
            className={classes.textFieldDate}
            component={renderTextField} 
            label=<React.Fragment />
            margin="normal" 
            variant = "outlined" 
            required={true} 
            type="date"
//            onChange={this.judgeErr}
            InputLabelProps={{ 
              shrink: true, 
            }} 
            validate={[
              validateRule.required,
              validateRule.maxLength(10), 
              validateRule.minLength(10), 
              validateRule.pastDate,
              validateRule.correctDate(values.StartRequireAttachTerm__c)
            ]}
            onChange={e => { this.onChangeTerm(e) }}
          />

          { (this.state.forDays != null && this.state.forDays > 0) &&
            <div className={classes.fordaysArea}>
              <ArrowForward className={classes.fordaysArrow}/>

              <div className={classes.fordaysTextArea}>
                <Typography className={classes.fordaysText}>
                {this.state.forDays}<FormattedMessage id={'term.fordays'}/>
                </Typography>
              </div>
            </div>
          }
        </Grid>
{/*this.state.errFlg &&
// エラーメッセージ
        <div style={{marginBottom:"10px"}}>
          <Typography variant="subtitle2" 
            style={{display:"inline-block", margin:"0 0 -10px 0", fontSize:"0.75em", color:"red"}}>
            <FormattedMessage id={'inputCheckMessage.wrongday'}/><br />
          </Typography>
        </div>
*/}


{/* 工事・作業の分類 */}
        <div style={{paddingBottom:"0px"}}>
          <FormControl component="fieldset" className={classes.formControl2}>
            <FormLabel component="legend" className={classes.formLabel}><FormattedMessage id={'workRequest.title.worktype'}/></FormLabel>
            <div style={{paddingLeft:"15px"}}>
              <Field name="Purpose__c" id="Purpose__c" className={classes.fields} required={true} component={renderRadioGroup} validate={[ validateRule.required3 ]} row>
                <FormControlLabel value={selKind1} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.architecture'}/> />
                <FormControlLabel value={selKind2} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.carry'}/> />
                <FormControlLabel value={selKind3} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.civilEngineering'}/> />
                <FormControlLabel value={selKind4} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.road'}/> />
                <FormControlLabel value={selKind5} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.wall'}/> />
                <FormControlLabel value={selKind6} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.paint'}/> />
                <FormControlLabel value={selKind7} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.workOther'}/> />
              </Field>
            </div>
          </FormControl>
        </div>
        
        {/* その他の場合は入力欄を表示 */}
        {(values && values.Purpose__c === selKind7) && 
          //<div style={{paddingTop:"-60px", paddingLeft:"25px"}}className={classes.formControl3}>
          <div className={classes.formControl3}>
          <Field 
            id="PurposeOther__c" 
            name="PurposeOther__c" 
            component={renderTextField} 
            label=<FormattedMessage id={'workRequest.select.workOther.memo'}/> 
            margin="normal" 
            variant = "outlined" 
            required={true}
            type="text"
            validate={[ 
              validateRule.required,
              validateRule.maxLength(20)
            ]}
          /> 
          </div>
        }
        
{/* 工事・作業の内容 */}
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.formLabel}><FormattedMessage id={'workRequest.title.workContents'}/></FormLabel>
          <div style={{paddingLeft:"15px"}}>
          <table>
            <tbody>
              <tr>
                <td style={{width:'120px', whiteSpace: 'nowrap'}}>
                  <Typography><FormattedMessage id={'workRequest.title.crane'}/></Typography>
                </td>
                <td>
                  <Field name="Crane__c" id="Crane__c" className={classes.fields2} required={true} component={renderRadioGroup} validate={[ validateRule.required3 ]} row>
                    <FormControlLabel value={yes} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.yes'}/> />
                    <FormControlLabel value={no} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.no'}/> />
                  </Field>
                </td>
              </tr>
              <tr>
                <td style={{width:'120px', whiteSpace: 'nowrap'}}>
                  <Typography><FormattedMessage id={'workRequest.title.scaffold'}/></Typography>
                </td>
                <td>
                  <Field name="Scafford__c" id="Scafford__c" className={classes.fields2} required={true} component={renderRadioGroup} validate={[ validateRule.required3 ]} style={{paddingTop:"6px"}} row>
                    <FormControlLabel value={yes} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.yes'}/> />
                    <FormControlLabel value={no} control={<RadioB />} label=<FormattedMessage id={'workRequest.select.no'}/> />
                  </Field>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </FormControl>
        
{/* 防護管取付希望範囲 */}
        <FormControl component="fieldset" className={classes.formControl} >
          <FormLabel component="legend" className={classes.formLabel}><FormattedMessage id={'workRequest.title.range'}/></FormLabel>
          <div style={{paddingLeft:'15px'}}>
          <FormGroup row>
            <Field className={classes.fields3} 
              id="DistributionLine__c" 
              name="DistributionLine__c"
              component={renderCheckbox}
              label=<FormattedMessage id={'workRequest.check.distributionLine'}/>
            /> 
            <Field className={classes.fields3} 
              id="IncomingLine__c" 
              name="IncomingLine__c" 
              component={renderCheckbox} 
              label=<FormattedMessage id={'workRequest.check.serviceLine'}/>
            /> 
            <Field className={classes.fields3} 
              id="Device__c" 
              name="Device__c" 
              component={renderCheckbox} 
              label=<FormattedMessage id={'workRequest.check.machine'}/>
            />
{/* ２次開発で復活予定
            <Field className={classes.fields3} 
              id="CommunicationLine__c" 
              name="CommunicationLine__c" 
              component={renderCheckbox} 
              label=<FormattedMessage id={'workRequest.check.communicationLine'}/>
            /> 
*/}
            <Field className={classes.fields3} 
              id="Other__c" 
              name="Other__c" 
              component={renderCheckbox} 
              label=<FormattedMessage id={'workRequest.check.rangeOther'}/>
            /> 
          </FormGroup>
          
{termErrFlg &&
          <Typography variant="subtitle2" 
            style={{display:"inline-block", paddingBottom: "10px", fontSize:"0.75em", color:"red"}}>
            <FormattedMessage id={'inputCheckMessage.required.range'}/><br />
          </Typography>
}
          </div>
        </FormControl>

          
{/* ご連絡事項 */}
        <Field 
          id="Message__c" 
          name="Message__c"
          component={renderTextField} 
          label=<FormattedMessage id={'workRequest.in.memo'}/> 
          margin="normal" 
          variant="outlined" 
          type="text" 
          multiline={true}
          className={classes.fields} 
          rows={3}
          validate={[ 
            validateRule.maxLength(1000)
          ]} 
        /> 

{/* 写真アップロード */}
        <Typography variant="subtitle1" className={classes.typography3}><FormattedMessage id={'workRequest.title.pictureUpload'}/><font size="2"><FormattedMessage id={'workRequest.title.multiSelect'}/></font></Typography>

        <Typography variant="subtitle2" display="inline" className={classes.typographySec}>
          <FormattedMessage id={'photo.upload.message.picture'}/><br />
          <FormattedMessage id={'photo.upload.message.require1'}/><br />
          <FormattedMessage id={'photo.upload.message.require2'}/>
        </Typography>

        <Typography variant="h5" className={classes.typographySample}>
          <FormattedMessage id={'photo.upload.sample'}/>
          <a target="_blank" rel="noopener noreferrer" href={Conf.SAMPLE_PHOTO_URL}>
            <FormattedMessage id={'photo.upload.sample.here'}/>
          </a>
        </Typography>

        <Fragment>
          <div style={{paddingLeft:"20px"}}>
          <Upload
            maxFileCount={10}
            maxFileSize={3145728}
            previewFlag={true}
            previewWidth="35%"
            acceptFileType=".jpg,.jpeg,.png,.gif,.pdf"
            fileSetHandler={this.setFiles}
            />
            </div>
        </Fragment>
        
        
{/* オペレータ連絡文言 */}
        
        <Typography variant="subtitle2" 
          style={{display:"inline-block", borderTop: "dashed 1px skyblue",borderBottom: "dashed 1px skyblue",margin:"20px 5px -10px 5px", textAlign: "center", fontSize:"0.8em"}}>
          <FormattedMessage id={'workRequest.message.contact'}/><br />
          { userInfo.data && userInfo.data.Cellphone__c ? userInfo.data.Cellphone__c : userInfo.data.Tel__c } 
        </Typography>
        
        
{/* ボタン */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
          onClick={handleSubmit(this.doWorkRequest)}
        >
          <span><FormattedMessage id={'common.btn.send'}/></span>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          size="large"
          onClick={this.onClickBack}
        >
          <span><FormattedMessage id={'common.btn.back'}/></span>
        </Button>
        
      </form>
      </div>
    );
  }
}



const FORM_NAME = "WorkRequest"; 
 
WorkRequest.propTypes = { 
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types 
}; 
 
const mapStateToProps = state => ({ 
  initialValues: {
        WorkSitePrefecture__c:null,
        WorkSiteAddress__c:null,
        WorkSitePoleNo__c:null,
        StartRequireAttachTerm__c:null,
        EndRequireAttachTerm__c:null,
        Purpose__c:null,
        PurposeOther__c:null,
        Crane__c:null,
        Scafford__c:null,
        DistributionLine__c:false,
        IncomingLine__c:false,
        Device__c:false,
        CommunicationLine__c:false,
        Other__c:false,
        Message1__c:null,
  },
}); 

WorkRequest = reduxForm({ 
  form: FORM_NAME, 
  destroyOnUnmount: false,
  validate, 
  enableReinitialize: true, 
})(connect(state => { 
  return { 
    values: getFormValues(FORM_NAME)(state), 
  }; 
})(WorkRequest)); 
 
export default withStyles(styles)( 
  connect(
    mapStateToProps
  )(injectIntl(WorkRequest)) 
); 
