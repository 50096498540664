import React from 'react';
import { connect } from 'react-redux';
import UserUpdate from '../../components/templates/UserUpdate';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doOpenDialog: commonOperations.doOpenDialog,
  doUserUpdateAction: authOperations.doUserUpdateOperation,
  getAddressAction: commonOperations.doGetPost,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<UserUpdate
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
