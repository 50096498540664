import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as validateRule from '../../validateRule';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm, getFormValues } from 'redux-form';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import * as common from '../../common';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 16px 16px 16px',
    marginTop: '50px'
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  button2: {
    marginTop: '20px',
    width: '80%',
    textAlign: 'center',
    maxWidth: '400px',
  },
    button3: {
    width: '80%',
    textAlign: 'center',
    maxWidth: '400px',
  },
  serchbutton: {
    marginTop: '20px',
    marginLeft: '10px',
  },
  textFieldLeft: {
    margin: '10px 5px 5px 0',
  },
  textFieldRight: {
    margin: '10px 0 5px 5px',
  },
  textFieldL: {
    margin: '10px 0 5px 0',
  },
  textFieldM: {
    margin: '10px 0 5px 0',
    width: '60%'
  },
  textFieldS: {
    margin: '10px 0 5px 0',
    width: '40%'
  },
  formLabel: {
    margin: '0 0 -10px 0',
  },
  formControl: {
    margin: '10px 0 -5px 0',
  },
  typography: {
    fontSize: "0.8em",
    color: "red"
  },
  postalRow: {
    alignItems: 'center',
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '20px 20px -10px 20px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  textFieldGrid: {
    width: '100%'
  },
  hiddenField: {
    top: "-300px",
    position: "fixed",
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    inputProps={{
      min: 1
    }}
    {...input}
    {...custom}
  />
);

const renderCheckbox = ({ input, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          color="primary"
        />
      }
      label = { label }
    />
  );
};

class UserUpdate extends React.Component {

state = {
    companynameKanji:"",
    companyname:"",
    usernameFamily:"",
    usernameFirst:"",
    usernameFamilyKana:"",
    usernameFirstKana:"",
    zipCode:"",
    companyaddress:"",
    mobile:"",
    telephone:"",
    errFlg:false
  };


  getAddress = async() => {
    console.log("#住所検索呼出#");
    const { getAddressAction, values } = this.props;
    const zipCode = values.zipCode;
    if(!zipCode){
      return;
    }
console.log("zipcode:", zipCode);
    try {
      const result = await getAddressAction( zipCode );
      if(result.data.body.data.status === 200){
        //正常
        if(result.data.body.data.results){
          var info = result.data.body.data.results[0];
          console.log(info.address1,info.address2,info.address3);
          this.props.change('companyaddress', info.address1 + info.address2 + info.address3);
        }else{
          //データ無し
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'userUpdate.dialog.error.nondata',
          };
          doOpenDialog(dialog);
        }
      }else if (result.data.body.data.status === 400) {
        // 入力値不正エラーだった場合
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'message.error.90000',
        };
        doOpenDialog(dialog);
      }else{
        //異常
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // システムエラーだった場合
      const { doOpenDialog } = this.props;
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }

  };

  // メニュー画面に遷移
  onClickBack = () => {
    this.props.history.push('/workMenu');
  };

  // ログイン画面に遷移
  doMoveLogin = () => {
    this.props.history.push("/login");
  }

  // 変更処理
  doUserUpdate = () => {

    const { values } = this.props;
    if (values.mobile.length === 0 && values.telephone.length === 0) {
      this.setState({errFlg: true});
      return;
    }else{
      this.setState({errFlg: false});
    }

    const { doOpenDialog } = this.props;
    const dialog = {
      type: 'okcancel', // 「確認」と「キャンセル」を表示
      title: '',
      message: 'userUpdate.dialog.inquiry',
      action: this.doAction
    };
    doOpenDialog(dialog);

  }

  doAction = async() => {

    console.log("#ユーザー情報変更#");
    const { doUserUpdateAction, doOpenDialog } = this.props;
    try {
      const result = await doUserUpdateAction();
      console.log(result);
      if (result === '00000') {
        // 正常
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'userUpdate.dialog.finish',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else if (result === '21300') {
        // ユーザー情報更新失敗
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userUpdate.dialog.error.failure',
        };
        doOpenDialog(dialog);
      }
      else if ( result === "80005") {
        // パスワード変更失敗
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userUpdate.dialog.error.failure.password',
        };
        doOpenDialog(dialog);
      }else {
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.title',
        };
        doOpenDialog(dialog);
      }

    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }

  };


  changeTel = (e) => {
    if (e.target.value.length > 0) {
      this.setState({errFlg: false});
    }
  }
  judgeErr = (e) => {
    this.cnvFull2Harf(e);
    const { values } = this.props;
    if (values) {
      if (!values.mobile && !values.telephone) {
        this.setState({errFlg: true});
      }else{
        this.setState({errFlg: false});
      }
    }else{
        this.setState({errFlg: true});
    }
  }

  cnvFull2Harf = (e) => {
    const { name, value } = e.target;
    let harf = common.full2Harf(value);
    this.props.change(name, harf);
    e.preventDefault();
  }

  cnvHarf2Full = (e) => {
    const { name, value } = e.target;
    let full = common.harf2Full(value);
    this.props.change(name, full);
    e.preventDefault();
  }

  render() {
    const { classes, values, handleSubmit } = this.props;

    return (
      <div>
          <form noValidate autoComplete="off" className={classes.root}>
            <Field
              id="companynameKanji"
              name="companynameKanji"
              label=<FormattedMessage id={'userUpdate.companynameKanji'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
//              inputProps={{ inputMode: 'text' }}
              type="text"
              validate={[
                validateRule.required,
                validateRule.maxLength(38),
                validateRule.fullWidth,
              ]}
              onBlur={this.cnvHarf2Full}
            />
            <Field
              id="companyname"
              name="companyname"
              label=<FormattedMessage id={'userUpdate.companyname'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
//              inputProps={{ inputMode: 'text' }}
              type="text"
              validate={[
                validateRule.required,
                validateRule.maxLength(38),
                validateRule.fullKanaWidth,
              ]}
              onBlur={this.cnvHarf2Full}
            />
            <Grid container spacing={24}>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFamily"
                  name="usernameFamily"
                  label=<FormattedMessage id={'userUpdate.usernameFamily'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: 'text' }}
                  type="text"
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFirst"
                  name="usernameFirst"
                  label=<FormattedMessage id={'userUpdate.usernameFirst'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: 'text' }}
                  type="text"
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFamilyKana"
                  name="usernameFamilyKana"
                  label=<FormattedMessage id={'userUpdate.usernameFamilyKana'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: 'text' }}
                  type="text"
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullKanaWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  id="usernameFirstKana"
                  name="usernameFirstKana"
                  label=<FormattedMessage id={'userUpdate.usernameFirstKana'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: 'text' }}
                  type="text"
                  validate={[
                    validateRule.required,
                    validateRule.maxLength(18),
                    validateRule.fullKanaWidthWithoutSpace,
                  ]}
                  onBlur={this.cnvHarf2Full}
                />
              </Grid>
            </Grid>
            {/* 住所 */}
            <Grid container spacing={24} className={classes.postalRow}>
              <Grid item xs={5}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  label=<FormattedMessage id={'userUpdate.zipCode'} />
                  className={classes.textFieldGrid}
                  margin="normal"
                  variant="outlined"
                  required={true}
                  component={renderTextField}
//                  inputProps={{ inputMode: 'numeric' }}
//                  type="number"
                  validate={[
                    validateRule.required,
                    validateRule.minLength(7),
                    validateRule.maxLength(7),
                    validateRule.number,
                  ]}
                  onChange={e => this.setState({ zipCode: e.target.value })}
//                  onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 107 || e.keyCode === 109 ) && e.preventDefault() }
                  onBlur={this.cnvFull2Harf}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  id="search"
                  name="search"
                  variant="contained"
                  color="primary"
                  className={classes.serchbutton}
                  size="large"
                  onClick={this.getAddress}
                >
                <span><FormattedMessage id={'userUpdate.btn.search'} /></span>
                </Button>
              </Grid>
            </Grid>
            <Field
              id="companyaddress"
              name="companyaddress"
              label=<FormattedMessage id={'userUpdate.companyaddress'} />
              className={classes.textFieldL}
              margin="normal"
              variant="outlined"
              required={true}
              component={renderTextField}
              validate={[
                validateRule.required,
                validateRule.maxLength(50),
                validateRule.fullWidth,
              ]}
//              inputProps={{ inputMode: 'text' }}
              type="text"
              onBlur={this.cnvHarf2Full}
            />

{this.state.errFlg &&
// エラーメッセージ
        <div style={{margin:"5px 0 -10px 12px"}}>
          <Typography variant="subtitle2"
            style={{display:"inline-block", fontSize:"0.75em", color:"red"}}>
            <FormattedMessage id={'inputCheckMessage.required2'}/><br />
          </Typography>
        </div>
}
            <Field
              id="mobile"
              name="mobile"
              label=<FormattedMessage id={'userUpdate.mobile'} />
              className={classes.textFieldM}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              onChange={this.changeTel}
              onBlur={this.judgeErr}
              validate={[
                    validateRule.telephone,
                    validateRule.maxLength(13)
              ]}
 //             inputProps={{ inputMode: 'tel' }}
//              type="tel"
            />

            <Field
              id="telephone"
              name="telephone"
              label=<FormattedMessage id={'userUpdate.telephone'} />
              className={classes.textFieldM}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              onChange={this.changeTel}
              onBlur={this.judgeErr}
              validate={[
                    validateRule.telephone,
                    validateRule.maxLength(13)
              ]}
//              inputProps={{ inputMode: 'tel' }}
//              type="tel"
            />
            <Typography className={classes.typographyCap}><FormattedMessage id={'userUpdate.telephoneinfo'} /></Typography>

            <br />
            <Divider variant="fullWidth" />
            <Field className={classes.root}
              id="changePassword"
              name="changePassword"
              component={renderCheckbox}
              color="primary"
              label=<FormattedMessage id={'userUpdate.chk.updatePassword'}/>
            />
            {/* autocomplete対策で、ダミーのID/PW項目を設置 */}
            <Field
              id="dummyIn"
              name="dummyIn"
              margin="normal"
              variant="outlined"
              className={classes.hiddenField}
              component={renderTextField}
              autoComplete="username"
            />
            <Field
              id="dummyPassword"
              name="dummyPassword"
              type="password"
              margin="normal"
              variant="outlined"
              className={classes.hiddenField}
              component={renderTextField}
              autoComplete="current-password"
            />

{ (values && values.changePassword) &&
            <FormControl component="fieldset" className={classes.formControl}>
              <Field
                id="oldPassword"
                name="oldPassword"
                label=<FormattedMessage id={'userUpdate.oldPassword'} />
                className={classes.textField}
                type="password"
                margin="normal"
                variant="outlined"
                required={true}
                component={renderTextField}
                autoComplete="current-password"
                validate={
                    [
                      validateRule.required,
                      validateRule.minLength(8),
                      validateRule.maxLength(32),
                      validateRule.password
                    ]
                }
              />
              <Field
                id="password"
                label=<FormattedMessage id={'userUpdate.password'} />
                className={classes.textField}
                name="password"
                type="password"
                margin="normal"
                variant="outlined"
                required={true}
                component={renderTextField}
                autoComplete="new-password"
                validate={
                    [
                      validateRule.required,
                      validateRule.minLength(8),
                      validateRule.maxLength(32),
                      validateRule.password
                    ]
                }
              />
              <Typography className={classes.typographyCap}><FormattedMessage id={'userUpdate.password.info'} /></Typography>
              <Field
                id="password2"
                label=<FormattedMessage id={'userUpdate.password2'} />
                className={classes.textField}
                name="password2"
                type="password"
                margin="normal"
                variant="outlined"
                required={true}
                component={renderTextField}
                autoComplete="new-password"
                validate={
                    [
                      validateRule.required,
                      validateRule.minLength(8),
                      validateRule.maxLength(32),
                      validateRule.passwordConfirm(values ? values.password : '')
                    ]
                }
              />
            </FormControl>
}
           <div className={classes.root2}>
           <Grid item xs={12}>
            <Button
             id="btnOk"
              onClick={handleSubmit(this.doUserUpdate)}
              variant="contained"
              color="primary"
              className={classes.button3}
              size="large"
//              type="submit"
            >
              <span><FormattedMessage id={'userUpdate.btn.update'} /></span>
            </Button>
            </Grid>
            <Grid item xs={12}>
            <Button
             id="btnCancel"
              onClick={this.onClickBack}
              variant="outlined"
              color="primary"
              className={classes.button2}
              size="large"
            >
              <span><FormattedMessage id={'common.btn.back'} /></span>
            </Button>
            </Grid>
            </div>
      </form>
        </div>
    );
  }
}

const FORM_NAME = "UserUpdate";

const mapStateToProps = state => ({

  initialValues: state.auth.userInfo? {
    companynameKanji:state.auth.userInfo.data.OfficeName__c,
    companyname:state.auth.userInfo.data.OfficeNameKana__c,
    usernameFamily:state.auth.userInfo.data.ApplicantFamilyName__c,
    usernameFirst:state.auth.userInfo.data.ApplicantFirstName__c,
    usernameFamilyKana:state.auth.userInfo.data.ApplicantFamilyNameKana__c,
    usernameFirstKana:state.auth.userInfo.data.ApplicantFirstNameKana__c,
    zipCode:state.auth.userInfo.data.OfficePostalCode__c,
    companyaddress:state.auth.userInfo.data.OfficeAddress__c,
    mobile:state.auth.userInfo.data.Cellphone__c,
    telephone:state.auth.userInfo.data.Tel__c
  }
  :
  {
    companynameKanji:"",
    companyname:"",
    usernameFamily:"",
    usernameFirst:"",
    usernameFamilyKana:"",
    usernameFirstKana:"",
    zipCode:"",
    companyaddress:"",
    mobile:"",
    telephone:""
  }

});

UserUpdate = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect(state => {
  return {
//    zipCode: selector(state, 'zipCode'),
    values: getFormValues(FORM_NAME)(state),
  };
})(UserUpdate));

UserUpdate.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  change: PropTypes.func,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(UserUpdate)
);
