import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import auth from './auth'; // import all reducers from ducks/index.js
import common from './common';
import style from './style';
import work from './work';
import { reducer as formReducer } from 'redux-form';
import Conf from '../config/config.js';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers({
  auth,
  common,
  style,
  work,
  router: connectRouter(history),
  form: formReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "DO_LOGOUT") {
    //state = undefined;
    var ret = {};
    //router情報については初期化せず継承する
    ret.router = state.router;
    return appReducer(ret, action);
  }
  return appReducer(state, action);
};

// 永続化の設定
const persistConfig = {
  key: 'root', // Storageに保存されるキー名を指定する
  storage, // 保存先としてlocalStorageがここで設定される
  blacklist: ['router'] // `visibilityFilter`は保存しない
};

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  diff: true,
  collapsed: true,
});

const middlewares = [];
middlewares.push(thunk);
middlewares.push(routerMiddleware(history));

if(Conf.logger)
  middlewares.push(logger);

/*
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, logger, routerMiddleware(history))),
  );
}
*/
/*
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}
*/
const store = createStore(
  persistedReducer,
  window.REDUX_INITIAL_DATA,
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
export default function configureStore(initialState = {}) {
  return store;
}
