import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/icons/ArrowUpward';


const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  icon: {
    position: 'fixed',
    zIndex: '2000',
    bottom: '15px',
    right: '10px',
    padding: '0px 0px',
    MozOpacity: '0.7',
    filter: 'alpha(opacity=70)',
    opacity: '0.7',
  }
});

class Scroll extends React.Component {
    state = {
        visible: false
    }
    
    constructor(props) {
        super(props);
        this.state = {
            currentPosition: 0
        };
    }
    
    componentDidMount() {
        window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll');
    }
    
    watchCurrentPosition() {
        let blnFlg = false;
        if(this.scroll() > 100){
            blnFlg = true;
        }
        this.setState({ visible: blnFlg });
    }
    
    scroll() {
        return Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop);
    }

  scrollTop() {
     window.scrollTo(0, 0);
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.visible &&
          <div className={classes.icon}>
          <Fab size="medium" color="secondary" aria-label="Top" className={classes.fab} onClick={()=>{this.scrollTop()}}>
            <Icon />
          </Fab>
        </div>
        }
      </div>
    );
  }
}

Scroll.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Scroll);
