export default {
  'logger': false,
  'consolelog': true,
  'API_URL': 'https://umu9wlxftd.execute-api.ap-northeast-1.amazonaws.com/dev2/',
  'amplify_conf':{
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_XL3IS5MWi',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '3nst2sogn0tsfcaiio0cc2jpab',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    }
  },
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_XL3IS5MWi',
  'IdentityPoolId': 'ap-northeast-1:b90f61ab-0810-4d53-9e2d-501c8dd15028',
  'tell': '(電話) 0120-007172',
  'company': '電力サポート中国 防護管受付センター',
  'MANUAL_PDF_URL': 'https://d1jwcynwc788y2.cloudfront.net/manual/%E9%98%B2%E8%AD%B7%E7%AE%A1%E8%A8%AD%E7%BD%AE%E7%94%B3%E8%BE%BC%E6%93%8D%E4%BD%9C%E8%AA%AC%E6%98%8E%E6%9B%B8.pdf',
  "MANUAL_SP_INSTALL_URL": "https://d1jwcynwc788y2.cloudfront.net/manual/zoom/Zoom%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E6%96%B9%E6%B3%95_%E3%82%B9%E3%83%9E%E3%83%9B%E7%89%88.pdf",
  "MANUAL_SP_MEETING_URL": "https://d1jwcynwc788y2.cloudfront.net/manual/zoom/Zoom%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E5%8F%82%E5%8A%A0%E6%96%B9%E6%B3%95_%E3%82%B9%E3%83%9E%E3%83%9B%E7%89%88.pdf",
  "MANUAL_PC_INSTALL_URL": "https://d1jwcynwc788y2.cloudfront.net/manual/zoom/Zoom%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E6%96%B9%E6%B3%95_PC%E7%89%88.pdf",
  "MANUAL_PC_MEETING_URL": "https://d1jwcynwc788y2.cloudfront.net/manual/zoom/Zoom%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E5%8F%82%E5%8A%A0%E6%96%B9%E6%B3%95_PC%E7%89%88.pdf",
  "MANUAL_SAFETY_PR_URL":"https://d1jwcynwc788y2.cloudfront.net/manual/%E7%81%BD%E5%AE%B3%E9%98%B2%E6%AD%A2%E3%81%AB%E5%90%91%E3%81%91%E3%81%9F%E9%9B%BB%E6%B0%97%E5%AE%89%E5%85%A8%EF%BC%B0%EF%BC%B2.pdf",
  "MANUAL_PHOTOGRAPHY_URL":"https://d1jwcynwc788y2.cloudfront.net/manual/%E5%8F%96%E4%BB%98%E5%B8%8C%E6%9C%9B%E7%AF%84%E5%9B%B2%E3%81%AE%E5%86%99%E7%9C%9F%E6%92%AE%E5%BD%B1%E6%96%B9%E6%B3%95%E4%BE%8B.pdf",
  "CLAUSE_PDF_URL":"https://d1jwcynwc788y2.cloudfront.net/clause/%E9%98%B2%E8%AD%B7%E7%AE%A1%E5%8F%97%E4%BB%98_%E7%B4%84%E6%AC%BE.pdf",
  "SAMPLE_PHOTO_URL": "https://www.d-sapo-c.co.jp/library/pdf/bougokan_satsueirei.pdf",

};
