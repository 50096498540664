import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PaperIcon from '@material-ui/icons/ListAlt';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 16px 16px 16px',
  },
  inputfile: {
    display: 'none'
  },
  imagefile: {
    cursor: 'pointer'
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: '#a5a5a5',
  },
  description: {
    width: '98%',
    margin: '0% 0% 10px 2%'
  }
});

class Upload extends React.Component {
  state = {
    files: [],
    dialog: false,
    image: null,
    errMessageKey: null,
  }

  //ファイル選択時
  onFileChange = (e) => {
    var files = e.target.files;
    this.setState({isWarning: false, errMessage: null});
    
    //ファイルサイズチェック、ファイル拡張子チェック
    var blnTypeErr = true;
    var chk = this.props.acceptFileType;
    if(files.length > 0) {
      var file = files[0];
      
      //ファイル拡張子チェック
      var pos = file.name.lastIndexOf(".");
      var filetype = "";
      if(pos !== -1) filetype = file.name.slice(pos);
      chk.split(",").forEach((type) => {
//        if(file.name.match(type)) blnTypeErr = false;
        if(filetype.toLowerCase().match(type.toLowerCase()) || filetype === "") blnTypeErr = false;
      });
      if(blnTypeErr){
        this.setErr("upload.error.typepermission");
//        this.setErr(file.name);
      //ファイルサイズチェック
      }else if(file.size > this.props.maxFileSize){
        this.setErr("upload.error.sizeover");

      //ファイル追加
      }else{
        this.pushFile(file);
      }
    }
  }
  
  //エラー情報セット
  setErr = (msgid) => {
    this.setState({isWarning: true, errMessageKey: msgid});
    this.setState({dialog: true});
    //input:fileの情報をクリア
    this.clearFile();
  }

  //親コンポーネントへの情報返却
  returnFile = (e) => {
    this.props.fileSetHandler(e);
  }
  
  //選択ファイル読み込み
  pushFile = (file) => {
    var reader = new FileReader();
    var fileData = file;
    reader.fileName = file.name;
    reader.onload = (e) => {
      var contenttype = (e.target.result).split(";")[0].split(":")[1];
//      var file = {"ImageBody": e.target.result, "ImageName": e.target.fileName, "ContentType": contenttype, UploadDate: m().format('YYYYMMDDHHmmss') };
      var file = { "ImageBody": e.target.result, "ImageName": e.target.fileName, "ContentType": contenttype, fileData };
      var create_files = this.state.files.concat(file);
      this.setState({files: create_files});
      this.returnFile(create_files);
    };
    reader.readAsDataURL(file);
    //input:fileの情報をクリア
    this.clearFile();
  }

  //選択ファイルの選択解除  
  delFile = (e) => {
    var files = this.state.files;
    var create_files = [];
    files.forEach((file) => {
      if(file !== e) create_files.push(file);
    });
    this.setState({files: create_files});
    this.returnFile(create_files);
  }

  //ダミーの追加ボタン押下時にinput:fileボタンを押下  
  addFile = (e) => {
    document.getElementById("btn_addfile").click();
  }

  //input:fileの情報をクリア
  clearFile = (e) => {
    document.getElementById("btn_addfile").value = "";
  }

  //イメージデータのプレビュー
  viewFile = (e) => {
    this.setState({image: e});
    this.setState({dialog: true});
  }
  
  //ダイアログのクローズ
  handleClose = (e) => {
    this.setState({dialog: false});
    this.setState({image: null, errMessageKey: null});
  }
  
  render() {
    const { classes } = this.props;
    const files = this.state.files;

    let addDisabled = true;
    if(files.length < this.props.maxFileCount) addDisabled = false;

    let preview = '';
    
    preview = files.map((file, index) => {
      return (
        <React.Fragment key={index}>
          <table width="100%" border="0" cellPadding="0" cellSpacing="0">
          <tbody>
          {this.props.previewFlag &&
            <tr>
              <td width={this.props.previewWidth}><br/>
              {(file.ContentType).match("image") &&
                <img width="100%" src={file.ImageBody} alt={file.ImageName} className={classes.imagefile} onClick={()=>{this.viewFile(file)}}/>
              }
              {!(file.ContentType).match("image") &&
                <PaperIcon fontSize="large" />
              }
              </td>
              <td width="65%">&nbsp;</td>
            </tr>
          }
            <tr height="10">
              <td colSpan="2">
                <IconButton color="primary" aria-label="delete" size="medium" onClick={()=>{this.delFile(file)}}>
                  <DeleteIcon />
                </IconButton>{file.ImageName}
              </td>
            </tr>
          </tbody>
          </table>
        </React.Fragment>
      );
    });

    return (
      <div>
        {preview}
        <div><br />
          <input type="file" className={classes.inputfile} id="btn_addfile" accept={this.props.acceptFileType} onChange={this.onFileChange} />
          <Fab id="add" color="primary" aria-label="add" size="medium" onClick={this.addFile} disabled={addDisabled}>
            <AddIcon />
          </Fab>
        </div>
        <Dialog
          open={this.state.dialog}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="xl"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          {(this.state.image) &&
            <React.Fragment>
              <DialogTitle id="scroll-dialog-title">
                {this.state.image.ImageName}
                <IconButton className={classes.closeButton} onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <img width="100%" src={this.state.image.ImageBody} alt={this.state.image.ImageName} />
                </DialogContentText>
              </DialogContent>
            </React.Fragment>
          }
          {(this.state.errMessageKey) &&
            <React.Fragment>
              <DialogTitle id="scroll-dialog-title">
                <FormattedMessage id={"upload.error.title"} />
                <IconButton className={classes.closeButton} onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <FormattedMessage id={this.state.errMessageKey} />
              </DialogContent>
            </React.Fragment>
          }
        </Dialog>
      </div>
    );
  }
  
}

Upload.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  maxFileCount: PropTypes.number,       //1度にアップロード可能なファイル数
  maxFileSize: PropTypes.number,        //1ファイルあたりの許容サイズ
  previewFlag: PropTypes.bool,          //イメージファイルのプレビュー機能有無
  previewWidth: PropTypes.string,       //プレビューイメージの表示幅
  acceptFileType: PropTypes.string,     //許可する拡張子を「.（ドット）」込みでカンマ区切りで指定　ex）.jpg,.pdf
  fileSetHandler: PropTypes.func        //選択ファイルを格納するための関数
};

Upload.defaultProps = {
  maxFileCount: 3,
  maxFileSize: 1000000,
  previewFlag: false,
  acceptFileType: '.txt'
};

export default withStyles(styles)(Upload);
