import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
//import Header from '../../organisms/Header';
import { Field, reduxForm } from 'redux-form';
import * as validateRule from '../../validateRule';
import Conf from '../../../config/config.js';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Grid from '@material-ui/core/Grid'; 


const styles = theme => ({
  root: {
    textAlign: 'center',
    flexDirection: 'column',
    marginTop: '100px',
    padding: '16px',
  },
  button: {
    marginTop: '20px',
    width: '60%',
    maxWidth: '300px',
  },
  card: {
    margin: '0px 16px 15px 16px',
    paddingTop: '10px',
    textAlign: 'center',
    backgroundColor: '#e8f5e9',
    height: "80px",
  },
  title: {
      color: "#d50000",
      fontSize: "1.3em"
    },
  textField: {
    width: '80%',
    maxWidth: '800px',
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

class Redirect extends React.Component {
  state = {
    username: Conf.DefaultId,
    password: Conf.DefaultPassword,
  };
  doDialogSample = () => {
    const { doOpenDialog } = this.props;

    //Login関数呼び出し設定
    this.doLogin = this.doLogin.bind(this);

    const dialog = {
      type: 'okcancel', //'ok' or 'close' or 'okcancel'
      title: 'infoList.title',
      message: 'message.sample.context',
      action: this.doLogin, //'ok'button Click event
    };

    doOpenDialog(dialog);
  }

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  doLogin = async() => {
    console.log("#######");
    const { loginAction, getUserInfo } = this.props;
    if (true) { //バリデーションがOK
      try {
        const result = await loginAction("redirect");
        console.log("結果：" + result);
        if (result === 'OK') {
          // ユーザー情報を取得し、申込検討画面へ遷移
          const ret = await getUserInfo();
          if (ret === "OK") {
            this.props.history.push('/workConsideration');
          } else {
            const { doOpenDialog } = this.props;
            const dialog = {
              type: 'close', // '閉じる'のみ表示
              title: '',
              message: 'login.dialog.error.nouserid',
            };
            doOpenDialog(dialog);
          }
        }
        else if (result === 'NO_USER_ID') {
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'login.dialog.error.nouserid',
          };
          doOpenDialog(dialog);
        }
        else {
          const { doOpenDialog } = this.props;
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'login.dialog.error.input',
          };
          doOpenDialog(dialog);
        }
      }
      catch (err) {
        console.log(`エラー: ${err}`);
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'login.dialog.error.system',
        };
        doOpenDialog(dialog);
      }
    }
  };
  
  doUserRegist = () => {
    console.log("#userPolicy画面に遷移#");
    this.props.history.push('/userPolicy');
  };

  doPasswordReset = () => {
    console.log("#passwordReset画面に遷移#");
    this.props.history.push('/passwordReset');
  };

  render() {
    const { classes, handleSubmit } = this.props;
    const { username, password } = this.state;

    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root}>
          <Card className={classes.card}>
            <CardHeader classes={{title: classes.title}} title=<FormattedMessage id={'login.card.title'}/> />
          </Card>
          <Grid container>
            <Grid item xs={12}>
              <Field
                id="filled-mail"
                name="username"
                label=<FormattedMessage id={'login.in.mailaddress'}/>
                className={classes.textField}
                value={username}
                required={true}
                onChange={e => this.setState({ username: e.target.value })}
                margin="normal"
                variant="outlined"
                component={renderTextField}
                validate={[
                  validateRule.required,
                  validateRule.minLength(6),
                  validateRule.maxLength(80),
                  validateRule.email,
                  validateRule.emailDomain
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="filled-password"
                name="password"
                label=<FormattedMessage id={'login.in.password'}/>
                type="password"
                className={classes.textField}
                value={password}
                required={true}
                onChange={e => this.setState({ password: e.target.value })}
                margin="normal"
                variant="outlined"
                component={renderTextField}
                validate={[
                  validateRule.required,
                  validateRule.minLength(8),
                  validateRule.maxLength(32),
                  validateRule.password,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleSubmit(this.doLogin)}
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
              >
                <span><FormattedMessage id={'login.btn.login'} /></span>
              </Button>
            </Grid>
{/*
            <Grid item xs={12}>
              <Button
                onClick={this.doPasswordReset}
                className={classes.button}>
                <u><FormattedMessage id={'login.btn.reissue'} /></u>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.doUserRegist}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                <span><FormattedMessage id={'login.btn.regist'} /></span>
              </Button>
            </Grid>
*/}
          </Grid>
        </form>
      </div>
    );
  }
}

Redirect.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
Redirect = reduxForm({
  form: 'Redirect',
  destroyOnUnmount: false
})(Redirect);
export default withStyles(styles)(Redirect);
