import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { commonOperations } from './../../../reducks/common';
import { authOperations } from './../../../reducks/auth';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  dialogcontext: {
    color: 'black',
  }
});

class CustomDialog extends React.Component {
  state = {
    runFlg: false,
    action: null
  }

  handleClose = event => {
    this.setState({runFlg: false});
    this.props.doCloseDialog();
  }

  handleRun = event => {
    this.setState({runFlg: true, action:  this.props.dialogInfo.action});
    this.props.doCloseDialog();
  }

  handleError = event => {
    this.setState({runFlg: true, action:  this.doLogin});
    this.props.doCloseDialog();
  }

  handleExited = event => {
    //ダイアログを表示した状態で、ブラウザバックなどの対処
    if(this.props.pathname !== this.props.dialogInfo.address){
      this.props.doCloseDialog();
    }
    //okボタン押下後、ダイアログ非表示をトリガーに指定されたアクションを実行
    if(this.state.runFlg && (this.props.pathname === this.props.dialogInfo.address)){
      if(this.state.action){
        this.state.action();
      }
    }
  }

  doLogin = () => {
    this.props.history.push('/login');
  }
  
  render() {
    const { dialogInfo, classes, pathname } = this.props;

    return (
      <div>
        <Dialog
          open={dialogInfo.open && (pathname === dialogInfo.address)}
          onExited={this.handleExited}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
        >
          {dialogInfo.title &&
            <DialogTitle id="responsive-dialog-title"><FormattedMessage id={dialogInfo.title}/></DialogTitle>
          }
          <DialogContent>
            <DialogContentText className={classes.dialogcontext}>
              <FormattedMessage id={dialogInfo.context}/>
            </DialogContentText>
          </DialogContent>
          {(dialogInfo.type === 'ok') &&
          <DialogActions>
            <Button onClick={this.handleRun} color="primary">
              <FormattedMessage id={'message.button.close'}/>
            </Button>
          </DialogActions>
          }
          {(dialogInfo.type === 'okcancel') &&
          <DialogActions>
            <Button onClick={this.handleRun} color="primary">
              <FormattedMessage id={'message.button.ok'}/>
            </Button>
            <Button onClick={this.handleClose} color="primary">
              <FormattedMessage id={'message.button.cancel'}/>
            </Button>
          </DialogActions>
          }
          {(dialogInfo.type === 'close') &&
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <FormattedMessage id={'message.button.close'}/>
            </Button>
          </DialogActions>
          }
          {(dialogInfo.type === 'error') &&
          <DialogActions>
            <Button onClick={this.handleError} color="primary">
              <FormattedMessage id={'message.button.close'}/>
            </Button>
          </DialogActions>
          }
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dialogInfo: state.common.dialogInfo,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  doCloseDialog: commonOperations.closeDialog,
  doLogout: authOperations.doLogoutOperation,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(CustomDialog))
);
