import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import UserHeader from '../../organisms/UserHeader';
import { connect } from 'react-redux';
import { workOperations } from './../../../reducks/work';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 15px 4px',
//    margin: '0 20px 0 20px',
  },
    root2: {
    flexDirection: 'column',
    textAlign: "center"
    },
  grow: {
    flexGrow: 1,
  },
  card: {
    margin: '5px',
    textAlign: "center"
  },
  cardTop: {
    margin: '0 16px 16px 16px',
  },
  typography:{
    margin: '10px 0 0 20px',
    textAlign: "left"
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '20px 20px -10px 20px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    variant: "subtitle2",
  },
  paper: {
    marginTop: '0px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  center: {
    textAlign: 'center',
  },
  button: {
    margin: '20px 0 20px 0',
    padding:"8px",
    width: '60%',
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#82b3c9',
      contrastText: '#000000',
    },
  },
  button2: {
    margin: '20px 80px 20px 80px',
    padding:"8px",
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#82b3c9',
      contrastText: '#000000',
    },
  },
  table: {
//    width: "90%",
  },
  typographySec1: {
    color: "#d50000",
    border: "solid 2px #d50000",
    margin: '5px 10px 0 10px',
    padding: '0 0 0 5px',
    borderRadius: "0.2em",
    fontSize: "1.0em",
  },
  typographySec2: {
    margin: '5px 10px 0 16px',
    borderBottom: "solid 2px black",
    fontSize: "1.0em",
  },
  typographyText1: {
    margin: '5px 10px 20px 16px',
    fontSize: '0.8em'
  },
  typographyText2: {
    margin: '5px 10px 0 16px',
    fontSize: '0.8em'
  },
  titleSize: {
    margin: '5px 10px 0 15px',
    fontSize: '0.9em',
    minHeight: '13px',
    padding: '0 !important',
    root: {padding: '0 !important'},
  },
  muiCardContent: {
    padding: '0px !important',
    root: {padding: '0 !important'},
  },
  muiPaperRoot: {
    padding: '0 !important',
  },
  cellHeight: {
    height: '15px',
  }
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#dddddd",
    color: "#006666",
    fontSize: 14,
  },
  root: {
    padding: '4px 3px 4px 3px !important',
    textAlign: 'center'
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#f5f8f5",
      //width:  '30px 30px 30px 30px !important',
      //whiteSpace: 'nowrap',
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#ffffff",
      //width:  '30px 30px 30px 30px !important',
      //whiteSpace: 'nowrap',
    },
  },
}))(TableRow);

class WorkMenu extends React.Component {
  state = {
    comment: '',

  };

  nodata = {
    padding: '10px 10px 10px 5px !important',
  };

onClickNew = () => {
  this.props.history.push('/workImportantMatter');
};

onClickConsider = (Id,Status__c, PaidFlag__c) => {
  console.log("#WorkConsideration表示#");
  const param = {
    Id,Status__c
  };
  console.log(param);
  this.props.selectApply(param); // 対象のIdとステータスを設定
  if (PaidFlag__c) {
    this.props.history.push('/workConsiderationPay');
  } else { // 無償
  this.props.history.push('/workConsideration');
  }
};

// ログイン画面に遷移
doMoveLogin = () => {
  this.props.history.push("/login");
};


  render() {
    const { classes, applyList } = this.props;

    let workData = [];
    console.log(applyList);
    if (applyList) {
      if (applyList.errorCode === '00000') {
        if (applyList.data) {
          workData = applyList.data;
        }
      }
      else {
        // エラーだった場合の処理
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }

    return (
      <div className={classes.root}>
      <UserHeader />
      <Grid item xs={12} className={classes.root2}>
        <div className={classes.root2}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
          onClick={this.onClickNew}
        >
         <span><FormattedMessage id={'workmenu.btn.new'}/></span>
        </Button>
        </div>

        </Grid>
        <div style={{margin: "-10px 5px 10px 5px"}}>
        <Typography align="center"><FormattedMessage id={'workmenu.message.notice'}/></Typography>
        {/*
        <Typography display="inline" className={classes.typographySec}>
          <FormattedMessage id={'workmenu.info.title'} />
          <br/>
          <FormattedMessage id={'workmenu.info.text'} />
          <br/>
          <FormattedMessage id={'workmenu.info.contact'} />
        </Typography>
        */}
        </div>

        <Typography variant="h6" className={classes.typography}><span><FormattedMessage id={'workmenu.title.list'}/></span></Typography>
          <Paper className={classes.paper}>
           {workData.length > 0 ?
            <Table className={classes.table}>
              <TableHead>
                <TableRow style={{height: 16, whiteSpace: 'nowrap'}}>
                  <StyledTableCell style={{width: '16%'}}><span><FormattedMessage id={'workmenu.title.number'}/></span></StyledTableCell>
                  <StyledTableCell style={{width: '65%'}}><span><FormattedMessage id={'workmenu.title.subject'}/></span></StyledTableCell>
                  <StyledTableCell style={{width: '19%'}}><span><FormattedMessage id={'workmenu.title.status'}/></span></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workData.map(row => (
                  <StyledTableRow
                    key={row.Id}
                    className={classes.cellHeight}
                    >
                    <StyledTableCell
                      onClick={()=>{this.onClickConsider(row.Id, row.Status__c, row.PaidFlag__c)}}
                      component="th"
                      scope="row"
                      style={{whiteSpace: 'nowrap', width:"200px"}}
                     >
                      <u style={{color:'#0000ff', cursor:'hand'}}>{row.OrderNumber__c}</u>
                    </StyledTableCell>
                    <StyledTableCell style={{width: "50%", textAlign: "left"}}>
                      <Tooltip title={row.OrderName__c} placement="top">
                      <div style={{overflow:"hidden", height:16, width:"100%"}}>
                        {row.OrderName__c}
                      </div>
                    </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell style={{whiteSpace: 'nowrap', width: "20%"}}>{row.StatusOperator__c}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            :
            <div style={{margin: "10px 0 10px 0", textAlign: "center"}}>
            <FormattedMessage className={classes.typography} id={'message.info.nodata'} />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

WorkMenu.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  selectApply: workOperations.selectApply,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
    )(WorkMenu));