import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import conf from '../../../config/config.js';
import UserHeader from '../../organisms/UserHeader';
import * as common from '../../common';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4px 16px 4px',
  },
  typographyOrderName:{
    margin: '30px 10px 0 10px'
  },
  typographySummary:{
    margin: '10px 10px 0 16px'
  },
  typographyInfo: {
    color: "rgba(0, 0, 0, 0.87)",
    border: "solid 1px rgba(0, 0, 0, 0.12);",
    margin: '20px 20px -10px 20px',
    padding: '10px 10px 10px 10px',
    borderRadius: "0.1em",
    variant: "subtitle2",
  },
  typographyItemName: {
    margin: "10px 0 0 16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1em",
  },
  typographyValue: {
    margin: "0 0 0 50px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1em",
  },
  typographyText: {
    margin: "10px 0 0 16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1em",
  },
  typographyText2: {
    margin: "0 0 0 16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1em",
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  button: {
    textAlign: 'center',
    marginTop: '10px',
    width: '60%',
    maxWidth: '450px',
  },
});

class WorkEstimateConfirm extends React.Component {

  state = {
    isDownloadedEstimate: false,
    isDownloadedClause: false,
  };

  // 承諾ボタンクリック
  onClickConsent = () => {
    const { doOpenDialog } = this.props;
    const dialog = {
      type: 'okcancel', // 「はい」と「いいえ」を表示
      title: '',
      message: 'workEstimateConfirm.dialog.inquiry',
      action: this.doAction
    };
    doOpenDialog(dialog);
  }

  // 承諾処理実行
  doAction = async() => {

    const { doApplyConsentOperation, doOpenDialog } = this.props;
    try {
      const result = await doApplyConsentOperation();
      console.log(result);
      if (result === '00000') {
        // 正常
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workEstimateConfirm.dialog.finish',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else if (result === '20800') {
        // 取付申込承諾失敗
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'workEstimateConfirm.dialog.error.consentfailure',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else {
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.title',
      };
      doOpenDialog(dialog);
    }
  };

  // 申込内容確認画面に遷移
  onClickBack = async () => {
    this.props.history.push('/workConsiderationPay');
  };

  // 見積書ダウンロード
  onDownloadEstimate = async(e) => {
    e.preventDefault();
    const { auth, applyDetail, doOpenDialog } = this.props;
    console.log('start onDownloadEstimate');

    // 見積書ファイル名作成
    let ParentId = applyDetail.data.Id;
    let FileName = "見積書_" + applyDetail.data.OrderNumber__c + '.pdf';
    let browser = common.browser();
    let childWindow;
    let childWindowName = FileName + ("00000" + Math.floor(Math.random() * 100000)).slice(-5);

    if (browser === 'IOS_SAFARI' || browser === 'MAC_SAFARI') {
      // Safariポップアップ抑止対策
      childWindow = window.open('', childWindowName);
    }

    try {
      const ret = await axios.post(conf.API_URL + "GetAttachedFile/getContentDocument",
                        { ParentId, FileName }, {
                        headers: {
                          Authorization: auth.loginInfo.idToken,
                        }
      });
      if (ret.data.body.errorCode === "00000") {
        const blob = common.base64toBlob(ret.data.body.data.contentDocumentBody, 'application/pdf');
        window.URL = window.URL || window.webkitURL;
        const url = window.URL.createObjectURL(blob);

        childWindow = window.open(url, childWindowName);
        setTimeout(()=>{
          childWindow.document.title = FileName;
        }, 100);
      } else {
        console.log("error 見積書ダウンロード失敗 error code:" + ret.data.body.errorCode);
        if (childWindow) childWindow.close();
        const dialog = {
          type: 'ok', // 閉じるのみ表示
          title: '',
          message: 'workEstimateConfirm.dialog.error.estimateDLfailure',
        };
        doOpenDialog(dialog);
      }

    }catch(e){
      console.log("error 見積書ダウンロード失敗 " + e.message);
      if (childWindow) childWindow.close();
      const dialog = {
        type: 'ok', // 閉じるのみ表示
        title: '',
        message: 'workEstimateConfirm.dialog.error.estimateDLfailure',
      };
      doOpenDialog(dialog);
    }

    // 見積書ダウンロード済
    this.setState({isDownloadedEstimate: true});
    console.log('end onDownloadEstimate');
  };

  // 約款ダウンロード
  onDownloadClause = (e) => {
    // 約款ダウンロード済
    this.setState({isDownloadedClause: true});
  };

  render() {
    const { classes, applyDetail } = this.props;
    const { isDownloadedEstimate, isDownloadedClause } = this.state;
    const applyData = applyDetail.data;

    let estimateAmout = applyData.Cost.EstimateBody__c + applyData.Cost.EstimateTax__c;
    let isConsentAble = false;
    if (isDownloadedEstimate && isDownloadedClause
          && applyData.AttachApprovalInput__c === "未") {
      isConsentAble = true;
    }

    return (
      <div className={classes.root}>
        <form noValidate autoComplete="off" className={classes.root}>
          <UserHeader />

          <Typography variant="h5" className={classes.typographyOrderName}>件名：{applyData.OrderName__c}</Typography>
          <br />
          <Typography variant="subtitle1" className={classes.typographySummary}>お見積金額および付随書類の確認と承諾入力</Typography>
          <Typography display="inline" className={classes.typographyInfo}>
            <span>お見積確認にあたって</span><br />
            <span>
              &nbsp;&nbsp;この度は、防護管取付サービスのご利用、誠にありがとうございます。</span
            ><br />
            <span>
              &nbsp;&nbsp;下記に、サービスの契約に係る事項をお知らせしますので、内容のご確認をお願いいたします。
            </span>
          </Typography>
          <br />
          <Typography variant="h6" className={classes.typographyItemName}>お見積金額</Typography>
          <Typography variant="h6" className={classes.typographyValue}>{estimateAmout.toLocaleString()}&nbsp;円&nbsp;(税込)</Typography>
          <Typography variant="h6" className={classes.typographyItemName}>付随書類※</Typography>
          <Typography variant="h6" className={classes.typographyValue}>
            ・<u style={{color:'#0000ff', cursor:'hand'}}>
                <a target="_blank" rel="noopener noreferrer" href="true" onClick={this.onDownloadEstimate}>
                  見積金額明細
                </a>
              </u>
          </Typography>
          <Typography variant="h6" className={classes.typographyValue}>
            ・<u style={{color:'#0000ff', cursor:'hand'}}>
                <a target="_blank" rel="noopener noreferrer" href={conf.CLAUSE_PDF_URL} onClick={this.onDownloadClause}>
                  約款(キャンセル料含む)
                </a>
              </u>
          </Typography>
          <Typography variant="h6" className={classes.typographyItemName}>内容に関する問い合わせ先</Typography>
          <Typography variant="h6" className={classes.typographyValue}>防護管受付センター　0120-007172</Typography>
          <br />
          <Typography variant="h6" className={classes.typographyText}>
            取付内容および上のお見積金額・付随書類に承諾いただけましたら、次の[承諾]をクリックしてください。
          </Typography>

          <Typography className={classes.typographyText2}>
            ※付随書類（２つ）を全て開いてご確認いただきますと、承諾ボタンが活性化します。
          </Typography>
          <br />

          <div className={classes.buttonArea}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
                onClick={this.onClickConsent}
                disabled={ isConsentAble ? false: true }
              >
              承諾
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                size="large"
                onClick={this.onClickBack}
              >
                <span><FormattedMessage id={'common.btn.back'}/></span>
              </Button>
            </Grid>
          </div>
        </form>
      </div>
    );
  }
}

const FORM_NAME = "WorkEstimateConfirm";

const mapStateToProps = state => ({
  initialValues: {
  }
});

WorkEstimateConfirm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect(state => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(WorkEstimateConfirm));

WorkEstimateConfirm.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(WorkEstimateConfirm)
);
