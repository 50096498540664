import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, getFormValues } from "redux-form";
import * as validateRule from '../../validateRule';
import { connect } from 'react-redux'; 
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '90px',
    padding: '16px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    width: '60%',
    maxWidth: '300px',
  },
  textField: {
    width: '80%',
    maxWidth: '800px',
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

renderTextField.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

class MailUpdate extends React.Component {
  state = {
    email: ''
  };

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  backPage = async() => {
    console.log("#前の画面に戻る#");
    this.props.history.goBack();
  };

  
  doMailUpdate = async() => {
    
    const { doOpenDialog, values, userInfo } = this.props;
    if (values.email === userInfo.data.Mail__c) {
      const dialog = {
        type: 'close',
        title: '',
        message: 'mailUpdate.dialog.error.input2',
      };
      doOpenDialog(dialog);
    } else {
      console.log("#mailUpdate実施#");
      const { mailUpdateAction } = this.props;
      try {
        console.log('API呼出');
        const result = await mailUpdateAction({});
        console.log("result",result);
        if (result === 'OK') {
          // 正常処理
          const dialog = {
            type: 'ok',
            title: '',
            message: 'mailUpdate.dialog.finish',
            action: this.doMoveVerify
          };
          doOpenDialog(dialog);
        }
        else if (result === 'USER_EXIST') {
          // メールアドレス重複
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'mailUpdate.dialog.error.input',
          };
          doOpenDialog(dialog);
        }
        else if (result === 'UPDATE_ERROR') {
          // メールアドレス変更失敗
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'mailUpdate.dialog.error.failure',
          };
          doOpenDialog(dialog);
        }
        else if (result === 'MAIL_ERROR') {
          // メールアドレス不正
          const dialog = {
            type: 'close', // '閉じる'のみ表示
            title: '',
            message: 'mailUpdate.dialog.error.mail',
          };
          doOpenDialog(dialog);
        }
        else {
          // システムエラーだった場合
          const dialog = {
            type: 'error', // '閉じる'のみ表示
            title: 'system.message.error.title',
            message: 'system.message.error.context',
          };
          doOpenDialog(dialog);
        }
      }
      catch (err) {
        console.log(`エラー: ${err}`);
        // システムエラーだった場合
        const dialog = { 
          type: 'error', // '閉じる'のみ表示 
          title: 'system.message.error.title', 
          message: 'system.message.error.context',
        }; 
        doOpenDialog(dialog);
      }
    }
  };

  // 確認画面に遷移
  doMoveVerify = () => {
    this.props.history.push('/mailUpdateVerify');
  }
  
  handleSubmit = (e) => {
    e.preventDefault();  
  }

  render() {
    const { classes, handleSubmit } = this.props;
    const { email } = this.state;
    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root} onSubmit={this.handleSubmit}>
         <Grid item xs={12}>
          <Field
            id="filled-mail"
            name="email"
            label=<FormattedMessage id={'login.in.mailaddress'}/>
            className={classes.textField}
            value={email}
            required={true}
            onChange={e => this.setState({ email: e.target.value })}
            margin="normal"
            variant="outlined"
            component={renderTextField}
            validate={[
              validateRule.required,
              validateRule.minLength(6),
              validateRule.maxLength(80),
              validateRule.email,
              validateRule.emailDomain,
            ]}
          />
        </Grid>
       <Grid item xs={12}>
        <Button
          onClick={handleSubmit(this.doMailUpdate)}
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
        >
          <span><FormattedMessage id={'mailUpdate.btn.doMailUpdate'} /></span>
        </Button>
        </Grid>
       <Grid item xs={12}>
        <Button
          onClick={this.backPage}
          variant="outlined"
          color="primary"
          className={classes.button}
          size="large"
        >
          <span><FormattedMessage id={'mailUpdate.btn.backPage'} /></span>
        </Button>
        </Grid>
      </form>
      </div>
  
    );
  }
}

MailUpdate.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({ 
  initialValues: {
        email:null,
  },
}); 

MailUpdate = reduxForm({
  form: "MailUpdate",
  destroyOnUnmount: false,
  enableReinitialize: true, 
})(connect(state => { 
  return { 
    values: getFormValues("MailUpdate")(state), 
  }; 
})(MailUpdate));


export default withStyles(styles)( 
  connect(
    mapStateToProps
  )(MailUpdate));
