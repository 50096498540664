/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

const harfKanaMap = {
  'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
  'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
  'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
  'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
  'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
  'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
  'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
  'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
  'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
  'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
  'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
  'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
  'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
  'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
  'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
  'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
  'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
  'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
  '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
};

// 全角→半角変換
export const full2Harf = (full) => {
  if(!full) return '';
  return full.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  })
  .replace(/　/g, ' ');
};

// 半角→全角変換
export const harf2Full = (harf) => {
  if(!harf) return '';

  // アルファベット 数字 記号
  let full = harf.replace(/[A-Za-z0-9!-~]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });

  // 半角カナ→全角カナ
  let reg = new RegExp('(' + Object.keys(harfKanaMap).join('|') + ')', 'g');
  full = full.replace(reg, function (match) {
                return harfKanaMap[match];
            })
            .replace(/ﾞ/g, '゛')
            .replace(/ﾟ/g, '゜')
            .replace(/ /g, '　');

  return full;
};

// base64からBlob変換
export const base64toBlob = (base64, mime_ctype) => {
  // 日本語の文字化けに対処するためBOMを作成
  // let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

  let bin = window.atob(base64.replace(/^.*,/, ''));
  let buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
     buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try{
    var blob = new window.Blob([buffer.buffer], {
      type: mime_ctype,
    });
  }catch (e){
    return false;
  }
  return blob;
};

// ブラウザ判定
export const browser = () => {
  var ua = window.navigator.userAgent.toLowerCase();

  // Mac
  if(ua.indexOf("mac os x") !== -1) {
    // Chrome
    if(ua.indexOf("chrome" && ua.indexOf('edge') == -1) !== -1) {
      return "MAC_CHROME";
    }
    // Safari
    if(ua.indexOf("safari") !== -1) {
      return "MAC_SAFARI";
    }
  }

  // iPhone
  if(ua.indexOf("iphone") !== -1) {
    // Chrome
    if(ua.indexOf("chrome") !== -1 && ua.indexOf('edge') == -1) {
      return "IOS_CHROME";
    }
    // Safari
    if(ua.indexOf("safari") !== -1) {
      return "IOS_SAFARI";
    }
  }

  return "OTHER";
};
