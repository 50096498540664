const CHANGE_THEME = 'CHANGE_THEME';
const CHANGE_TAB = 'CHANGE_TAB';
const ADD_TODO = 'ADD_TODO';
const DO_LOGIN = 'DO_LOGIN';
const DO_WATER_PRICE = 'DO_WATER_PRICE';
const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';
const GET_APPLY_LIST = 'GET_APPLY_LIST';
const DO_APPLY_PROTECTIVE_TUBE = 'DO_APPLY_PROTECTIVE_TUBE';  // 取付申込登録
const GET_APPLY_DETAIL = 'GET_APPLY_DETAIL';                  // 申込詳細取得
const SELECT_APPLY_LIST = 'SELECT_APPLY_LIST';
const DO_APPLY_CONSENT = 'DO_APPLY_CONSENT';                  // 取付承諾
const DO_APPLY_REMOVAL = 'DO_APPLY_REMOVAL';
const GET_INQUIRY_LIST = 'GET_INQUIRY_LIST';
const CLEAR_APPLY_DETAIL = "CLEAR_APPLY_DETAIL";
const GET_FINAL_IMAGE = "GET_FINAL_IMAGE";
const CLEAR_FINAL_IMAGE = "CLEAR_FINAL_IMAGE";

export default {
  CHANGE_THEME,
  ADD_TODO,
  DO_LOGIN,
  DO_WATER_PRICE,
  CHANGE_TAB,
  START_REQUEST,
  END_REQUEST,
  GET_APPLY_LIST,
  DO_APPLY_PROTECTIVE_TUBE,
  GET_APPLY_DETAIL,
  SELECT_APPLY_LIST,
  DO_APPLY_CONSENT,
  DO_APPLY_REMOVAL,
  GET_INQUIRY_LIST,
  CLEAR_APPLY_DETAIL,
  GET_FINAL_IMAGE,
  CLEAR_FINAL_IMAGE,
};
