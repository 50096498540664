import React from 'react';
import { connect } from 'react-redux';
import Login from '../../components/templates/Login';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  formData: state.form,
});
const mapDispatchToProps = {
  loginAction: authOperations.doLoginOperation,
  logoutAction: authOperations.doLogoutOperation,
  doOpenDialog: commonOperations.doOpenDialog,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    // store初期化
//    this.props.logoutAction();
  }

  componentWillMount() {
    // store初期化
    this.props.logoutAction();
  }

  render() {
    return (<Login
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
