import types from './types';

export const startRequest = () => ({
  type: types.START_REQUEST
});

export const endRequest = () => ({
  type: types.END_REQUEST
});

export const openDialog = dialogInfo => ({
    type: types.OPEN_DIALOG,
    dialogInfo
});

export const closeDialog = () => ({
    type: types.CLOSE_DIALOG
});

export const openModal = modalInfo => ({
    type: types.OPEN_MODAL,
    modalInfo
});

export const closeModal = modalInfo => ({
    type: types.CLOSE_MODAL
});

export const checkAccess = accessInfo => ({
    type: types.CHECK_ACCESS,
    accessInfo
});

export default {
    startRequest,
    endRequest,
    openDialog,
    closeDialog,
    openModal,
    closeModal,
    checkAccess,
};

