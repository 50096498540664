import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from "redux-form";
import * as validateRule from '../../validateRule';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '90px',
    padding: '16px',
    textAlign: 'center',
  },
    button: {
    marginTop: '20px',
    width: '60%',
    textAlign: 'center',
    maxWidth: '300px',
  },
   text: {
    textAlign: 'left',
    marginLeft: "10%",
  }, 
    textField: {
    width: '80%',
    maxWidth: '800px',
  },
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

renderTextField.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

class PasswordReset extends React.Component {
  state = {
    email: ''
  };

onClickBack = () => {
  this.props.history.push('/login');
};

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  backLogin = async() => {
    console.log("#login画面に戻る#");
    this.props.history.push('/login');
  };

  doPasswordReset = async() => {
    console.log("#passwordReset実施#");
    const { sendAuthenticationAction } = this.props;
    try {
      console.log('API呼出');
      const result = await sendAuthenticationAction({});
      console.log(result);
      if (result === 'OK') {
        // 正常処理
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok',
          title: '',
          message: 'passwordReset.dialog.finish',
          action: this.doMoveVerify
        };
        doOpenDialog(dialog);
      }
      else if (result === 'NO_USER') {
        // メールアドレス不正
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'passwordReset.dialog.error.input',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'NO_VERIFY') {
        // メールアドレスが未検証
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'passwordReset.dialog.error.mailVerify',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'LIMIT_EXCEED') {
        // 試行回数を超えた
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'passwordReset.dialog.error.limitExceeded',
        };
        doOpenDialog(dialog);
      }
      else {
        // システムエラーだった場合
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // システムエラーだった場合
      const { doOpenDialog } = this.props;
      const dialog = { 
        type: 'error', // '閉じる'のみ表示 
        title: 'system.message.error.title', 
        message: 'system.message.error.context',
      }; 
      doOpenDialog(dialog);
    }
  };

  doMoveVerify = () => {
    this.props.history.push('/passwordResetVerify');
  }
  render() {
    const { classes, handleSubmit } = this.props;
    const { email } = this.state;
    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root}>
        <Typography className={classes.text}>パスワードを忘れた方は、以下の情報を入力し、送信ください。</Typography>
         <Grid item xs={12}>
          <Field
            id="filled-mail"
            name="email"
            label=<FormattedMessage id={'login.in.mailaddress'}/>
            className={classes.textField}
            value={email}
            required={true}
            onChange={e => this.setState({ email: e.target.value })}
            margin="normal"
            variant="outlined"
            component={renderTextField}
            validate={[
              validateRule.required,
              validateRule.minLength(6),
              validateRule.maxLength(80),
              validateRule.email,
              validateRule.emailDomain
            ]}
          />
          </Grid>
          
        <Grid item xs={12}>
        <Button
          onClick={handleSubmit(this.doPasswordReset)}
          variant="contained"
          color="primary"
          className={classes.button}
          //size="large"
        >
          <span><FormattedMessage id={'passwordReset.btn.doPasswordReset'} /></span>
        </Button>
        </Grid>
        <Grid item xs={12}>
        <Button
          onClick={this.backLogin}
          variant="outlined"
          color="primary"
          className={classes.button}
          //size="large"
        >
          <span><FormattedMessage id={'passwordReset.btn.backlogin'} /></span>
        </Button>
        </Grid>
      </form>
      </div>
  
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

PasswordReset = reduxForm({
  form: "PasswordReset",
  destroyOnUnmount: false
})(PasswordReset);


export default withStyles(styles)(PasswordReset);
