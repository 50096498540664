import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import * as validateRule from '../../validateRule';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '90px',
    padding: '16px',
    textAlign: 'center',
    
  },

  button: {
    marginTop: '20px',
    width: '60%',
    maxWidth: '300px',
  },
  textField: {
    width: '80%',
    maxWidth: '800px',
  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

class MailUpdateVerify extends React.Component {

  onChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  doMailUpdateVerify = async() => {
    console.log("#メールアドレス変更(認証)呼出し#");
    const { mailUpdateVerifyAction } = this.props;
    try {
      console.log('API呼出');
      const result = await mailUpdateVerifyAction({});
      console.log(result);
      if (result === 'OK') {
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // ログイン画面に戻るため
          title: '',
          message: 'mailUpdateVerify.dialog.finish',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'CODE_ERROR') {
        // 認証コード誤り
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'mailUpdateVerify.dialog.error.input',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'CODE_EXPIRED') {
        // 認証コードの有効期限切れ
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'mailUpdateVerify.dialog.error.expired',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'USER_EXIST') {
        // メールアドレス登録済
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'mailUpdateVerify.dialog.error.exist',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else if (result === 'UPDATE_ERROR') {
        // メールアドレス認証失敗
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'mailUpdateVerify.dialog.error.failure',
          action: this.onClickBack
        };
        doOpenDialog(dialog);
      }
      else {
        // エラーだった場合の処理
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // エラーだった場合の処理
      const { doOpenDialog } = this.props;
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }
  };

　// 戻る(メールアドレス変更画面に遷移)
  onClickBack = () => {
    this.props.history.push('/mailUpdate');
  };

  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" className={classes.root}>
        <Grid>
          <Field
            id="filled-authenticationCode"
            label=<FormattedMessage id={'mailUpdateVerify.authenticationCode'} />
            className={classes.textField}
            name="authenticationCode"
            onChange={this.onChange}
            margin="normal"
            variant="outlined"
            component={renderTextField}
            type="text"
            required={true}
            validate={[
              validateRule.required,
              validateRule.number,
              validateRule.minLength(6),
              validateRule.maxLength(6),
            ]}
          />
          </Grid>
          <Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            onClick={handleSubmit(this.doMailUpdateVerify)}
          >
            <span><FormattedMessage id={'mailUpdateVerify.btn.doMailUpdateVerify'} /></span>
          </Button>
          </Grid>
        </form>
      </div>
    );
  }
}

MailUpdateVerify.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const selector = formValueSelector('MailUpdateVerify');

MailUpdateVerify = reduxForm({
  form: 'MailUpdateVerify',
  destroyOnUnmount: false
})(connect(state => {
  return {
    authenticationCode: selector(state, 'authenticationCode'),
  };
})(MailUpdateVerify));

export default withStyles(styles)(MailUpdateVerify);
