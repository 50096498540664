import types from './types';


// 色彩
export const changeTheme = theme => ({
  type: types.CHANGE_THEME,
  theme,
});


// 文字
export const changeFont = fontStyle => ({
  type: types.CHANGE_FONT,
  fontStyle,
});

//
export const startRequest = () => ({
  type: types.START_REQUEST
});

export const endRequest = () => ({
  type: types.END_REQUEST
});

// 申込一覧取得
export const getApplyList = applyList => ({
    type: types.GET_APPLY_LIST,
    applyList
});

// 申込一覧選択
export const selectApply = selectedApply => ({
    type:types.SELECT_APPLY_LIST,
    selectedApply
}); 

// 取付申込登録
export const doApplyProtectiveTube = applyProtectiveTube => ({ 
  type: types.DO_APPLY_PROTECTIVE_TUBE, 
  applyProtectiveTube 
});
 
// 取付申込詳細取得
export const getApplyDetail = applyDetail => ({ 
  type: types.GET_APPLY_DETAIL, 
  applyDetail 
});

// 取付申込詳細クリア
export const clearApplyDetail = () => ({
  type:types.CLEAR_APPLY_DETAIL
});

// 最終画像取得
export const getFinalImage = (ImageBody, Description__c) => ({
  type: types.GET_FINAL_IMAGE,
  ImageBody,
  Description__c,
});

// 最終画像クリア
export const clearFinalImage = () => ({
  type: types.CLEAR_FINAL_IMAGE,
});

// 取付申込承諾
export const doApplyConsent = applyConsent => ({
  type: types.DO_APPLY_CONSENT,
  applyConsent
});

// 取外し作業申込
export const doApplyRemoval = applyRemoval => ({
  type:types.DO_APPLY_REMOVAL,
  applyRemoval,
});

// 問合参照
export const getInquiryList = inquiryList => ({
  type:types.GET_INQUIRY_LIST,
  inquiryList,
});

export default {
  changeTheme,
  changeFont,
  startRequest,
  endRequest,
  getApplyList,
  doApplyProtectiveTube,
  getApplyDetail,
  clearApplyDetail,
  selectApply,
  doApplyRemoval,
  getInquiryList,
  getFinalImage,
  clearFinalImage
};
