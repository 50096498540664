import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

//import App from './pages/App';
import RedirectPage from './pages/Redirect/index.js';
import Login from './pages/Login/index.js';
import UserRegist from './pages/UserRegist/index.js';
import UserRegistConfirm from './pages/UserRegistConfirm/index.js';
import PasswordReset from './pages/PasswordReset/index.js';
import PasswordResetVerify from './pages/PasswordResetVerify/index.js';
import Header from './components/organisms/Header/index.js';
import Loading from './components/organisms/Loading/index.js';
import Dialog from './components/organisms/Dialog/index.js';
import Scroll from './components/organisms/Scroll/index.js';
import Help from './components/templates/Help/index.js';
import Modal from './components/organisms/Modal/index.js';

import WorkInquiry from './pages/WorkInquiry/index.js';
import UserPolicy from './pages/UserPolicy/index.js';
import WorkMenu from './pages/WorkMenu/index.js';
import WorkImportantMatter from './pages/WorkImportantMatter/index.js';
import WorkRequest from './pages/WorkRequest/index.js';
import WorkConsideration from './pages/WorkConsideration/index.js';
import WorkEstimateConfirm from './pages/WorkEstimateConfirm/index.js';
import WorkBillConfirm from './pages/WorkBillConfirm/index.js';
import WorkRemoval from './pages/WorkRemoval/index.js';
import UserUpdate from './pages/UserUpdate/index.js';
import MailUpdate from './pages/MailUpdate/index.js';
import MailUpdateVerify from './pages/MailUpdateVerify/index.js';
import Conf from './config/config.js';

if(!Conf.consolelog)
  console.log = function (){};

const themeDefault = createMuiTheme({
  palette: {
     primary: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a38',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#4dabf5',
      main: '#ff9100',
      dark: '#1769aa',
      contrastText: '#fafafa',
    },
    typography: {
      useNextVariants: true,
    },
  },
});

const themeDark = createMuiTheme({
  palette: {
    //    type: 'dark',
    primary: {
      light: '#a2cf6e',
      main: '#8bc34a',
      dark: '#618833',
      contrastText: '#fafafa',
    },
    typography: {
      useNextVariants: true,
    },
  },
});

const themes = {
  default: themeDefault,
  dark: themeDark,
};

const routes = ({ theme }) => (
  <MuiThemeProvider theme={themes[theme]}>
    <Header />
    <Loading />
    <Dialog />
    <Scroll />
    <Modal content={<Help />} />
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/redirect" component={RedirectPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/userPolicy" component={UserPolicy} />
      <Route exact path="/userRegist" component={UserRegist} />
      <Route exact path="/userRegistConfirm" component={UserRegistConfirm} />
      <Route exact path="/passwordReset" component={PasswordReset} />
      <Route exact path="/passwordResetVerify" component={PasswordResetVerify} />
      <PrivateRoute exact path="/workInquiry" component={WorkInquiry} />
      <PrivateRoute exact path="/workMenu" component={WorkMenu} />
      <PrivateRoute exact path="/workImportantMatter" component={WorkImportantMatter} />
      <PrivateRoute exact path="/workRequest" component={WorkRequest} />
      <PrivateRoute exact path="/workConsideration" component={WorkConsideration} />
      <PrivateRoute exact path="/workConsiderationPay" component={WorkConsideration} />
      <PrivateRoute exact path="/workEstimateConfirm" component={WorkEstimateConfirm} />
      <PrivateRoute exact path="/workBillConfirm" component={WorkBillConfirm} />
      <PrivateRoute exact path="/workRemoval" component={WorkRemoval} />
      <PrivateRoute exact path="/userUpdate" component={UserUpdate} />
      <PrivateRoute exact path="/mailUpdate" component={MailUpdate} />
      <PrivateRoute exact path="/mailUpdateVerify" component={MailUpdateVerify} />
      <Route exact path="*" component={Login} />
    </Switch>
  </MuiThemeProvider>

);

function privateRoute({ isLoggedIn, component: Component, ...rest }) {
  console.log(isLoggedIn);
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <div>
            <Component {...props} /> <
    /div>
  ): (
    <Redirect to="/login"  {...props} />
  )
}
/>
);
}

privateRoute.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
  //component: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const PrivateRoute = connect(mapStateToProps)(privateRoute);

/////////////////////////////////////////////////////////////

const mapStateToPropsForMui = state => ({
  theme: state.style.theme,
});

const Routes = connect(mapStateToPropsForMui)(routes);

//const ConntectedHeader = connect()(Header);
export default Routes;
