// visibility Filter Constants
const SHOW_ALL = 'SHOW_ALL';
const SHOW_COMPLETED = 'SHOW_COMPLETED';
const SHOW_ACTIVE = 'SHOW_ACTIVE';
const LOGIN_API = '';

export default {
  SHOW_ALL,
  SHOW_COMPLETED,
  SHOW_ACTIVE,
  LOGIN_API,
};
