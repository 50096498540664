import { startRequest, endRequest, openDialog, closeDialog, openModal, closeModal, checkAccess } from './actions';
import axios from 'axios';
import conf from './../../config/config.js';

const dialogInfo = {
    type: ['ok', 'okcancel', 'close'],
    title: '',
    context: '',
    action: '',
    open: false,
};

const doOpenDialog = ({ type, title, message, action }) => {
    dialogInfo.type = type;
    dialogInfo.title = title;
    dialogInfo.context = message;
    dialogInfo.action = action;
    dialogInfo.open = true;
    dialogInfo.address = window.location.pathname;
    return dispatch => {
        dispatch(openDialog(dialogInfo));
    };
};

const modalInfo = {
    btnExist: false,
    open: false,
};

const doOpenModal = ( btnExist ) => {
    modalInfo.btnExist = btnExist;
    modalInfo.open = true;
    return dispatch => {
        dispatch(openModal(modalInfo));
    };
};

const doCloseModal = () => {
    return dispatch => {
        dispatch(closeModal());
    };
};

// 住所検索
const doGetPost = (zipcode) => {
    /*
    return async(dispatch, getState) => {
        return $.getJSON('https://zip-cloud.appspot.com/api/search?callback=?',
            {
                zipcode: zipcode
            }
        );
    };
    */

    return async(dispatch, getState) => { 
        // 引数：住所検索用郵便番号
        return await axios.post(conf.API_URL + "/Common/getAddress", 
                          { zipCode: zipcode }, { 
        });
     };

}; 

const accessInfo = {
    time: null,
};

const doCheckAccess = () => {
    //UTC変換したタイムスタンプを保持
    accessInfo.time = (new Date(Date.now() + new Date().getTimezoneOffset() * 60000)).getTime();
    return dispatch => {
        dispatch(checkAccess(accessInfo));
    };
};

export default {
    startRequest,
    endRequest,
    doOpenDialog,
    closeDialog,
    doOpenModal,
    doCloseModal,
    doGetPost,
    doCheckAccess,
};
