const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';
const GET_APPLY_LIST = 'GET_APPLY_LIST';
const CHECK_ACCESS = 'CHECK_ACCESS';

export default {
    START_REQUEST,
    END_REQUEST,
    OPEN_DIALOG,
    CLOSE_DIALOG,
    OPEN_MODAL,
    CLOSE_MODAL,
    GET_APPLY_LIST,
    CHECK_ACCESS,
};
