import m from 'moment';

// 取外し予定日(初期値)
const getInitRemvScheduleDate = (applyDetail) => {
  let endAttachTerm = applyDetail.data.EndAttachTerm__c

  // 取外し予定日の初期値は取付期間(至) + 1
  let initRemvDate = '';
  if (endAttachTerm) {
    initRemvDate = m(endAttachTerm).toDate();
    initRemvDate.setDate(initRemvDate.getDate() + 1);
    initRemvDate = m(initRemvDate).format('YYYY-MM-DD');
  }
  return initRemvDate;
};

export default {
  getInitRemvScheduleDate,
};
