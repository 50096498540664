import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    marginTop: '70px'
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  button2: {
    marginTop: '20px',
    width: '80%',
    textAlign: 'center',
    maxWidth: '400px',
  },
  serchbutton: {
    marginTop: '20px',
    marginLeft: '10px',
  },
  formLabel: {
    margin: '0 0 -10px 0',
  },
  formControl: {
    margin: '10px 0 -5px 0',
  },
  typography: {
    fontSize: "0.8em",
    color: "red"
  },
  postalRow: {
    alignItems: 'center',
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '20px 20px -10px 20px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  textFieldGrid: {
    width: '100%'
  },
});

class UserRegistConfirm extends React.Component {

  userRegistConfirm = async() => {
    console.log("#利用者登録呼出#");
    const { doUserRegistAction } = this.props;
    try {
      const result = await doUserRegistAction({});
      console.log(result);
      if (result === 'OK') {
        // 正常
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'userRegistConfirm.dialog.finish',
          action: this.doAction
        };
        doOpenDialog(dialog);
      }
      else if (result === 'USER_EXIST') {
        // 登録済メールアドレス
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userRegistConfirm.dialog.error.exist',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'LIMIT_EXCEED') {
        // 試行回数を超えた
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userRegistConfirm.dialog.error.limitExceeded',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'MAIL_ERROR') {
        // メールアドレス不正
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userRegistConfirm.dialog.error.mail',
        };
        doOpenDialog(dialog);
      }
      else if (result === 'REGIST_ERROR') {
        // 利用者登録失敗(Dynamo)
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'userRegistConfirm.dialog.error.failure',
        };
        doOpenDialog(dialog);
      }
      else {
        // その他エラー
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }
    catch (err) {
      console.log(`エラー: ${err}`);
      // その他エラー
      const { doOpenDialog } = this.props;
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }

  };

  doAction = () => {
    this.props.history.push('/login');
  }

  backUserRegist = async() => {
    console.log("#userRegist画面へ戻る#");
    this.props.history.push('/userRegist');
  };

  render() {
    const { classes } = this.props;
    const {
      mAddress,
      companyname,
      companynameKanji,
      usernameFamily,
      usernameFirst,
      usernameFamilyKana,
      usernameFirstKana,
      zipCode,
      companyaddress,
      mobile,
      telephone
    } = this.props;

    return (
      <div>
       <form noValidate autoComplete="off" className={classes.root}>
        <TextField
          id="filled-email-confirm"
          label=<FormattedMessage id={'userRegistConfirm.mailaddress'} />
          className={classes.textField}
          defaultValue={mAddress}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
        <TextField
          id="filled-companynameKanji"
          label=<FormattedMessage id={'userRegistConfirm.companynameKanji'} />
          className={classes.textField}
          defaultValue={companynameKanji}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
        <TextField
          id="filled-companyname"
          label=<FormattedMessage id={'userRegistConfirm.companyname'} />
          className={classes.textField}
          defaultValue={companyname}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
        <Grid container spacing={24}>
          <Grid item xs={6} md={6}>
            <TextField
              id="filled-usernameFamily"
              label=<FormattedMessage id={'userRegistConfirm.usernameFamily'} />
              className={classes.textFieldGrid}
              defaultValue={usernameFamily}
              margin="normal"
              InputProps = {
                {
                  readOnly: true
                }
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="filled-usernameFirst"
              label=<FormattedMessage id={'userRegistConfirm.usernameFirst'} />
              className={classes.textFieldGrid}
              defaultValue={usernameFirst}
              margin="normal"
              InputProps = {
                {
                  readOnly: true
                }
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <TextField
              id="filled-usernameFamilyKana"
              label=<FormattedMessage id={'userRegistConfirm.usernameFamilyKana'} />
              className={classes.textFieldGrid}
              defaultValue={usernameFamilyKana}
              margin="normal"
              InputProps = {
                {
                  readOnly: true
                }
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-usernameFirstKana"
              label=<FormattedMessage id={'userRegistConfirm.usernameFirstKana'} />
              className={classes.textFieldGrid}
              defaultValue={usernameFirstKana}
              margin="normal"
              InputProps = {
                {
                  readOnly: true
                }
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* 住所 */}
        <Grid container spacing={24}>
          <Grid item xs={5}>
            <TextField
              id="zipCode"
              name="zipCode"
              label=<FormattedMessage id={'userRegistConfirm.zipCode'} />
              className={classes.textFieldGrid}
              defaultValue={zipCode}
              margin="normal"
              InputProps = {
                {
                  readOnly: true
                }
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
        <TextField
          id="companyaddress"
          name="companyaddress"
          label=<FormattedMessage id={'userRegistConfirm.companyaddress'} />
          className={classes.textField}
          defaultValue={companyaddress}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
        <TextField
          id="filled-mobile"
          label=<FormattedMessage id={'userRegistConfirm.mobile'} />
          className={classes.textField}
          defaultValue={mobile}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
        <TextField
          id="filled-telephone"
          label=<FormattedMessage id={'userRegistConfirm.telephone'} />
          className={classes.textField}
          defaultValue={telephone}
          margin="normal"
          InputProps = {
            {
              readOnly: true
            }
          }
          variant="outlined"
        />
      <div className={classes.root2}> 
       <Grid item xs={12}>  
        <Button
          id="btnOk-confirm"
          onClick={this.userRegistConfirm}
          variant="contained"
          color="primary"
          className={classes.button2}
          size="large"
        >
          <span><FormattedMessage id={'userRegistConfirm.btn.regist'} /></span>
        </Button>
        </Grid>
       <Grid item xs={12}>  
        <Button
          id="btnCancel-confirm"
          onClick={this.backUserRegist}
          variant="outlined"
          color="primary"
          className={classes.button2
          }
          size="large"
        >
          <span><FormattedMessage id={'userRegistConfirm.btn.backUserRegist'} /></span>
        </Button>
        </Grid>
      </div> 
      </form>
        </div>
    );
  }
}

UserRegistConfirm.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const selector = formValueSelector('UserRegist');

export default withStyles(styles)(connect(state => selector(
  state, 'mAddress','companyname','companynameKanji','usernameFirst','usernameFamily','usernameFirstKana','usernameFamilyKana','telephone','mobile','zipCode','companyaddress'))(UserRegistConfirm));
  