
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    flexDirection: 'column',
    marginTop: '30px',
    padding: '16px',
    textAlign: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  button: {
    marginTop: '20px',
    width: '60%',
    textAlign: 'center',
    maxWidth: '300px',
  },
  formControl: {
    padding: '0px 20px 0px 20px',
    textAlign: 'left',
  },
  context: {
    maxHeight: 400,
    overflow: 'auto',
    padding: '0px 15px 0px 15px',
  },
   text2: {
    textAlign: 'left',
    marginBottom: "15px",
  },
});

class UserPolicy extends React.Component {
  state = { agree: false}

  onClickNext = () => {
    this.props.history.push('/userRegist');
  };

  onClickBack = () => {
    this.props.history.push('/login');
  };


  render() {
    const { classes } = this.props;
    const { agree } = this.state;
    return (
     <div className={classes.root}>
     <form noValidate autoComplete="off" className={classes.root}>
      <Paper className={classes.text}>
        <div className={classes.context}>
          <h3>個人情報の取扱いについて</h3>
          <Typography className={classes.text2}>当社は、個人情報を適正に取扱い、その保護を図ることが重要な社会的責務であると考え、その責務を果たすために、次のとおり方針を掲げ、これに基づき個人情報を適切に取り扱ってまいります。</Typography>
          <div>
            <hr style={{margin:"8px 0 0 0", border:"0", color: "Darkgray", borderTop:"3px dashed"}}/>
            <h4>個人情報保護方針</h4>
            <Typography><strong>１．個人情報の取得、利用および提供時の適切性の確保</strong></Typography>
            <Typography>　個人情報の取得にあたっては、あらかじめその利用目的を特定したうえで適法かつ公正な手段によって行い、その利用目的の達成に必要な範囲内でのみ個人情報を利用します。</Typography>
            <Typography>　また、法令等で特に認められた場合を除き、原則として本人の事前同意なしに個人情報を第三者に提供いたしません。</Typography>
            <Typography className={classes.text2}>　ただし、特定個人情報（個人番号および個人番号をその内容に含む個人情報）については、本人の事前同意があっても、法令で明確に認められた場合を除き、第三者に提供いたしません。</Typography>

            <Typography><strong>２．個人情報保護に関する法律その他法令・社内規程の遵守</strong></Typography>
            <Typography>　「個人情報の保護に関する法律」、「行政手続における特定の個人を識別するための番号の利用等に関する法律（以下「番号法」という。）」その他関係法令の規定および行政機関等が規定したガイドライン等を遵守します。</Typography>
            <Typography className={classes.text2}>　また、社内における適正な個人情報の取扱いに関する基本事項を定めた個人情報保護規程を制定し、これを遵守します。</Typography>

            <Typography><strong>３．個人情報の正確性の保持および安全管理</strong></Typography>
            <Typography className={classes.text2}>　常に、個人情報を正確かつ最新の内容に保つよう努めるとともに、情報通信技術および管理組織体制の両面から合理的な安全対策を講じ、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏えい等の防止に努めます。</Typography>

            <Typography><strong>４．社内規程の継続的改善</strong></Typography>
            <Typography className={classes.text2}>　個人情報保護規程、その他個人情報保護について規定する各種社内規程類を管理し、継続的に改善するよう努めます。</Typography>
            <hr style={{border:"0", color: "Darkgray", borderTop:"3px dashed"}}/>
          </div>
          <div >
            <Typography style={{margin: "25px auto 15px 0"}}>個人情報の具体的な取扱いについては、以下のとおりです。</Typography>
            <Typography>１．個人情報の利用目的</Typography>
            <Typography>当社が、取得・保有する個人情報につきましては、次に掲げる事業において、契約の締結・履行、債権回収および債務の履行、アフターサービス、資産・設備等の形成・保全、商品・サービスの改善・開発、商品・サービスに関するアンケートの実施、その他これらに付随する業務を行うために必要な範囲内で利用いたします。</Typography>
            <div style={{marginLeft:"20px"}}><Typography className={classes.text2}>・電気工事業および電気通信工事業に付帯関連する事業</Typography></div>
            <Typography>２．個人情報の第三者への提供について </Typography>
            <Typography>当社は、次のいずれかに該当する場合を除き、個人情報を第三者に提供いたしません。</Typography>
            <Typography>(１）ご本人の同意がある場合</Typography>
            <Typography>(２）人の生命、身体または財産の保護のために必要であって、ご本人の同意を得ることが困難であるとき</Typography>
            <Typography>(３）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</Typography>
            <Typography>(４）事業の承継に伴って提供する場合</Typography>
            <Typography>(５）利用目的の達成に必要な範囲内において、業務委託先に提供する場合</Typography>
            <Typography>なお、業務委託先に提供する場合は、個人情報の取扱いに関する契約の締結等により、当該業務委託先において個人情報の安全管理が図られるよう必要かつ適切な監督を行います。</Typography>
            <Typography className={classes.text2}>(６）その他法令等に基づき第三者に対する提供が認められる場合</Typography>

            <Typography>３．個人情報の開示等について</Typography>
            <Typography>当社が保有する個人情報について開示を希望される場合には、当社は、ご本人からのお申し出であることを確認したうえで、法令等に基づき、すみやかに回答いたします。ただし、次のいずれかに該当する場合には、開示できないことがあります。</Typography>
            <Typography>(１）ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</Typography>
            <Typography>(２）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</Typography>
            <Typography>(３）法令等に違反することとなる場合</Typography>
            <Typography>(４）当社の定める手続きに従って開示のお申し出をいただけない場合</Typography>
            <Typography className={classes.text2}>また、当社が保有する個人情報の内容が事実と相違している場合等については、ご本人からのお申し出に基づき、当社の業務運営上必要な範囲内において訂正、削除等の対応をさせていただきます。</Typography>

            <Typography>【個人情報の開示等のご請求手続きについて】</Typography>
            <Typography>① 受付方法</Typography>
            <Typography>　お申し出内容により、当社所定の「個人情報開示等請求書」をご提出いただく場合がございますので、あらかじめ、防護管受付センターへお問い合わせください。</Typography>
            <Typography>② 本人または代理人の確認</Typography>
            <Typography>　ご本人からのお申し出の場合は、当社にご登録いただいております氏名、住所、連絡先等によりご本人であることの確認をさせていただきます。なお、必要に応じ、運転免許証、各種健康保険証、年金手帳、旅券（パスポート）等の公的証明書により確認させていただく場合があります。 </Typography>
            <Typography className={classes.text2}>　また、代理人の方がご請求される場合は委任状を、法定代理人の方がご請求される場合は、法定代理人の資格を証明する書類もあわせてご提出いただきます。</Typography>
            <Typography>（個人情報の開示等のお問い合わせ窓口）</Typography>
            <div style={{wordBreak: "break-all"}}><Typography>〒730-0037　広島市中区中町８-１２広島グリーンビル1Ｆ</Typography>
            <Typography>株式会社電力サポート中国　防護管受付センター</Typography></div>
            <div style={{wordBreak: "break-all", marginLeft:"12px"}}>
              <Typography className={classes.text2}>
                <span>電話番号：082-553-9626</span><br />
                <span>電話での受付時間：9時00分～17時00分まで（土・日・祝休日を除く）</span><br />
                <span>Ｅメールアドレス：UBG001@pnet.gr.energia.co.jp</span>
              </Typography>
            </div>

            <Typography>４．個人情報の取扱いに関するご相談窓口</Typography>
            <Typography className={classes.text2}>　当社の個人情報の取り扱いについて、お困りのこと、ご意見等がございましたら、次の個人情報ご相談窓口までご連絡ください。</Typography>
            <Typography>（個人情報ご相談窓口）</Typography>
            <div style={{wordBreak: "break-all"}}><Typography>〒730-0037　広島市中区中町７-２２住友生命広島平和大通りビル8Ｆ</Typography>
            <Typography>株式会社電力サポート中国　品質管理部</Typography></div>
            <div style={{wordBreak: "break-all", marginLeft:"12px"}}>
              <Typography className={classes.text2}>
                <span>電話番号：082-541-1110</span><br />
                <span>電話での受付時間：９時00分～17時00分まで（土・日・祝休日を除く）</span><br />
                <span>Ｅメールアドレス：UDESCOM@pnet.gr.energia.co.jp</span>
              </Typography>
            </div>

          </div>
        </div>


      </Paper>
        <div className={classes.formControl}>
          <FormControl component="fieldset">
            <FormGroup row >
              <FormControlLabel
                control={
                  <Checkbox checked={agree ? true : false} onChange={e => this.setState({ agree: e.target.checked })} color="primary"/>
                }
                label=<span><FormattedMessage id={'userPolicy.btn.agree'}/></span>
              />
            </FormGroup>
          </FormControl>
        </div>
      <div align="center">
       <Grid item xs={12}>
      <Button
        id="btnNext"
        variant="contained"
        color="primary"
        className={classes.button}
        size="large"
        disabled={agree === true? false: true}
        onClick={this.onClickNext}
      >
        <span><FormattedMessage id={'common.btn.next'}/></span>
      </Button>
      </Grid>
     <Grid item xs={12}>
      <Button
        id="btnDisagree"
        variant="outlined"
        color="primary"
        className={classes.button}
        size="large"
        onClick={this.onClickBack}
      >
        <span><FormattedMessage id={'common.btn.back'}/></span>
      </Button>
      </Grid>
      </div>
    </form>
    </div>
    );
  }
}

UserPolicy.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(UserPolicy);
