import { combineReducers } from 'redux';
import types from './types';

const INITIAL_STATE = { type: '', title: '', context: '', open: false, action: ''};

const isRequesting = (state = false, action) => {
  switch (action.type) {
    case types.START_REQUEST:
      return true;
    case types.END_REQUEST:
      return false;
    default:
      return state;
  }
};

const dialogInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.OPEN_DIALOG:
            return action.dialogInfo;
        case types.CLOSE_DIALOG:
            return { ...state, open: false };
        default:
            return state;
    }
};

const modalInfo = (state = { btnExist: false, open: false }, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            return action.modalInfo;
        case types.CLOSE_MODAL:
            return { btnExist: false, open: false };
        default:
            return state;
    }
};

const checkAccess = (state = { time: null }, action) => {
    switch (action.type) {
        case types.CHECK_ACCESS:
            return action.accessInfo;
        default:
            return state;
    }
};

const reducer = combineReducers({
    isRequesting,
    dialogInfo,
    modalInfo,
    checkAccess
});

export default reducer;
