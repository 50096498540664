const CHANGE_THEME = 'CHANGE_THEME';
const CHANGE_TAB = 'CHANGE_TAB';
const ADD_TODO = 'ADD_TODO';
const DO_LOGIN = 'DO_LOGIN';
const DO_WATER_PRICE = 'DO_WATER_PRICE';
const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';

export default {
  CHANGE_THEME,
  ADD_TODO,
  DO_LOGIN,
  DO_WATER_PRICE,
  CHANGE_TAB,
  START_REQUEST,
  END_REQUEST,
};
