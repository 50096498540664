import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import HelpIcon from '@material-ui/icons/Help';
import ContactMailIcon from '@material-ui/icons/ContactMailOutlined';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FormattedMessage } from 'react-intl';
//import Card from '@material-ui/core/Card';
//import CardHeader from '@material-ui/core/CardHeader';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

import { authOperations } from './../../../reducks/auth';
import { commonOperations } from './../../../reducks/common';
import { withRouter } from 'react-router';
import logo from './../../../assets/logo.js';
import logo_title from './../../../assets/logo_title.js';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  topgrow: {
    flexGrow: 1,
    margin: '3px 3px 3px 3px',
  },
  grow: {
    flexGrow: 1,
  },
  UserHeader: {
    textAlign: 'center',
    margin: '86px 24px 20px 24px',
  },
  appBarTop: {
    backgroundColor: theme.palette.primary.dark,
  },
  appBar: {
    marginTop: '22px',
  },
  toolHeight: {
    minHeight: 40,
  },

  toolbar: theme.mixins.toolbar,
});

class Header extends React.Component {
  state = {
    anchorEl: null,
    tabValue: 0,
    tab: 'home',
    timeout: false,
  };
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount = () => {
    this.timer = setInterval(this.doCheckTime, 5000);
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  doMailUpdate = () => {
    this.handleClose();
    this.props.history.push('/mailUpdate');
  };

  doUserUpdate = () => {
    this.handleClose();
    this.props.history.push('/userUpdate');
  };

  doHelp = () => {
    this.handleClose();
    this.props.doOpenModal(true);
  };

  doLogout = () => {
    this.handleClose();
    this.props.doLogout();
  };

  doClear = () => {
    this.setState({timeout:false});
    this.props.doLogout();
  };

  doCheckTime = () => {
    if(this.props.isLoggedIn){
        //UTC変換した現時刻と保持している時刻の差（ms）を計測
        let diffTime = (new Date(Date.now() + new Date().getTimezoneOffset() * 60000)).getTime() - this.props.checkAccess.time;
        if(diffTime / 1000 > 3600){
          this.setState({timeout:true});
        }
    }
  };

  render() {
    const { classes, location, isLoggedIn } = this.props;
    const anchorEl = this.state.anchorEl;
    const open = Boolean(anchorEl);
    const pathname = location.pathname.replace('/', '');
    const title = (pathname ? pathname : 'login') + '.title';

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBarTop}>
          <Typography variant="caption" color="inherit" className={classes.topgrow} >
            <img src={logo} height="16" alt="icon" /><img src={logo_title} height="16" alt="icon" />
          </Typography>
        </AppBar>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolHeight}>

            <Typography variant="h6" color="inherit" className={classes.grow} >
              <FormattedMessage id={title}/>
            </Typography>

            {isLoggedIn &&
            <div>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
              <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
               >
                <ListItem button onClick={this.doMailUpdate}>
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary=<FormattedMessage id={'header.menu.mailupdate'}/> />
                </ListItem>
                <ListItem button onClick={this.doUserUpdate}>
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary=<FormattedMessage id={'header.menu.userinfo'}/> />
                </ListItem>
                <ListItem button onClick={this.doHelp}>
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText primary=<FormattedMessage id={'header.menu.help'}/> />
                </ListItem>
                <ListItem button onClick={this.doLogout}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary=<FormattedMessage id={'header.menu.logout'}/> />
                </ListItem>
              </Menu>
            </div>
            }
          </Toolbar>
        </AppBar>
        {this.state.timeout &&
        <div>
          <Dialog
            open={true}
            onClose={this.handleClose}
            disableBackdropClick={true}
            aria-labelledby="responsive-dialog-title"
          >
          {/*
            <DialogTitle id="responsive-dialog-title"><FormattedMessage id="system.message.error.title"/></DialogTitle>
          */}
            <DialogContent>
              <DialogContentText className={classes.dialogcontext}>
                <FormattedMessage id="message.error.403"/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.doClear} color="primary">
                <FormattedMessage id={'message.button.close'}/>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        }
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  isLoggedIn: state.auth.isLoggedIn,
  checkAccess: state.common.checkAccess,
});

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doOpenModal: commonOperations.doOpenModal,
  doCheckAccessTime: commonOperations.doCheckAccessTime,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Header))
);
