import React from 'react';
import { connect } from 'react-redux';
import WorkRemoval from '../../components/templates/WorkRemoval';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';
import { workOperations } from '../../reducks/work';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  applyDetail: state.work.applyDetail
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doOpenDialog: commonOperations.doOpenDialog,
  doApplyRemovalAction: workOperations.doApplyRemovalOperation,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<WorkRemoval
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
