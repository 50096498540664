import React from 'react';
import { connect } from 'react-redux';
import MailUpdate from '../../components/templates/MailUpdate';
import { authOperations } from '../../reducks/auth';
import { commonOperations } from '../../reducks/common';

const mapStateToProps = state => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  mailUpdateAction: authOperations.mailUpdateOperation,
  doOpenDialog: commonOperations.doOpenDialog,
};

class App extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<MailUpdate
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainerApp;
