import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ja from 'react-intl/locale-data/ja';
import 'react-app-polyfill/ie11';
import './index.css';
import ContainerRouter from './router';
import * as serviceWorker from './serviceWorker';
//import configureStore, { history } from './reducks/store';
import configureStore, { history, persistor } from './reducks/store';
//import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from "axios";
import { authOperations } from './reducks/auth';
import { commonOperations } from './reducks/common';
import GlobalIntlProvider from './core/globalIntl';

import { PersistGate } from 'redux-persist/integration/react';


addLocaleData([...en, ...ja]);
const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
const { dispatch } = reduxStore;

let MainComponent = ({ locale, messages }) => (
  <IntlProvider locale={locale} messages={messages}>
    <GlobalIntlProvider>
      <ContainerRouter />
    </GlobalIntlProvider>
  </IntlProvider>
);

MainComponent = connect(state => ({
  locale: state.style.locale,
  messages: state.style.messages
}))(MainComponent);

// 言語切り替えが実装したい場合は、local / messages を
// redux の store で管理するようにし、動的に入れ替える。
// Material-ui のテーマについても同様、Provider に与える
// テーマを事前に複数用意しておき、ユーザ操作によりその値
// を変更することで、複数パターンの動的テーマ切り替えを可能とする。
/*
ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <ConnectedRouter history={history}>
      <MainComponent />
    </ConnectedRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
*/

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <MainComponent />
      </ConnectedRouter>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);


/*
const errDialog = {
  type: 'error',
  title: 'system.message.error.title',
  message: 'system.message.error.context',
};
*/

// ServiceWorker を利用する場合にはこちらの利用を検討する。
// デフォルトでは unregister なので利用しない。
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Add a request interceptor
axios.interceptors.request.use(async function(config) {
  dispatch(commonOperations.doCheckAccess());
  await dispatch(authOperations.doRefreshTokenOperation());

  if(reduxStore.getState().auth.loginInfo){
    config.headers.Authorization = reduxStore.getState().auth.loginInfo.idToken;
  }
  // Do something before request is sent
  // styleAction.startRequest(); -> リクエストの開始を意味する。isRequesting = true にする。
  var method = config.method;
  console.log("----METHOD----", method);
  if(method === 'post'){
    dispatch(commonOperations.startRequest());
  }
  debugLogIN(config);
  return config;
}, function(error) {
  // Do something with request error
  // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。

  console.log("axios.interceptors END ERR1");
  dispatch(commonOperations.endRequest());
  errorDialog(error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function(response) {
  // Do something with response data
  // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。
  var method = response.config.method;
  console.log("----METHOD----", method);
  if(method === 'post'){
    dispatch(commonOperations.endRequest());
  }
  debugLogOUT(response);
  return response;
}, function(error) {
  // Do something with response error
  // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。
  console.log("axios.interceptors END ERR2");
  console.log("ERROR_INFO::" + error);
  dispatch(commonOperations.endRequest());
  errorDialog(error);
  return Promise.reject(error);
});

function errorDialog(error) {
  console.log("DEBUG");
  console.log(error);
  console.log(error.request);
  console.log(error.response);
  console.log(error.message);
  console.log(error.data);
  console.log(error.header);
  console.log(error.headers);
  console.log(error.option);
  var errDialog = {};
  if (error.response) {
    console.log("ERROR_INFO >> RESPONSE");
    console.log(error.response);
    switch (error.response.status) {
      case 403:
        errDialog = {
          type: 'error',
          title: 'system.message.error.title',
          message: 'system.message.error.403',
        };
        break;
      case 429:
        errDialog = {
          type: 'close',
          title: 'system.message.error.title',
          message: 'system.message.error.429',
        };
        break;
      default:
        errDialog = {
          type: 'error',
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
    }
  }
  else if (error.request) {
    console.log("ERROR_INFO >> REQUEST");
    console.log(error.request);
    debugLogArray(error.request, 1);
    errDialog = {
      type: 'error',
      title: 'system.message.error.title',
      message: 'system.message.error.context',
    };
  }
  else {
    console.log("ERROR_INFO >> ELSE");
    console.log("ERROR_MESSAGE::" + error.message);
    errDialog = {
      type: 'error',
      title: 'system.message.error.title',
      message: 'system.message.error.context',
    };
  }
  dispatch(commonOperations.doOpenDialog(errDialog));
}

function debugLogIN(config) {
  console.log("-----API DEBUG LOG IN-----");
  console.log("##  " + config.url.split('/')[config.url.split('/').length - 1] + "  ##");
  //console.log("headers");
  //debugLogArray(config.headers, 1);
  console.log("data");
  debugLogArray(config.data, 1);
}

function debugLogOUT(config) {
  const item = ['status', 'errorCode', 'errorMessage', 'errorParam'];

  console.log("-----API DEBUG LOG OUT-----");
  console.log("##  " + config.request.responseURL.split('/')[config.request.responseURL.split('/').length - 1] + "  ##");
  for (var i in item) {
    console.log(item[i] + " => " + config.data.body[item[i]]);
  }
  console.log("data");
  debugLogArray(config.data.body.data, 1);
}

function debugLogArray(ary, cnt) {
  var i = 1;
  var blank = "  ";
  while (i < cnt) {
    blank = blank + "  ";
    i++;
  }
  for (var j in ary) {
    if ((ary[j] instanceof Array) || (ary[j] instanceof Object)) {
      console.log(blank + j + " >>");
      debugLogArray((ary[j]), (cnt + 1));
    }
    else {
      console.log(blank + j + " => " + ary[j]);
    }
  }
}
