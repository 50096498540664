import jaForApp from './../../translations/ja';
//import enForApp from './../../translations/en';

import { combineReducers } from 'redux';
import types from './types';
/* State shape
state: {
  todos: [],
  visibilityFilter: SHOW_ALL
}
*/

// this method can be confusing because it serves two purposes:
// 1 - it create a new todo
// 2 - it toggles the completed state of an existing todo

const theme = (state = 'default', action) => {
  switch (action.type) {
    case types.CHANGE_THEME:
      return action.theme || state;
    default:
      return state;
  }
};


const fontStyle = (state = 'FontM', action) => {
  switch (action.type) {
    case types.CHANGE_COLOR:
      return action.fontStyle || state;
    default:
      return state;
  }
};

const isRequesting = (state = false, action) => {
  switch (action.type) {
    case types.START_REQUEST:
      return true;
    case types.END_REQUEST:
      return false;
    default:
      return state;
  }
};


const locale = (state = 'ja', action) => {
  return 'ja';
  /*
    switch (action.type) {
      case types.CHANGE_THEME:
        return (action.theme === 'default') ? 'ja' : 'en';
      default:
        return state;
    }
  */
};

const messages = (state = jaForApp, action) => {
  return jaForApp;
  /*
    switch (action.type) {
      case types.CHANGE_THEME:
        return (action.theme === 'default') ? jaForApp : enForApp;
      default:
        return state;
    }
  */
};

const applyList = (state = null, action) => {
    switch (action.type) {
        case types.GET_APPLY_LIST:
            return action.applyList;
        default:
            return state;
    }
};

const selectedApply = (state = null, action)  => {
    switch (action.type) {
        case types.SELECT_APPLY_LIST:
            return action.selectedApply;
        default:
            return state;
    }
};


// 取付申込登録結果返却
const applyProtectiveTube = (state = null, action) => { 
  switch (action.type) { 
    case types.DO_APPLY_PROTECTIVE_TUBE: 
      return action.applyProtectiveTube; 
    default: 
      return state; 
  } 
}; 

// 取付申込詳細返却
const applyDetail = (state = null, action) => {
  switch (action.type) {
    case types.GET_APPLY_DETAIL:
      return action.applyDetail;
    case types.CLEAR_APPLY_DETAIL:
      return null;
    default:
      return state;
  }
};

// 最終画像返却(追加)
const finalImage = (state = null, action) => {
  switch (action.type) {
    case types.GET_FINAL_IMAGE:
//      return state.concat([{ ImageBody: action.ImageBody, Description_c: action.Description_c }]);
//      return {ImageBody: action.ImageBody, Description_c: action.Description_c };
console.log("action.ImageBody", action.ImageBody);
      if (!state) {
        return [{ImageBody: action.ImageBody, Description__c: action.Description__c }];
      }else{
        return state.concat([{ImageBody: action.ImageBody, Description__c: action.Description__c }]);
      }
  
    case types.CLEAR_FINAL_IMAGE:
      return null;
    default:
      return state;
  }
};

// 作業問合参照
const inquiryList = (state = null, action) => {
  switch (action.type) {
    case types.GET_INQUIRY_LIST:
      return action.inquiryList;
    default:
      return state;
  }
};



const reducer = combineReducers({
  theme,
  fontStyle,
  isRequesting,
  locale,
  messages,
  applyList,
  applyProtectiveTube,
  applyDetail,
  selectedApply,
  inquiryList,
  finalImage,
});

export default reducer;
