import { changeTheme, startRequest, endRequest, getApplyList,
          getApplyDetail,selectApply,
          getInquiryList, clearApplyDetail, getFinalImage, clearFinalImage,
        } from './actions';
import axios from 'axios';
import conf from './../../config/config.js';
import { intl } from '../../core/globalIntl';
import { commonOperations } from '../common';
import { default as workSelectors } from './selectors';
import m from "moment";

const doGetApplyList = () => {
  console.log("申込一覧取得・・・");
  return async(dispatch, getState) => {
    const { auth } = getState();
    console.log(auth.loginInfo.userId);
    const retGetApplyList = await axios.post(conf.API_URL + "Order/getApplyList", { }, {
      headers: { Authorization: auth.loginInfo.idToken }
    });
    dispatch(getApplyList(retGetApplyList.data.body));
  };
};

// 取付申込登録
const doWorkRequestOperation = (images) => {
    console.log("取付申込登録・・・");
    return async(dispatch, getState) => {
        const { form, auth } = getState();
        const values = form.WorkRequest.values;
        console.log(images);
        let params = {
            WorkSitePrefecture__c: values.WorkSitePrefecture__c,          // 県
            WorkSiteAddress__c: values.WorkSiteAddress__c,                // 住所
            StartRequireAttachTerm__c: values.StartRequireAttachTerm__c,  // 取付希望期間from(ハイフン区切り)
            EndRequireAttachTerm__c: values.EndRequireAttachTerm__c,      // 取付希望期間to(ハイフン区切り)
            Purpose__c: values.Purpose__c,                                // 工事・作業の分類
            Crane__c: values.Crane__c,                                    // クレーン
            Scafford__c: values.Scafford__c,                              // 足場の設置
            DistributionLine__c : values.DistributionLine__c,             // 電線(配電線)
            IncomingLine__c : values.IncomingLine__c,                     // 電線(引込線)
            Device__c: values.Device__c,                                  // 機器
            CommunicationLine__c : values.CommunicationLine__c,           // 通信線
            Other__c: values.Other__c,                                    // その他
        };

        // 任意項目 or 条件付必須項目を設定
        if (values.WorkSitePoleNo__c) {
          params.WorkSitePoleNo__c = values.WorkSitePoleNo__c;    // 電柱番号
        }
        if (values.Purpose__c === intl.formatMessage({ id: 'workRequest.select.workOther' })) {
          params.PurposeOther__c = values.PurposeOther__c;         // その他入力
        }
        if (values.Message__c) {
          params.Message__c = values.Message__c;                 // 連絡事項
        }

        // 画像ファイルをS3にput
        var ImageDatas = [];
        var imageFlg = false;   // false:アップロード失敗 true:画像なしorアップロード成功
        if (images.length > 0) {
          let UploadDate = m().format('YYYYMMDDHHmmss');
          let ctr = 0;
          const uploadItems = images.map(function(item) {
            ctr++;
            return {ImageName: String(ctr) + "_" + item.ImageName, ContentType: item.ContentType, data: item.fileData};
          });

          //ImageNameで並び替え(昇順)
          uploadItems.sort(function(a, b) {
            if (a.ImageName > b.ImageName) {
              return 1;
            } else {
              return -1;
            }
          });

          // S3のURLを取得する
          const retS3Url = await getUploadS3URL(uploadItems, UploadDate, auth.loginInfo.idToken);
          if (retS3Url) {
            ctr = -1;
            // APIから返却された値をImageNameで並び替え(昇順)
            retS3Url.sort(function(a, b) {
              if (a.ImageName > b.ImageName) {
                return 1;
              } else {
                return -1;
              }
            });
            const putParams = retS3Url.map(function(item) {
              ctr++;
              return {url:item.URL, type:uploadItems[ctr].ContentType, data:uploadItems[ctr].data };     // url, type, data
            });
            // S3にファイルをputする
            const retS3Upload = await putUploadFileToS3(putParams, dispatch);

            console.log("put後の戻り値：", retS3Upload);
            if (retS3Upload) {
              ctr = -1;
              imageFlg = true;
              // S3への登録が正常終了したものだけをパラメータに追加
              ImageDatas = retS3Upload.map(function(item) {
                ctr++;
                if (!(item.statusText === "OK" && item.status === 200)) {
                  imageFlg = false;
                }
                return {ImageName: uploadItems[ctr].ImageName, UploadDate};
              });
console.log("ImageDatas", ImageDatas);
              if (imageFlg) {
                params.ImageDatas = ImageDatas;
              }
            }
          }
        }else{
          imageFlg = true;
        }

console.log("登録パラメータ：", params);
        //
        const ret = await axios.post(conf.API_URL + "Order/applyProtectiveTube", params, {
          headers: { Authorization: auth.loginInfo.idToken }
        });
        let retRegist = ret.data.body ? ret.data.body.errorCode : "ERROR_REGIST";

        if (retRegist === "00000" && !imageFlg) {
          // 正常終了でも、S3への画像アップロードが出来ていない場合はERROR_UPLOAD(ファイルアップロード失敗)を返す
console.log("問合せは登録完了、でも画像アップロードは失敗");
          return "ERROR_UPLOAD";
        }else{
          return retRegist;
        }


    };
};

// 取付申込詳細取得
const getApplyDetailOperation = () => {
  console.log("取付申込詳細取得・・・");
  return async(dispatch, getState) => {
    const { auth, work } = getState();
    const ret = await axios.post(conf.API_URL + "Order/getApplyDetail",
                      { Id: work.selectedApply.Id }, {
                      headers: {
                        Authorization: auth.loginInfo.idToken,
                      }
    });
    console.log(ret.data.body);
    dispatch(getApplyDetail(ret.data.body));
    return ret.data.body.errorCode;
  };
};

// 最終画像取得
const getFinalImageOperation = (ImageId, ObjectType, Description__c) => {
  console.log("最終画像取得・・・" , Description__c);
  return async(dispatch, getState) => {
    const { auth } = getState();

    try {
      const ret = await axios.post(conf.API_URL + "GetFinalImage/getFinalImage",
                        { ImageId, ObjectType }, {
                        headers: {
                          Authorization: auth.loginInfo.idToken,
                        }
      });
      if (ret.data.body.errorCode === "00000") {
        dispatch(getFinalImage(ret.data.body.data.ImageBody, Description__c));
      }else{
        dispatch(getFinalImage("ERROR", Description__c));
      }
      return ret.data.body.errorCode;

    }catch(e){
console.log("error:store");
      dispatch(getFinalImage("ERROR", Description__c));
      return "ERROR";
    }finally{
    }

  };
};


// 取付申込承諾
const doApplyConsentOperation = () => {
  console.log("取付申込承諾・・・");
  return async(dispatch, getState) => {
    const { auth, work } = getState();
    console.log(work.applyDetail.data.AttachWork__c);
    const ret = await axios.post(conf.API_URL + "AttachWork/applyConsent",
                      { Id: work.applyDetail.data.AttachWork__c,                           // 取付作業参照ID
                        LastModifiedDate: work.applyDetail.data.AttachLastModifiedDate__c  // 取付最終更新日
                      },
                      {
                      headers: {
                        Authorization: auth.loginInfo.idToken
                      }
    });
    return ret.data.body.errorCode;
  };
};

// 精算承諾入力
const doApplyConsentBillOperation = () => {
  console.log("精算承諾入力・・・");
  return async(dispatch, getState) => {
    const { auth, work } = getState();
    console.log(work.applyDetail.data.AttachWork__c);
    const ret = await axios.post(conf.API_URL + "AttachWork/applyConsentBill",
                      { Id: work.applyDetail.data.AttachWork__c,                           // 取付作業参照ID
                        LastModifiedDate: work.applyDetail.data.AttachLastModifiedDate__c  // 取付最終更新日
                      },
                      {
                      headers: {
                        Authorization: auth.loginInfo.idToken
                      }
    });
    return ret.data.body.errorCode;
  };
};

// 取外し申込
const doApplyRemovalOperation = () => {
  console.log("取外し申込")  ;
  return async(dispatch,getState) => {
    const { auth, work, form } = getState();
    const values = form.WorkRemoval.values;

    // 取外し予定日が変更されたかチェック
    let initRemvDate = workSelectors.getInitRemvScheduleDate(work.applyDetail);
    let endAttachTermExt = "";
    if (initRemvDate && m(initRemvDate).format("YYYYMMDD") < m(values.RemvScheduleDate__c).format("YYYYMMDD")) {
      endAttachTermExt =  values.RemvScheduleDate__c;
    }

    let params = { Id: work.applyDetail.data.Remove__c,                             // 取外し作業参照ID(詳細)
                  RemvScheduleDate__c: values.RemvScheduleDate__c,                  // 取外し予定日
                  LastModifiedDate: work.applyDetail.data.RemoveLastModifiedDate__c // 取外し最終更新日(詳細)
                  };

    // 値があればセット(空の場合はkeywordも渡さない)
    if (endAttachTermExt) {
      params.EndAttachTermExtention__c = endAttachTermExt;   // 取付期間終了日(延長)
    }
    if (values && values.Message2__c) {
      params.Message2__c = values.Message2__c;               // ご連絡事項
    }

    const ret = await axios.post(conf.API_URL + "RemoveWork/applyRemoval",
            params,
            {headers: {
              Authorization: auth.loginInfo.idToken
            }
    });
    return ret.data.body.errorCode;
  };
};


// 問合登録
const doWorkInquiryOperation = (images) => {
    console.log("問合登録・・・");
    return async(dispatch, getState) => {
        const { auth, work, form } = getState();
        const values = form.WorkInquiry.values;
        let params = {
            Id: work.selectedApply.Id,                                // カスタムオブジェクトID(申込一覧)
            LastModifiedDate: work.inquiryList.data.LastModifiedDate  // 取付最終更新日(問合せ参照)
        };

      // お問合せ内容登録*****
        if (values && values.comment) {
          var today = m().format("YYYY/MM/DD");
          params.ContactHistory__c = "お問合せ　" + today + "\n" + values.comment;
          if (work.inquiryList.data.ContactHistory__c) {
            params.ContactHistory__c += "\n" + work.inquiryList.data.ContactHistory__c;
          }
          if (params.ContactHistory__c.length > 10000) {
            return "ERROR_SIZE_OVER";
          }
        }


        // 画像ファイルをS3にput
        var ImageDatas = [];
        var imageFlg = false;   // false:アップロード失敗 true:画像なしorアップロード成功
        if (images.length > 0) {
          let UploadDate = m().format('YYYYMMDDHHmmss');
          let ctr = 0;
          const uploadItems = images.map(function(item) {
            ctr++;
            return {ImageName: String(ctr) + "_" + item.ImageName, ContentType: item.ContentType, data: item.fileData};
          });

          //念のためImageNameで並び替え
          uploadItems.sort(function(a, b) {
            if (a.ImageName > b.ImageName) {
              return 1;
            } else {
              return -1;
            }
          });

          // S3のURLを取得する
          const retS3Url = await getUploadS3URL(uploadItems, UploadDate, auth.loginInfo.idToken);
          if (retS3Url) {
            ctr = -1;
            retS3Url.sort(function(a, b) {
              if (a.ImageName > b.ImageName) {
                return 1;
              } else {
                return -1;
              }
            });
            const putParams = retS3Url.map(function(item) {
              ctr++;
              return {url:item.URL, type:uploadItems[ctr].ContentType, data:uploadItems[ctr].data };     // url, type, data
            });
            // S3にファイルをputする
            const retS3Upload = await putUploadFileToS3(putParams, dispatch);

            console.log("put後の戻り値：", retS3Upload);
            if (retS3Upload) {
              ctr = -1;
              imageFlg = true;
              // S3への登録が正常終了したものだけをパラメータに追加
              ImageDatas = retS3Upload.map(function(item) {
                ctr++;
                if (!(item.statusText === "OK" && item.status === 200)) {
                  imageFlg = false;
                }
                return {ImageName: uploadItems[ctr].ImageName, UploadDate};
              });
console.log("ImageDatas", ImageDatas);
              if (imageFlg) {
                params.ImageDatas = ImageDatas;
              }
            }
          }
        }else{
          imageFlg = true;
        }

console.log(params);

        // 問合せがある場合、又は画像のアップロードが正常終了した場合
        if ( params.ContactHistory__c || imageFlg) {
console.log("問合せがある or 問合せの有無に関係なく画像アップロードが正常終了");
          const ret = await axios.post(conf.API_URL + "Inquiry/registInquiry", params, {
            headers: { Authorization: auth.loginInfo.idToken }
          });
          let retRegist = ret.data.body ? ret.data.body.errorCode : "ERROR_REGIST";

          if (retRegist === "00000" && !imageFlg) {
            // 正常終了でも、S3への画像アップロードが出来ていない場合は21002(ファイルアップロード失敗)を返す
console.log("問合せは登録完了、でも画像アップロードは失敗");
            return "ERROR_UPLOAD";
          }else{
            return retRegist;
          }

        } else {
          if (!imageFlg) {
            // 画像アップロード失敗の場合
console.log("画像アップロード失敗:S3へのアップロード");
            return "ERROR_UPLOAD";
          }else{
console.log("あり得ないはずだけども、バリデーションエラーなど(両方省略されている");
            // 問合せなし＆画像アップロードなし
            return "90000";
          }
        }



    };
};

// 作業問合参照
const getWorkInquiryOperation = () => {
  console.log("作業問合参照・・・");
  return async(dispatch, getState) => {
    const { auth, work } = getState();

    // 引数：申込一覧で選択したid(カスタムオブジェクトID)
    const ret = await axios.post(conf.API_URL + "Inquiry/getInquiry",
                      { Id: work.selectedApply.Id }, {
                      headers: {
                        Authorization: auth.loginInfo.idToken
                      }
    });
console.log(ret);
    dispatch(getInquiryList(ret.data.body));
    return ret.data.body.errorCode;
  };
};




// S3URL取得(★アップロード仕様変更対応)
async function getUploadS3URL(images, UploadDate, token) {
    console.log("S3URL取得・・・");
    let params = {UploadDate};
    params.ImageDatas = images;
    const ret = await axios.post(conf.API_URL + "GetUploadURL/getUploadURL", params, {
      headers: { Authorization: token }
    });
    if (ret.data.body.errorCode === "00000") {
      let urlParam = ret.data.body.data;
      return urlParam;
    }else{
      return null;
    }
}


// ファイルデータをS3にput(★アップロード仕様変更対応)
async function putUploadFileToS3(obj, dispatch) {
  //obj {url, type, data}
  const api = axios.create();
  dispatch(commonOperations.startRequest());

  try{
    const ret = await axios.all(obj.map((item, index) => {

        return api({
          method: 'PUT',
          url: item.url,
          headers: {
            'Content-Type': item.type
          },
          data: item.data
        });


    }));
    return ret;

  }catch(err){
console.log(err);
    return null;
  }finally{
    dispatch(commonOperations.endRequest());
  }


}



export default {
    changeTheme,
    startRequest,
    endRequest,
    doGetApplyList,
    doWorkRequestOperation,
    getApplyDetailOperation,
    selectApply,
    doApplyConsentOperation,
    doApplyConsentBillOperation,
    doApplyRemovalOperation,
    doWorkInquiryOperation,
    getWorkInquiryOperation,
    clearApplyDetail,
    getFinalImageOperation,
    clearFinalImage,
};
